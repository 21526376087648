import cx from 'classnames';
import { LoadingIndicator, Search, X } from 'components/icons';
import { useCallback, useEffect, useId, useMemo, useState, useTransition } from 'react';
import { useGlobalFilter } from './DataTableContext';

const useInput = () => {
  const [globalFilter, setGlobalFilter] = useGlobalFilter();
  const [query, setQuery] = useState(globalFilter ?? '');

  const [isPending, startTransition] = useTransition();

  /** @type {import('react').ChangeEventHandler<HTMLInputElement>} */
  const onChange = useCallback((evt) => {
    const evtValue = evt.currentTarget.value;
    setQuery(evtValue);
    startTransition(() => {
      setGlobalFilter(evtValue);
    });
  }, [setGlobalFilter]);

  /** @type {import('react').FormEventHandler<HTMLFormElement>} */
  const onReset = useCallback(() => {
    setQuery(undefined);
    startTransition(() => {
      setGlobalFilter(undefined);
    });
  }, [setGlobalFilter]);

  const id = useId();
  const inputName = `${id}:query`;
  const inputProps = useMemo(() => ({ name: inputName }), [inputName]);

  /** @type {import('react').FormEventHandler<HTMLFormElement>} */
  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    const value = evt.target.elements[inputName].value;
    setQuery(value);
    startTransition(() => {
      setGlobalFilter(value);
    });
  }, [inputName, setGlobalFilter]);

  useEffect(() => {
    setQuery(globalFilter ?? '');
  }, [globalFilter]);

  const value = query ?? '';
  return useMemo(() => ({
    isPending,
    inputProps,
    value,
    onChange,
    onSubmit,
    onReset,
  }), [isPending, inputProps, value, onChange, onSubmit, onReset]);
};

export default function GlobalSearchInput({ type, placeholder, className, children }) {
  const {
    isPending,
    inputProps,
    value,
    onChange,
    onSubmit,
    onReset,
  } = useInput();

  return (
    <form className="relative" onSubmit={onSubmit} onReset={onReset}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <Search
          className={cx(
            'absolute text-neutral-light size-5 fill-current',
            isPending ? 'transition-opacity duration-0 delay-300 opacity-0' : 'opacity-100',
          )}
        />
        <LoadingIndicator
          className={cx(
            'absolute text-primary-darken size-5',
            isPending ? 'transition-opacity duration-0 delay-300 opacity-100' : 'opacity-0',
          )}
        />
      </div>
      <input
        type={type || 'text'}
        spellCheck={false}
        autoCapitalize="off"
        autoComplete="off"
        inputMode="search"
        placeholder={placeholder || 'Search'}
        className={cx(
          'peer pl-10 pr-8 h-10 text-sm appearance-none text-ellipsis',
          'text-neutral-dark caret-current placeholder:text-neutral-light selection:bg-primary-dark selection:text-white',
          'ring-1 ring-neutral-light/50 focus:ring-primary-dark/50 focus-visible:ring-primary-dark/50',
          'outline-none focus:outline-none focus-visible:outline-none',
          className,
        )}
        value={value}
        onChange={onChange}
        {...inputProps}
      />
      {children}
      <div className="peer-placeholder-shown:hidden absolute inset-y-0 right-0 pr-3 flex items-center">
        <button type="reset" tabIndex={-1} aria-label="clear">
          <X className="text-neutral-light size-5 fill-current" />
        </button>
      </div>
    </form>
  );
}
