import { useState } from 'react';
import { groupBy, isEmpty, partial, sortBy } from 'lodash';
import { parseISO } from 'date-fns';
import { useFetchPropertyHistoryQuery } from 'redux/apiSlice';
import { HISTORY_RECORD_TYPES } from 'components/constants';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import { CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatCurrency, formatCurrencyAbbreviated, formatPercentage, titleCase } from 'components/utils';
import { LoadingIndicator } from '../icons';
import HistoryYearGroup, { extractHistoryValues } from './components/HistoryRows';
import HistoryModal from './components/HistoryModal';

function RecordTypeCheckBox({ checked, label, toggleChecked }) {
  return (
    <label className="flex flex-row items-center gap-x-4.5 w-max tracking-wide cursor-pointer select-none">
      <input type="checkbox" className="h-4.5 w-4.5 cursor-pointer accent-primary-dark" checked={checked} onChange={toggleChecked} />
      {label}
    </label>
  );
}

const VALUATION_TOOLTIP_FIELDS = [
  'estimatedMaxValueAmount',
  'estimatedMinValueAmount',
  'confidenceScore',
];

function ValuationTooltip({ active, payload }) {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;
    const dateString = new Date(dataPoint.date).toLocaleDateString();

    return (
      <div className="bg-white border rounded-md shadow-md p-4">
        <p className="font-medium">{`AVM: ${formatCurrency(dataPoint.value)}`}</p>
        <p className="text-sm">{`Valuation Date: ${dateString}`}</p>
        {/* If details exists and is an object, loop over its keys */}
        {dataPoint.attributes?.details && typeof dataPoint.attributes.details === 'object' && (
          <div className="mt-2">
            {VALUATION_TOOLTIP_FIELDS.map(key => (
              <p key={key} className="text-xs text-gray-500">
                {`${titleCase(key)}: ${key.toLowerCase().includes('amount') ? formatCurrency(dataPoint.attributes.details[key]) : formatPercentage(dataPoint.attributes.details[key] / 100, 0)}`}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  }
  return null;
}

export default function History({ parcel }) {
  const [selectedModalItem, setSelectedModalItem] = useState(null);
  const [recordFilters, setRecordFilters] = useState({
    [HISTORY_RECORD_TYPES.LISTING]: true,
    [HISTORY_RECORD_TYPES.RECORDER]: true,
    [HISTORY_RECORD_TYPES.RENT_LISTING]: true,
  });

  const { data: history = [], isLoading: isHistoryLoading } = useFetchPropertyHistoryQuery(parcel?.fipsApn, { skip: !parcel?.fipsApn });

  if (isHistoryLoading) {
    return (
      <div className="py-6">
        <LoadingIndicator className="w-8 text-blue-400" />
      </div>
    );
  }

  const filteredHistory = history.filter(record => recordFilters[record.attributes.type]);
  const propertyHistoryGroups = Object.entries(
    groupBy(sortBy(filteredHistory, record => parseISO(record.attributes.date)).reverse(), event =>
      parseISO(event.attributes.date).getFullYear()
    )
  );

  const toggleRecordFilterChecked = recordType => setRecordFilters({
    ...recordFilters,
    [recordType]: !recordFilters[recordType],
  });

  const chartHistory = history.filter(record => record.attributes.type === HISTORY_RECORD_TYPES.VALUATION);
  // Transform records so that the x-axis is a timestamp (number) and the value is the dollar amount.
  const transformRecord = record => ({
    date: new Date(record.attributes.date).getTime(), // numeric timestamp for x-axis
    value: extractHistoryValues(record),
    ...record,
  });

  const valuationEvents = chartHistory.map(transformRecord).sort((a, b) => a.date - b.date);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="text-xl mb-1">Valuation History</div>
        {isEmpty(valuationEvents) ? (
          <div className="text-center text-gray-500 my-6">
            No valuation data available
          </div>
        ) : (
          <div>
            <div className="bg-white p-4 rounded-lg shadow-lg mt-10">
              <ResponsiveContainer width="100%" height={500}>
                <ComposedChart data={valuationEvents.sort((a, b) => a.date - b.date)} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={tick => {
                      const date = new Date(tick);
                      return `${date.toLocaleString('en-US', { month: 'short' })}, ${date.getFullYear()}`;
                    }}
                  />
                  <YAxis tickFormatter={value => `${formatCurrencyAbbreviated(value)}`} />
                  <Tooltip content={<ValuationTooltip />} />
                  {valuationEvents.length > 0 && (
                    <Line type="monotone" dataKey="value" data={valuationEvents} stroke="rgb(2, 132, 199)" dot={false} name="Valuation" />
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="text-xl mb-1">Transaction History</div>
        <div className="flex gap-x-8 py-8">
          <RecordTypeCheckBox
            label="Listings"
            checked={recordFilters[HISTORY_RECORD_TYPES.LISTING]}
            toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.LISTING)}
          />
          <RecordTypeCheckBox
            label="Recorder"
            checked={recordFilters[HISTORY_RECORD_TYPES.RECORDER]}
            toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.RECORDER)}
          />
          <RecordTypeCheckBox
            label="Rental Listing"
            checked={recordFilters[HISTORY_RECORD_TYPES.RENT_LISTING]}
            toggleChecked={partial(toggleRecordFilterChecked, HISTORY_RECORD_TYPES.RENT_LISTING)}
          />
        </div>
        <div className="overflow-auto rounded-xl">
          {propertyHistoryGroups.length ? (
            sortBy(propertyHistoryGroups, ([year]) => year)
              .reverse()
              .map(([year, records]) => (
                <HistoryYearGroup key={year} year={year} records={records} setSelectedModalItem={setSelectedModalItem} />
              ))
          ) : (
            <div className="w-full mx-auto rounded-xl border bg-white">
              <p className="p-6 text-gray-400">No historical information</p>
            </div>
          )}
        </div>
        {selectedModalItem && <HistoryModal item={selectedModalItem} onClose={() => setSelectedModalItem(null)} />}
      </div>
    </div>
  );
}

export function EmbeddedHistory({ context }) {
  const { data } = context;
  const { parcel } = data;

  return (
    <PropertyLayoutContainer>
      <History parcel={parcel} />
    </PropertyLayoutContainer>
  );
}
