import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, meanBy, sum } from 'lodash';
import { useFetchModelQuery } from 'redux/apiSlice';
import { setPendingParams, setBaseParams } from 'actions/model';
import { calcCashFlows, calcReturnMetrics } from 'components/dcf/dcf';
import { FormField, InfoField } from 'components/Form';
import { useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { useCurrentSideNavPanel } from 'components/inventory/InventorySideNav';
import { formatCurrency, formatEquityMultiple, formatPercentage, parseEventValue } from 'components/utils';
import { UnderwritingSimpleStat } from 'components/shared/metrics/UnderwritingStat';
import { LoadingIndicator } from 'components/icons';
import { DetailPaneHeading, DetailPaneNoData } from 'components/Import/NewBuild/NewBuildInventoryDetail';

function DrawerBody({ dispatch, model }) {
  const modelParams = model?.pendingParams;

  if (!modelParams) {
    return null;
  }

  const { dcfParams } = modelParams;
  const { listPrice, purchasePrice, units } = dcfParams;

  const onChange = (event) => {
    let param = event.target.name;
    let newValue = parseEventValue(event);
    if (['marketRent', 'turnBudget'].includes(param)) {
      newValue = [{ ...dcfParams.units[0], [param]: newValue }];
      param = 'units';
    } else if (param === 'annualHoa') {
      const expenseItems = [...dcfParams.expenseItems];
      param = 'expenseItems';
      const hoaItem = expenseItems.find(expenseItem => expenseItem.name === 'HOA Fee');
      const hoaItemIndex = expenseItems.indexOf(hoaItem);
      expenseItems.splice(hoaItemIndex, 1, { ...hoaItem, inputValue: newValue });
      newValue = expenseItems;
    }
    dispatch(setPendingParams({ param, newValue }));
  };

  const cashFlows = calcCashFlows(dcfParams);
  const returnMetrics = calcReturnMetrics(cashFlows, dcfParams);
  const { stabilizedYield, unleveredEquityMultiple, unleveredIrr } = returnMetrics;

  const marketRent = meanBy(units, 'marketRent');
  const capExBudget = sum(cashFlows.capital.followOnCapitalExpenses) * -1;

  const returnMetricStats = [
    {
      label: 'Yield',
      value: stabilizedYield,
      formatter: (value) => formatPercentage(value, 2),
    },
    {
      label: 'IRR',
      value: unleveredIrr,
      formatter: (value) => formatPercentage(value, 1),
    },
    {
      label: 'Equity Multiple',
      value: unleveredEquityMultiple,
      formatter: formatEquityMultiple,
    },
  ];

  let percentOfListLabel = null;
  if (!listPrice) {
    // leave as null
  } else if (listPrice < purchasePrice) {
    percentOfListLabel = `↑${formatPercentage((purchasePrice / listPrice) - 1)} of List`;
  } else if (listPrice > purchasePrice) {
    percentOfListLabel = `↓${formatPercentage(1 - (purchasePrice / listPrice))} of List`;
  }

  return (
    <div className="p-2">
      <div className="mb-6 grid grid-cols-3 divide-x border rounded shadow">
        {returnMetricStats.map(returnMetricStat => (
          <UnderwritingSimpleStat
            key={returnMetricStat.label}
            className="text-center px-6 py-2"
            {...returnMetricStat}
          />
        ))}
      </div>
      <div className="flex flex-col gap-y-4">
        <InfoField
          label="List Price"
          value={formatCurrency(listPrice)}
          valueClassName="py-1 px-2 text-right"
        />
        <div>
          <FormField
            label="Underwritten Price"
            name="purchasePrice"
            type="currency"
            value={purchasePrice}
            onChange={onChange}
          />
          <div className="w-full mt-1 text-right pr-2 text-gray-500 text-sm">{percentOfListLabel}</div>
        </div>
        <FormField
          label="Rent"
          name="marketRent"
          type="currency"
          value={Math.round(marketRent)}
          onChange={onChange}
        />
        <FormField
          label="Cap Ex Budget"
          name="turnBudget"
          type="currency"
          value={capExBudget}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default function OffMarketUnderwritingPane() {
  const show = useCurrentSideNavPanel() === 'underwriting';
  const [row] = useFilteredSelectedRows() ?? [];

  const { currentData: modelData, isLoading } = useFetchModelQuery({ id: row?.id, type: 'listings' }, { skip: !show || !row });

  const model = useSelector(state => state.model);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modelData) {
      // make a deep copy so we can edit as necessary
      const modelParams = cloneDeep(modelData);
      // default model params based on listing data
      const { model: { dcfParams } } = modelParams;
      const { original: { associationFee, compRent, renovationEstimate } } = row;

      dcfParams.units[0].turnBudget = renovationEstimate;
      if (compRent) {
        dcfParams.units[0].marketRent = compRent[0];
      }
      if (associationFee) {
        const { expenseItems } = dcfParams;
        const hoaItem = expenseItems.find(expenseItem => expenseItem.name === 'HOA Fee');
        const hoaItemIndex = expenseItems.indexOf(hoaItem);
        expenseItems.splice(hoaItemIndex, 1, { ...hoaItem, inputValue: associationFee });
      }
      dispatch(setBaseParams(modelParams.model));
    }
  }, [dispatch, modelData, row]);

  if (!show) {
    return null;
  }

  return (
    <>
      <DetailPaneHeading>Underwriting</DetailPaneHeading>
      {row ? (
        <div className="size-full overflow-auto">
          {isLoading ? (
            <LoadingIndicator className="size-8 mx-auto" />
          ) : (
            <DrawerBody dispatch={dispatch} model={model} />
          )}
        </div>
      ) : (
        <DetailPaneNoData>
          Select a row to view underwriting
        </DetailPaneNoData>
      )}
    </>
  );
}
