import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cx from 'classnames';
import DelayedLoadingIndicator from 'components/shared/DelayedLoadingIndicator';
import NonDecreasingWidth from 'components/shared/NonDecreasingWidth';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shared/Table/Table';
import { configFeature, editFeature } from 'components/shared/Table/table.features';
import { dataTableMeta, getCell, getTextAlignClass, rowIdFromId } from 'components/shared/Table/table.helpers';
import usePreviousValue from 'hooks/usePreviousValue';
import useResizeObserver from 'hooks/useResizeObserver';
import { isFunction } from 'lodash';
import { forwardRef, memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { DataTableContainerProvider, DataTablePortal, DataTableProvider } from './DataTableContext';

// Stable empty array reference to reduce re-renders
const EMPTY_ARR = Object.freeze([]);

// split out build and render table functions so that you can optionally
// get access to the Table object to handle cases where you need access
// to table state (e.g. the rowModel)

export const useBuildTable = ({
  columnFilters,
  columnVisibility,
  columns,
  data,
  enableEditing,
  enableMultiRowSelection,
  features,
  getRowId = rowIdFromId,
  initialState = {},
  defaultColumn,
  meta: metaProp,
  rowSelection,
  setColumnFilters,
  setColumnVisibility,
  setRowSelection,
  setSorting,
  sorting,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  theadClassName,
  tdClassName,
  trClassName,
  columnOrder,
  setColumnOrder,
  rowPinning,
  setRowPinning,
  enableGlobalFilter,
  globalFilter,
  setGlobalFilter,
  globalFilterFn,
  filterFns,
}) => {
  const meta = {
    ...(enableEditing ? dataTableMeta.enableEditing : {}),
    onRowClick,
    onRowMouseEnter,
    onRowMouseLeave,
    theadClassName,
    tdClassName,
    trClassName,
    ...metaProp,
  };

  /** @type {import('@tanstack/react-table').InitialTableState} */
  const state = {};
  /** @type {import('@tanstack/react-table').TableOptions} */
  const tableProps = {
    data: data ?? EMPTY_ARR,
    columns,
    initialState,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state,
    meta,
    getRowId,
    enableMultiRowSelection,
    enableGlobalFilter,
    filterFns,
    globalFilterFn,
    // only extra features need to be specified, bundled features are added automatically
    _features: [editFeature, configFeature, ...(features ?? [])],
    // pagination feature of tanstack-table isn't used
    // set to manual for better performance
    manualPagination: true,
  };

  // if an on*Change handler (e.g. onSortingChange) is provided (including undefined/null)
  // react-table disables internal state change handling
  // only pass setState callbacks if present in props
  if (setSorting) {
    state.sorting = sorting;
    tableProps.onSortingChange = setSorting;
  }

  if (setColumnFilters || columnFilters) {
    state.columnFilters = columnFilters;
    tableProps.onColumnFiltersChange = setColumnFilters;
  }

  if (setColumnVisibility || columnVisibility) {
    state.columnVisibility = columnVisibility;
    tableProps.onColumnVisibilityChange = setColumnVisibility;
  }

  if (setRowSelection || rowSelection) {
    state.rowSelection = rowSelection;
    tableProps.onRowSelectionChange = setRowSelection;
  }

  if (setColumnOrder || columnOrder) {
    state.columnOrder = columnOrder;
    tableProps.onColumnOrderChange = setColumnOrder;
  }

  if (rowPinning || setRowPinning) {
    state.rowPinning = rowPinning;
    tableProps.onRowPinningChange = setRowPinning;
  }

  if (globalFilter || setGlobalFilter) {
    state.globalFilter = globalFilter;
    tableProps.onGlobalFilterChange = setGlobalFilter;
  }

  return useReactTable(tableProps);
};

/**
 * @param {import('react').Ref} [ref]
 * @param {import('@tanstack/react-table').Row} row
 * @param {import('@tanstack/react-table').Table} table
 * @param {string} [className]
 */
const renderRow = ({
  ref,
  row,
  table,
  className,
  headerHeight,
  trHeight,
}) => {
  const {
    options: {
      meta: {
        onRowClick,
        onRowMouseEnter,
        onRowMouseLeave,
        trClassName,
      } = {},
    },
  } = table;

  // if row is pinned, calculate offset to make sticky display behavior work
  const style = table.getIsSomeRowsPinned() ? {
    top: row.getIsPinned() ? (headerHeight + (row.getPinnedIndex() * trHeight)) : null,
    height: trHeight,
  } : undefined;

  return (
    <TableRow
      ref={ref}
      key={row.id}
      className={cx(className, isFunction(trClassName) ? trClassName(row) : trClassName, row.getIsPinned() ? 'sticky' : null)}
      style={style}
      data-id={row.id}
      data-state={row.getIsSelected() ? 'selected' : undefined}
      onClick={onRowClick && (event => onRowClick(row, event))}
      onMouseEnter={onRowMouseEnter && (event => onRowMouseEnter(row, event))}
      onMouseLeave={onRowMouseLeave && (event => onRowMouseLeave(row, event))}
    >
      {/* empty cell for loading/empty indicator */}
      <td className="p-0 size-0" />
      {row.getVisibleCells().map((cell) => (
        <SharedTableCell key={cell.id} cell={cell} table={table} />
      ))}
      {/* empty cell for alignment */}
      <TableCell noPadding aria-hidden />
    </TableRow>
  );
};

// NOTE: It is not clear how rows can be null but I have seen this occur
//       now both locally and in production but cannot consistently replicate.
//       Because this condition causes the page to error, I am adding the null
//       check until we can investigate the causing conditions further.
const renderRows = ({ rows, table, className, headerHeight, trHeight }) => (
  rows?.map((row) => (
    renderRow({ row, table, className, headerHeight, trHeight })
  ))
);

// eslint-disable-next-line prefer-arrow-callback -- use function for displayName
const VirtualTableInner = forwardRef(function VirtualTableBody({ children, style }, ref) {
  return (
    <TableBody ref={ref} style={style}>
      {children}
      {/* empty row for alignment */}
      <tr aria-hidden tabIndex={-1} className="invisible block">
        {/* Firefox requires a td with non-zero height */}
        <td className="p-0 h-px" />
      </tr>
    </TableBody>
  );
});

function VirtualTableRow({ data: { listRef, getRowByIndex, table, rowHeightCache }, index, style: { top } }) {
  /** @type {import('react').CSSProperties} */
  const spacerStyle = useMemo(() => ({ height: `${top}px` }), [top]);
  const row = getRowByIndex(index);

  const initialHeight = rowHeightCache.get(row.id);
  const [{ blockSize: height }, refCallback] = useResizeObserver({ observeBlockSize: true, initialBlockSize: initialHeight });
  const prevHeight = usePreviousValue(height, initialHeight);
  if (height) {
    rowHeightCache.set(row.id, height);
  }
  const heightChanged = !!height && height !== prevHeight;

  // layoutEffect prevents excessive row resize events when existing edit mode
  useLayoutEffect(() => {
    if (heightChanged) {
      // reset internal row sizes if row height changed
      // reset all rows and always rerender to avoid edge cases
      listRef.current.resetAfterIndex(0, true);
    }
  }, [heightChanged, listRef]);

  return (
    <>
      <tr aria-hidden tabIndex={-1} className="invisible [&:not(:first-child)]:hidden" style={spacerStyle} />
      {/* Use transparent border for the last row to have a consistent height */}
      {/* Firefox requires non-zero height, h-0 works on Chrome */}
      {renderRow({ ref: refCallback, row, table, className: 'h-px *:border-b [&:nth-last-child(2)_>_td]:border-transparent' })}
    </>
  );
}

const rowIdAsKey = (idx, { [idx]: { id = idx } = {} }) => id;

function NonDecreasingWidthTableHead(props) {
  return <NonDecreasingWidth {...props} Component={TableHead} />;
}

function SharedTableHeader({ header, virtual, textAlign, style, attributes, listeners }) {
  const { column } = header;
  const Th = virtual ? NonDecreasingWidthTableHead : TableHead;

  return (
    <Th
      ref={attributes ? attributes.setNodeRef : null}
      style={style}
      onClick={column.getToggleSortingHandler()}
      className={cx(
        'group/th aria-[sort]:text-primary-700',
        { 'cursor-pointer': column.getCanSort() },
        getTextAlignClass({ column, defaultTextAlign: textAlign }),
        column.columnDef.meta?.thClassName,
      )}
      aria-sort={column.getIsSorted() ? `${column.getIsSorted()}ending` : undefined}
    >
      {header.isPlaceholder ? null : (
        <div {...attributes} {...listeners} className="w-full inline-flex flex-row gap-x-1 flex-nowrap items-center group-[.text-right]/th:flex-row-reverse group-[.text-center]/th:justify-center">
          <div aria-hidden className="invisible hidden group-[.text-center]/th:block basis-[2ch] flex-none" />
          {flexRender(column.columnDef.header, header.getContext())}
          {column.getCanSort() && (
            <span
              className={cx(
                "basis-[2ch] flex-none before:content-['↑'] before:invisible before:align-middle",
                "group-aria-[sort]/th:before:visible group-aria-[sort=descending]/th:before:content-['↓']",
              )}
            />
          )}
        </div>
      )}
    </Th>
  );
}

function SharedTableCell({ cell, table, style, setNodeRef }) {
  const {
    options: {
      meta: {
        textAlign,
        tdClassName,
      } = {},
    },
  } = table;

  const { column: { columnDef: { meta } } } = cell;

  const ctx = cell.getContext();
  const children = useMemo(() => flexRender(getCell(ctx), ctx), [ctx]);

  return (
    <TableCell
      style={style}
      ref={setNodeRef}
      className={cx(
        cell.column.columnDef.meta?.className,
        typeof tdClassName === 'function' ? tdClassName(cell.column, cell.row) : tdClassName,
        getTextAlignClass({ column: cell.column, defaultTextAlign: textAlign }),
      )}
      noPadding={meta?.noPadding}
    >
      {children}
    </TableCell>
  );
}

/**
 * you must supply a tableHeight value if you want sticky header functionality to work
 *
 * @param {import('@tanstack/react-table').Table} table
 * @param {boolean} [virtual]
 * @param {number} [virtualEstimatedRowHeight]
 * @param {string} [id]
 * @param {boolean} [isLoading]
 * @param {import('react').ReactNode} [emptyStateComponent]
 * @param {number} [tableHeight]
 * @param {string} [tableContainerClassName]
 */
export function RenderTable({
  table,
  virtual = false,
  virtualEstimatedRowHeight = 20,
  id,
  isLoading,
  emptyStateComponent,
  tableHeight,
  tableContainerClassName,
  trHeight,
}) {
  const { options: { meta: { theadClassName, textAlign } } } = table;

  const propsRef = useRef();
  propsRef.current = { id };

  const [{ blockSize: headerHeight }, headerRefCb] = useResizeObserver({ observeBlockSize: true });
  const header = (
    <TableHeader ref={headerRefCb} className={theadClassName}>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {/* Empty column for loading/empty indicator */}
          <th aria-hidden tabIndex={-1} className="p-0 invisible" />
          {headerGroup.headers.map(h => (
            <SharedTableHeader
              key={h.id}
              header={h}
              virtual={virtual}
              textAlign={textAlign}
            />
          ))}
          {/* Empty column header at the end for alignment */}
          <th aria-hidden tabIndex={-1} className="p-0 invisible" />
        </TableRow>
      ))}
    </TableHeader>
  );

  const hasFooter = table
    .getFooterGroups()
    .some(({ headers }) => (
      headers.some(({ column: { columnDef: { footer } } }) => footer)
    ));

  // TODO: support virtual table
  // TODO: share logic with header rendering
  // TODO: border not visible when sticky is active (not invisible in thead as well)
  const footer = !hasFooter ? null : (
    <TableFooter>
      {table.getFooterGroups().map((footerGroup) => (
        <TableRow key={footerGroup.id}>
          {/* Empty column for loading/empty indicator */}
          <td aria-hidden tabIndex={-1} className="p-0 invisible" />
          {footerGroup.headers.map(({ id: footerId, column, getContext }) => (
            <TableCell
              key={footerId}
              tfoot
              className={cx(
                getTextAlignClass({ column, defaultTextAlign: textAlign }),
                column.columnDef.meta?.thClassName,
              )}
            >
              {flexRender(column.columnDef.footer, getContext())}
            </TableCell>
          ))}
          {/* Empty column at the end for alignment */}
          <td aria-hidden tabIndex={-1} className="p-0 invisible" />
        </TableRow>
      ))}
    </TableFooter>
  );

  const isEmpty = !table.getRowCount();
  const loadingIndicator = (
    // Nest inside tbody/tr/td then force td to be a block element to work around html nesting rules
    <tbody className="contents">
      <tr className="contents">
        <td
          className={cx(
            'empty:hidden block sticky left-1/2 -translate-x-1/2 top-0 bottom-0 w-max h-max py-6 text-center',
            (!isEmpty || isLoading) && 'pointer-events-none',
          )}
        >
          {(isEmpty && !isLoading) && (emptyStateComponent ?? 'No Results')}
          {isLoading && <DelayedLoadingIndicator className="w-16 m-[25%] text-gray-500" />}
        </td>
      </tr>
    </tbody>
  );

  const componentsRef = useRef({});
  componentsRef.current = { header, footer, loadingIndicator };

  const [VirtualTableOuter] = useState(() => (
    // react/no-unstable-nested-components - component is actually stable
    // prefer-arrow-callback - use function for displayName
    // eslint-disable-next-line react/no-unstable-nested-components,prefer-arrow-callback
    forwardRef(function VirtualTableScroll({ className, onScroll, style, children }, ref) {
      const { height, tableHeight: userSuppliedTableHeight, ...styleWithoutHeight } = style;
      const { id: tableId } = propsRef.current;

      return (
        <Table
          ref={ref}
          onScroll={onScroll}
          style={styleWithoutHeight}
          // use overflow-anchor:none to prevent add/remove row -> auto scroll -> add/remove row -> auto scroll loop
          // overflow-anchor is not supported in Safari but Safari doesn't auto scroll when elements are added/removed
          containerClassName={cx('[overflow-anchor:none]', className)}
          // border-separate -- so the border width is counted in td/th's size
          // border-spacing-0 -- calculating cell size accurately with border-spacing is difficult
          className="border-separate border-spacing-0"
          tableHeight={userSuppliedTableHeight}
          id={tableId}
        >
          {componentsRef.current.header}
          {children}
          {componentsRef.current.loadingIndicator}
          {componentsRef.current.footer}
        </Table>
      );
    })
  ));

  const topRows = table.getTopRows();
  const centerRows = table.getCenterRows();
  const bottomRows = table.getBottomRows();

  const getRowByIndex = useCallback((idx) => {
    const centerRowsStartIdx = topRows.length;
    const bottomRowsStartIdx = topRows.length + centerRows.length;

    if (idx >= centerRowsStartIdx && idx < bottomRowsStartIdx) {
      return centerRows[idx - centerRowsStartIdx];
    } else if (idx < centerRowsStartIdx) {
      return topRows[idx];
    } else {
      return bottomRows[idx - bottomRowsStartIdx];
    }
  }, [bottomRows, centerRows, topRows]);

  const rowHeightCacheRef = useRef(new Map());
  const itemSize = useCallback((idx) => (
    rowHeightCacheRef.current.get(getRowByIndex(idx).id) ?? virtualEstimatedRowHeight
  ), [getRowByIndex, virtualEstimatedRowHeight]);

  const [{ blockSize: scrollContainerHeight }, sizeRefCb] = useResizeObserver({ observeBlockSize: true });

  const listRef = useRef();
  if (listRef.current) {
    // reset due to potential row order changes
    listRef.current.resetAfterIndex(0, false);
  }

  const baseTable = (
    <Table
      id={id}
      tableHeight={tableHeight}
      containerClassName={tableContainerClassName}
      // border-separate -- so the border width is counted in td/th's size
      // border-spacing-0 -- calculating cell size accurately with border-spacing is difficult
      className="border-separate border-spacing-0"
    >
      {header}
      <TableBody>
        {renderRows({ rows: topRows, table, headerHeight, trHeight })}
        {renderRows({ rows: centerRows, table, headerHeight, trHeight })}
        {renderRows({ rows: bottomRows, table, headerHeight, trHeight })}
      </TableBody>
      {loadingIndicator}
      {footer}
    </Table>
  );

  return virtual ? (
    <VariableSizeList
      ref={listRef}
      width="100%"
      height={scrollContainerHeight - headerHeight}
      style={tableHeight ? { tableHeight } : undefined}
      // need a new identity on every render
      itemData={{ listRef, getRowByIndex, table, rowHeightCache: rowHeightCacheRef.current }}
      itemCount={topRows.length + centerRows.length + bottomRows.length}
      itemKey={rowIdAsKey}
      itemSize={itemSize}
      innerElementType={VirtualTableInner}
      outerElementType={VirtualTableOuter}
      outerRef={sizeRefCb}
      // NOTE: set to 1 if scrolling issues start showing up
      overscanCount={5}
      // this value MUST NOT be higher than the actual height of any row
      estimatedItemSize={virtualEstimatedRowHeight}
      className={tableContainerClassName}
    >
      {VirtualTableRow}
    </VariableSizeList>
  ) : baseTable;
}

/**
 * @param {import('@tanstack/react-table').TableOptions['data']} [data]
 * @param {import('@tanstack/react-table').TableOptions['columns']} columns
 * @param {import('@tanstack/react-table').TableOptions['meta']} [meta]
 * @param {import('@tanstack/react-table').TableOptions['initialState']} [initialState]
 * @param {import('@tanstack/react-table').TableOptions['defaultColumn']} [defaultColumn]
 * @param {import('@tanstack/react-table').TableState['columnFilters']} [columnFilters]
 * @param {import('@tanstack/react-table').TableOptions['onColumnFiltersChange']} [setColumnFilters]
 * @param {import('@tanstack/react-table').TableState['columnVisibility']} [columnVisibility]
 * @param {import('@tanstack/react-table').TableOptions['onColumnVisibilityChange']} [setColumnVisibility]
 * @param {import('@tanstack/react-table').TableState['sorting']} [sorting]
 * @param {import('@tanstack/react-table').TableOptions['onSortingChange']} [setSorting]
 * @param {import('@tanstack/react-table').TableState['rowSelection']} [rowSelection]
 * @param {import('@tanstack/react-table').TableOptions['onRowSelectionChange']} [setRowSelection]
 * @param {import('@tanstack/react-table').TableOptions['getRowId']} [getRowId]
 * @param {import('@tanstack/react-table').TableOptions['enableMultiRowSelection']} [enableMultiRowSelection]
 * @param {import('@tanstack/react-table').TableOptions['enableGlobalFilter']} [enableGlobalFilter]
 * @param {import('@tanstack/react-table').TableState['globalFilter']} [globalFilter]
 * @param {import('@tanstack/react-table').TableOptions['onGlobalFilterChange']} [setGlobalFilter]
 * @param {import('@tanstack/react-table').TableOptions['globalFilterFn']} [globalFilterFn]
 * @param {import('@tanstack/react-table').TableOptions['filterFns']} [filterFns]
 * @param {boolean} [enableEditing]
 * @param {import('@tanstack/react-table').TableOptions['_features']} [features]
 * @param {(row: import('@tanstack/react-table').Row) => void} [onRowClick]
 * @param {(row: import('@tanstack/react-table').Row) => void} [onRowMouseEnter]
 * @param {(row: import('@tanstack/react-table').Row) => void} [onRowMouseLeave]
 * @param {string} [theadClassName]
 * @param {string | ((column: import('@tanstack/react-table').Column, row: import('@tanstack/react-table').Row) => string)} [tdClassName]
 * @param {string} [trClassName]
 * @param {string} [id]
 * @param {boolean} [isLoading]
 * @param {number} [tableHeight]
 * @param {import('react').ReactNode} [emptyStateComponent]
 * @param {string} [tableContainerClassName]
 * @param {boolean} [virtual]
 * @param {import('react').ReactNode} [children]
 */
function DataTable({
  data,
  columns,
  meta,
  initialState,
  defaultColumn,
  columnFilters,
  setColumnFilters,
  columnVisibility,
  setColumnVisibility,
  sorting,
  setSorting,
  rowSelection,
  setRowSelection,
  getRowId,
  enableMultiRowSelection,
  enableGlobalFilter,
  globalFilter,
  setGlobalFilter,
  globalFilterFn,
  filterFns,
  enableEditing,
  features,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  theadClassName,
  tdClassName,
  trClassName,
  trHeight,
  id,
  isLoading,
  tableHeight,
  emptyStateComponent,
  tableContainerClassName,
  virtual,
  columnOrder,
  setColumnOrder,
  children,
}) {
  const table = useBuildTable({
    data,
    columns,
    meta,
    initialState,
    defaultColumn,
    columnFilters,
    setColumnFilters,
    columnVisibility,
    setColumnVisibility,
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
    getRowId,
    enableMultiRowSelection,
    enableEditing,
    features,
    onRowClick,
    onRowMouseEnter,
    onRowMouseLeave,
    theadClassName,
    tdClassName,
    trClassName,
    trHeight,
    columnOrder,
    setColumnOrder,
    enableGlobalFilter,
    globalFilter,
    setGlobalFilter,
    globalFilterFn,
    filterFns,
  });

  const tableElem = (
    <RenderTable
      table={table}
      virtual={virtual}
      id={id}
      isLoading={isLoading}
      emptyStateComponent={emptyStateComponent}
      tableHeight={tableHeight}
      tableContainerClassName={tableContainerClassName}
      trHeight={trHeight}
    />
  );

  return (
    <DataTableProvider table={table}>
      {children === undefined ? tableElem : (
        <DataTableContainerProvider>
          <DataTablePortal>
            {tableElem}
          </DataTablePortal>
          {children}
        </DataTableContainerProvider>
      )}
    </DataTableProvider>
  );
}

export default memo(DataTable);
