import { forwardRef, useCallback } from 'react';
import Chip from 'components/shared/Chip';
import { useFilterDisplayValue, useFilterIsSelected, useFilterLabel, useOnClear } from './filterHooks';

function FilterChipButton(props, ref) {
  const onClear = useOnClear();
  /** @type {import('react').MouseEventHandler} */
  const onCancel = useCallback((evt) => {
    evt.stopPropagation();
    onClear();
  }, [onClear]);

  const selected = useFilterIsSelected();
  const label = useFilterLabel();
  const displayValue = useFilterDisplayValue();

  return (
    <Chip
      className="[&:not([data-chip-selected])]:bg-white"
      {...props}
      ref={ref}
      label={selected ? displayValue : label}
      onCancel={onCancel}
      selected={selected}
    />
  );
}

export default forwardRef(FilterChipButton);
