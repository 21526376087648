import cx from 'classnames';
import { endOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { constant, identity, isEmpty, isNil } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import {
  calcCompScoreTier,
  formatCurrency,
  formatDate,
  formatInteger,
  formatPercentage,
  formatTimeZone,
  parseEventValue,
  titleCase,
} from 'components/utils';
import { generatePath, Link } from 'react-router-dom';
import Modal from 'components/Modal';
import Button from 'components/shared/NewButton';
import { Camera, CameraOff, Check, CheckCircle, ExternalLink, InformationIcon, MinusCircle, Pencil, PlusCircle, X } from 'components/icons';
import ExternalUrl from 'components/shared/ExternalUrl';
import Input from 'components/Input';
import { useRowUpdatesForRow, useUpdateRow } from 'components/shared/Table/DataTableContext';
import { getOriginalValue, restoreCellValueSymbol } from 'components/shared/Table/table.features';
import Chip from 'components/shared/Chip';
import Select from 'components/Select';
import FloatingTooltip from 'components/shared/FloatingTooltip';
import { ACTIVE_STATUS, SCORE_COLORS } from 'components/constants';
import { getTextAlignClass } from './table.helpers';

const defaultAddressCellPath = (propertyId, dealId) => `/deals/${dealId}/properties/${propertyId}`;

export function AddressCell({ column: { columnDef: { meta: { pathFn } } }, row }) {
  const { property: { id, address, city, state, zipCode }, photoUrl } = row.original;
  const dealId = row.original.scenario?.dealId;

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-x-2">
        {photoUrl && (
          <div className="h-9 w-9">
            <img
              className="h-9 w-9 rounded-lg object-cover"
              alt="propertyPhoto"
              src={photoUrl}
            />
          </div>
        )}
        <div>
          <div className="text-sm">{titleCase(address)}</div>
          <div className="text-xs text-gray-500">
            {`${titleCase(city)}, ${state}, ${zipCode}`}
          </div>
        </div>
      </div>
      <Link className="ml-2 px-1 py-1" to={pathFn ? pathFn(row) : defaultAddressCellPath(id, dealId)}>
        <ExternalLink className="w-6 text-tertiary hover:text-tertiary-lighter" />
      </Link>
    </div>
  );
}

const generateLinkPath = ({ row, linkPath, linkParams }) => {
  let pathParams = {};
  if (linkParams) {
    pathParams = linkParams(row);
  }

  return generatePath(linkPath ?? '', { id: row.id, ...pathParams });
};

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {import('@tanstack/react-table').Row} row
 * @param {string} linkPath
 * @param {(row: import('@tanstack/react-table').Row) => Record<string, string>} [linkParams]
 */
export function RouteLinkCell({
  getValue,
  row,
  column: {
    columnDef: {
      meta: {
        linkPath,
        linkParams,
      } = {},
    },
  },
}) {
  const cellValue = getValue();
  const path = generateLinkPath({ row, linkPath, linkParams });

  return (
    <Link
      to={path}
      className="block whitespace-nowrap underline text-tertiary hover:text-tertiary-lighter active:text-tertiary-lighter focus:text-tertiary-lighter"
    >
      {cellValue ?? <ExternalLink className="w-5" />}
    </Link>
  );
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {import('@tanstack/react-table').Row} row
 * @param {string} linkPath
 * @param {(row: import('@tanstack/react-table').Row) => Record<string, string>} [linkParams]
 */
export function StretchedRouteLinkCell({
  getValue,
  row,
  column: {
    columnDef: {
      meta: {
        linkPath,
        linkParams,
      } = {},
    },
  },
}) {
  const cellValue = getValue();
  const path = generateLinkPath({ row, linkPath, linkParams });

  return (
    <Link
      to={path}
      className="after:absolute after:inset-0 after:z-10 [td:has(&)]:p-0 [tr:has(&)]:relative focus-within:[tr:has(&)]:bg-primary-focus"
    >
      {/* Render a hidden label so the browser can show a label for the link */}
      {/* sr-only because the link needs to remain focusable */}
      <span className="sr-only">{cellValue}</span>
    </Link>
  );
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 */
export function ZipCell({ getValue }) {
  return getValue()?.toString?.()?.padStart(5, '0');
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {Record<any, import('react').ReactNode>} [enumDisplayValue]
 * @param {import('react').ReactNode} [enumFallbackValue]
 */
export function EnumCell({
  getValue,
  column: {
    columnDef: {
      meta: {
        enumDisplayValues,
        enumFallbackValue,
      } = {},
    },
  },
}) {
  const cellValue = getValue();

  let label;
  if (enumDisplayValues && Object.hasOwn(enumDisplayValues, cellValue)) {
    label = enumDisplayValues[cellValue];
  } else {
    label = enumFallbackValue ?? '—';
  }

  return label;
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {string} [datetimeFormat]
 * @param {import('react').ReactNode} [datetimeFallbackValue]
 */
export function DatetimeCell({
  getValue,
  column: {
    columnDef: {
      meta: {
        datetimeFormat,
        datetimeFallbackValue,
      } = {},
    },
  },
}) {
  const cellValue = getValue();
  return formatDate(cellValue, datetimeFormat ?? 'yyyy-MM-dd') ?? datetimeFallbackValue ?? '—';
}

export function ZonedDatetimeCell({
  getValue,
  column: {
    columnDef: {
      meta: {
        datetimeFormat,
        datetimeFallbackValue,
        datetimeTimeZone,
      } = {},
    },
  },
}) {
  const cellValue = getValue();
  if (isNil(getValue())) return null;
  return formatTimeZone(cellValue, datetimeFormat ?? 'yyyy-MM-dd', { timeZone: datetimeTimeZone }) ?? datetimeFallbackValue ?? '—';
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {boolean} [currencyCents]
 */
export function CurrencyCell({
  getValue,
  column: {
    columnDef: {
      meta: {
        currencyCents,
      } = {},
    },
  },
}) {
  const cellValue = getValue();
  return formatCurrency(cellValue, currencyCents ?? false);
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {Number} [precision]
 */
export function PercentCell({
  getValue,
  column: {
    columnDef: {
      meta: {
        precision,
      } = {},
    },
  },
}) {
  const cellValue = getValue();
  return formatPercentage(cellValue, precision);
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 */
export function IntegerCell({ getValue }) {
  const cellValue = getValue();
  return formatInteger(cellValue);
}

/**
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {import('react').ReactNode} linkLabel
 */
export function ExternalLinkCell({
  getValue,
  column: {
    columnDef: {
      meta: {
        linkLabel,
      } = {},
    },
  },
}) {
  const url = getValue();

  if (!url) {
    return '—';
  }

  return (
    <ExternalUrl href={url} className="max-w-64 truncate">
      {linkLabel ?? url}
    </ExternalUrl>
  );
}

/**
 * @template TEvent
 *
 * @param {import('@tanstack/react-table').Cell['getValue']} getValue
 * @param {import('@tanstack/react-table').Row} row
 * @param {import('@tanstack/react-table').Column} column
 * @param {(event: TEvent) => any} [eventValueParser]
 */
const useInput = ({ getValue, row, column, eventValueParser = parseEventValue }) => {
  // this is the value that should be displayed (including edits)
  const cellValue = getValue();
  // this is the original value unmodified
  const originalCellValue = getOriginalValue(row, column.id);

  const [valueRaw, setValue] = useState(cellValue);
  const value = valueRaw === restoreCellValueSymbol ? originalCellValue : valueRaw;

  const [isPending, startTransition] = useTransition();
  const isPendingRef = useRef(isPending);
  isPendingRef.current = isPending;
  useEffect(() => {
    // update input value state if the cell value was modified externally
    // e.g. server returned a newer value
    if (!isPendingRef.current) {
      setValue(cellValue);
    }
  }, [cellValue]);

  const [, setUpdate] = useUpdateRow({ rowId: row.id, columnId: column.id });
  /** @type {(evt: TEvent) => void} */
  const onChange = useCallback((evt) => {
    const parsedValue = eventValueParser(evt);
    setValue(parsedValue);
    startTransition(() => setUpdate(parsedValue));
  }, [eventValueParser, setUpdate]);

  const changed = value !== originalCellValue;
  return useMemo(() => [
    value,
    onChange,
    changed,
  ], [onChange, value, changed]);
};

export function InputCell({ column, getValue, row, ...props }) {
  const {
    columnDef: {
      meta: {
        classname,
        editInputType,
        editInputPrecision,
        editInputMin,
        editInputMax,
        editInputRows,
        editInputCols,
        editInputPlaceholder,
        editEventValueParser,
      },
    },
  } = column;
  const [value, onChange, changed] = useInput({ getValue, row, column, eventValueParser: editEventValueParser });
  const { error, max } = props;

  const className = cx(
    'align-middle [field-sizing:content]',
    classname,
    error ? 'bg-red-50' : (changed && 'bg-blue-50'),
  );

  return (
    <div className="max-w-24 min-w-full">
      <Input
        className={className}
        width="w-auto min-w-full max-w-full"
        addOnClassName="overflow-hidden"
        type={editInputType}
        precision={editInputPrecision}
        min={editInputMin}
        max={max || editInputMax}
        rows={editInputRows}
        cols={editInputCols}
        placeholder={editInputPlaceholder}
        value={value}
        textAlign={getTextAlignClass({ column })}
        onChange={onChange}
      />
    </div>
  );
}

export function ButtonEditCell({ column, getValue, row }) {
  const {
    columnDef: {
      meta: {
        editButtonProps,
        editEventValueParser,
      },
    },
  } = column;
  const [value, onChange] = useInput({ getValue, row, column, eventValueParser: editEventValueParser });
  const resolvedButtonProps = useMemo(() => (
    typeof editButtonProps === 'function' ? editButtonProps(value) : editButtonProps
  ), [editButtonProps, value]);

  return (
    <Button
      {...resolvedButtonProps}
      value={value}
      onClick={onChange}
    />
  );
}

export function SelectInputCell({ column, getValue, row, ...props }) {
  const { columnDef: { meta: { classname, editEventValueParser, options } } } = column;
  const [value, onChange, changed] = useInput({ getValue, row, column, eventValueParser: editEventValueParser });
  const { error } = props;

  const className = cx(
    'align-middle [field-sizing:content]',
    classname,
    error ? 'bg-red-50' : (changed && 'bg-blue-50'),
  );

  return (
    <Select
      selectClassName={className}
      value={value}
      options={options}
      onChange={onChange}
      width="w-30"
    />
  );
}

export function TouchDatetimeCell({ column, getValue, row }) {
  const {
    columnDef: {
      meta: {
        datetimeTimeZone,
        editImpliedChanges,
      } = {},
    },
  } = column;

  const [value, onChange, changed] = useInput({ getValue, row, column, eventValueParser: identity });

  const originalCellValue = getOriginalValue(row, column.id);
  const onClick = useCallback(() => {
    onChange(changed ? originalCellValue : zonedTimeToUtc(endOfDay(utcToZonedTime(new Date(), datetimeTimeZone)), datetimeTimeZone).toISOString());
  }, [changed, datetimeTimeZone, onChange, originalCellValue]);

  const rowUpdates = useRowUpdatesForRow(row.id);
  const changeImplied = (editImpliedChanges ?? []).some((colId) => rowUpdates?.has(colId));
  const resolvedChanged = changed || changeImplied;

  return (
    <Chip
      inert={changeImplied ? '' : undefined}
      leadingIcon={<CheckCircle filled={resolvedChanged} className="w-full text-success-500" />}
      className="ml-auto bg-white"
      onClick={onClick}
    >
      {resolvedChanged ? (
        'Just now'
      ) : (
        <ZonedDatetimeCell column={column} getValue={constant(value)} />
      )}
    </Chip>
  );
}

export function ModalEditCell({ displayValue, children }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const onClose = () => setEditModalOpen(false);

  return (
    <div className="flex justify-between items-center">
      <Button outlined small label={<Pencil className="size-4" />} onClick={() => setEditModalOpen(true)} />
      {displayValue}
      {editModalOpen && (
        <Modal show showCloseAction={false} onClose={onClose}>
          {children(onClose)}
        </Modal>
      )}
    </div>
  );
}



const formatScoreValue = (scoreValue, formatter) => {
  if (isNil(scoreValue)) return 'n/a';
  return `${scoreValue > 0 ? '+' : ''}${formatter(scoreValue)}`;
}

function BaseCompScoreCell({ score, tooltip, showScore }) {
  return (
    <FloatingTooltip contents={tooltip}>
      <div className="flex justify-center items-center gap-x-2">
        {showScore && score?.toFixed(1)}
        <div className={cx(SCORE_COLORS[calcCompScoreTier(score)], 'w-5 h-5 rounded-full')} />
      </div>
    </FloatingTooltip>
  );
}

const comparisonValueFormatter = (formatter) => (value) => {
  if (value === 0) {
    return '✓';
  }
  return formatter(value);
}

const PROPERTY_COMP_SCORE_FORMATTERS = {
  bedrooms: ['Bedrooms', comparisonValueFormatter(identity)],
  bathrooms: ['Bathrooms', comparisonValueFormatter(identity)],
  sqft: ['Sqft', comparisonValueFormatter(formatPercentage)],
  year_built: ['Year Built', comparisonValueFormatter(identity)],
};

function PropertyCompScoreTooltipContents({ scoreComponents }) {
  return (
    <div className="text-sm grid grid-cols-[32px,auto,72px] gap-y-2">
      {scoreComponents.map(sc => (
        <Fragment key={sc[0]}>
          <div className={cx(SCORE_COLORS[calcCompScoreTier(sc[1])], 'w-5 h-5 rounded-full')} />
          <div>{PROPERTY_COMP_SCORE_FORMATTERS[sc[0]][0]}</div>
          <div className="text-right">{formatScoreValue(sc[2], PROPERTY_COMP_SCORE_FORMATTERS[sc[0]][1])}</div>
        </Fragment>
      ))}
    </div>
  )
}

export function PropertyCompScoreCell({ getValue, row: { original: { compScores } } }) {
  if (isNil(getValue())) {
    return null;
  }
  const score = getValue();
  const scoreComponents = compScores.property[1];
  const tooltip = <PropertyCompScoreTooltipContents scoreComponents={scoreComponents} />;
  return <BaseCompScoreCell score={score} tooltip={tooltip} />;
}

export function LocationCompScoreCell({ getValue, row: { original: { compScores } } }) {
  if (isNil(getValue())) {
    return null;
  }

  const score = getValue();
  const scoreComponents = compScores.location[1];

  let distanceScoreRange = null;
  if (score === 2) {
    distanceScoreRange = '[0 - 1mi]';
  } else if (score === 1) {
    distanceScoreRange = '[1mi - 2mi]';
  } else if (score === 0) {
    distanceScoreRange = '[2+ mi]';
  }

  const tooltip = (
    <div className="flex items-center gap-x-3">
      <div className={cx(SCORE_COLORS[score], 'w-5 h-5 rounded-full')} />
      <div className="text-sm">
        {score === 3 ? 'Same Subdivision' : distanceScoreRange}
      </div>
      <div className="text-sm text-gray-500">{`${scoreComponents[0][2]?.toFixed(2)} mi`}</div>
    </div>
  );
  return <BaseCompScoreCell score={score} tooltip={tooltip} />;
}

export function RecencyCompScoreCell({ getValue, row: { original: { compScores, standardStatus } } }) {
  if (isNil(getValue())) {
    return null;
  }
  const score = getValue();
  const scoreComponents = compScores.recency[1];
  let recencyScoreRange = null;
  if (standardStatus === ACTIVE_STATUS) {
    recencyScoreRange = 'Active';
  } else if (score === 3) {
    recencyScoreRange = '[<3 months]';
  } else if (score === 2) {
    recencyScoreRange = '[3-6 months]';
  } else if (score === 1) {
    recencyScoreRange = '[6-12 months]';
  } else if (score === 0) {
    recencyScoreRange = '[>1 year]';
  }

  const tooltip = (
    <div className="flex items-center gap-x-3">
      <div className={cx(SCORE_COLORS[score], 'w-5 h-5 rounded-full')} />
      <div className="text-sm">
        {recencyScoreRange}
      </div>
      {scoreComponents[0][2] && <div className="text-sm text-gray-500">{formatDate(scoreComponents[0][2], 'MMM d, yyyy')}</div>}
    </div>
  );
  return <BaseCompScoreCell score={score} tooltip={tooltip} />;
}

export function OverallCompScoreCell({ getValue, row: { original: { compScores } } }) {
  const score = getValue();
  if (isNil(score)) {
    return null;
  }

  const tooltip = (
    <div className="grid grid-cols-[auto,40px,32px] text-sm">
      <div className="pb-1">Property</div>
      <div className="pb-1 text-right">{compScores.property[0]}</div>
      <div className="pb-1 text-right text-gray-400">x2</div>
      <div className="py-1">Location</div>
      <div className="py-1 text-right">{compScores.location[0]}</div>
      <div className="py-1 text-right text-gray-400">x1</div>
      <div className="pt-1 pb-2">Recency</div>
      <div className="pt-1 pb-2 text-right">{compScores.recency[0]}</div>
      <div className="pt-1 pb-2 text-right text-gray-400">x1</div>
      <div className="pt-2 border-t">Overall</div>
      <div className="pt-2 border-t text-right">{compScores.overall}</div>
      <div className="pt-2 border-t" />
    </div>
  );

  return (
    <BaseCompScoreCell score={score} tooltip={tooltip} showScore />
  );
}

export function CompAddressToggleCell({ active, toggleActive, onInfoClick, onPhotoClick, getValue: getAddress, row: { getValue, original, id } }) {
  const { media } = original;
  const isSubject = getValue('isSubject');

  const handleClick = (event) => {
    event.stopPropagation();
    toggleActive();
  };

  let color;
  if (isSubject) {
    color = 'bg-red-500';
  } else if (active) {
    color = 'bg-primary-500';
  } else {
    color = 'bg-gray-500';
  }

  return (
    <div className="flex items-center gap-x-2">
      <div className="w-14 flex justify-between items-center">
        {isSubject ? <div /> : (
          <div className="flex justify-center w-6 cursor-pointer" onClick={handleClick}>
            {active ? <MinusCircle /> : <PlusCircle />}
          </div>
        )}
        <div data-comp-id={id} className="flex h-full items-center">
          <div className={`w-5 h-5 rounded-full ${color}`} />
        </div>
      </div>
      <div className="w-48 truncate">{getAddress()}</div>
      {(media.length > 0)
        ? <Camera className="size-5 cursor-pointer" onClick={() => { onPhotoClick(original); }} />
        : <CameraOff className="size-5 cursor-not-allowed" />}
      {isSubject ? <div className="w-4" /> : <InformationIcon className="size-4 cursor-pointer" onClick={() => { onInfoClick(original); }} />}
    </div>
  );
}

export function BuyBoxCell({ getValue, row: { original } }) {
  const missReasons = getValue();

  let buyBoxIndicator = null;
  if (isEmpty(missReasons)) {
    buyBoxIndicator = <Check className="text-success-500 size-5" />;
  } else {
    const tooltipContents = (
      <ul className="text-sm">
        {missReasons.map(reason => <li key={reason}>{titleCase(reason)}</li>)}
      </ul>
    );
    buyBoxIndicator = (
      <FloatingTooltip contents={tooltipContents}>
        <X className="text-error-500 size-5" />
      </FloatingTooltip>
    );
  }

  return (
    <div className="flex justify-center">
      {buyBoxIndicator}
    </div>
  );
}

export function ZeroWidthCell() {
  return <div className="contents [td:has(&)]:p-0" />;
}

export function CompRentCell({ getValue }) {
  const value = getValue();
  if (!value) return null;
  const [rent, score] = value;
  return (
    <div className="flex justify-end gap-x-2 items-center">
      <div>{formatCurrency(rent)}</div>
      <div className={cx(SCORE_COLORS[calcCompScoreTier(score)], 'size-4 rounded-full')} />
    </div>
  );
}
