import { useFilteredSelectedRows } from 'components/shared/Table/DataTableContext';
import { DetailPaneHeading, DetailPaneNoData } from 'components/Import/NewBuild/NewBuildInventoryDetail';
import InventoryPhotosOrder from './InventoryPhotosOrder';
import { useCurrentSideNavPanel } from './InventorySideNav';

export default function InventoryPhotosPane({
  supportImportFromGoogleDrive,
  useFetchInventoryImagesQuery,
  useUpdateInventoryImagesMutation,
  useCreateInventoryImagesMutation,
  useDeleteInventoryImageMutation,
  useInvalidateInventoryImages,
}) {
  const show = useCurrentSideNavPanel() === 'photos';
  const [row] = useFilteredSelectedRows() ?? [];

  if (!show) {
    return null;
  }

  return (
    <>
      <DetailPaneHeading>Photos</DetailPaneHeading>
      {row ? (
        <div className="size-full overflow-auto">
          <InventoryPhotosOrder
            id={row.id}
            supportImportFromGoogleDrive={supportImportFromGoogleDrive}
            useFetchInventoryImagesQuery={useFetchInventoryImagesQuery}
            useUpdateInventoryImagesMutation={useUpdateInventoryImagesMutation}
            useCreateInventoryImagesMutation={useCreateInventoryImagesMutation}
            useDeleteInventoryImageMutation={useDeleteInventoryImageMutation}
            useInvalidateInventoryImages={useInvalidateInventoryImages}
          />
        </div>
      ) : (
        <DetailPaneNoData>
          Select a row to see photos
        </DetailPaneNoData>
      )}
    </>
  );
}
