import { FinancialsFilled, FinancialsOutlined } from 'components/icons';
import { InventoryNavItem } from 'components/inventory/InventorySideNav';
import { NavItemIcon, NavItemLabel } from 'components/shared/NavigationRail';

export default function UnderwritingNavItem() {
  return (
    <InventoryNavItem name="underwriting">
      <NavItemIcon>
        <FinancialsOutlined className="block group-aria-selected/nav:hidden" />
        <FinancialsFilled className="hidden group-aria-selected/nav:block" />
      </NavItemIcon>
      <NavItemLabel>Underwriting</NavItemLabel>
    </InventoryNavItem>
  );
}
