import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@headlessui/react';
import { every, isNil, uniq, without } from 'lodash';
import useElementHeight from 'hooks/useElementHeight';
import { useFetchRentCompsQuery } from 'redux/apiSlice';
import { setAllComps, setBaseSelectedRentComps, setFilteredRentCompIds, setSelectedRentCompIds } from 'redux/rentCompsSlice';
import { setHoveredId } from 'redux/mapSlice';
import {
  CURRENT_COMP_SET,
  RENT_COMP_TABLE_INITIAL_STATE,
  getDefaultComps,
  makePropertyRow,
  rentCompsSelector,
  rentCompTableColumns,
} from 'components/rentComps/rentComps';
import FilterBar from 'components/rentComps/FilterBar';
import { useBuildTable, RenderTable } from 'components/shared/Table/DataTable';
import { DataTableProvider } from 'components/shared/Table/DataTableContext';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import DataTableConfigPanelBody from 'components/shared/Table/dataTableConfig/DataTableConfigPanelBody';
import { enableConfigPresets } from 'components/shared/Table/table.helpers';
import { EMPTY_PRESET, getDefaultPresetId } from 'components/shared/Table/dataTableConfig/utils';
import Chip from 'components/shared/Chip';
import { Config, LoadingIndicator } from 'components/icons';
import { LAYOUT } from 'components/constants';

const TABLE_ROW_HEIGHT = 40;
const TABLE_ID = 'rentComps';

function TableConfigButton() {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button as="div">
            <Chip
              preferLeadingIcon
              label="Config Table"
              focused={open}
              leadingIcon={<Config />}
            />
          </Popover.Button>
          <Popover.Panel className="absolute right-0 z-30 w-max max-h-96 overflow-auto bg-white rounded shadow">
            <DataTableConfigPanelBody className="p-2" />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}

function RentCompDataTable(props) {
  const {
    activeUnitMix,
    columnFilters,
    contentRef,
    property,
    rentComps,
    selectedIds,
    setColumnFilters,
    setFilter,
    setShowInfo,
    setShowPhoto,
  } = props;
  const dispatch = useDispatch();
  const { activeRentCompSetId, baseSelectedRentComps, selectedRentCompIds } = useSelector(state => state.rentComps);

  const contentHeight = useElementHeight(contentRef);
  const filterRef = useRef();
  const filterHeight = useElementHeight(filterRef);
  const tableHeight = contentHeight - filterHeight - LAYOUT.saleCompTopBarHeight;

  const filterContext = useMemo(() => ({
    subdivisions: uniq(rentComps?.map(ur => ur.subdivision) || []),
    propertyTypes: uniq(rentComps?.map(ur => ur.propertySubType) || []),
    statuses: uniq(rentComps?.map(ur => ur.standardStatus) || []),
    subjectRsf: property.livingArea,
  }), [property, rentComps]);

  const tableData = useMemo(() => [makePropertyRow(property, activeUnitMix), ...rentComps], [activeUnitMix, property, rentComps]);

  const toggleSelected = useCallback((id) => {
    if (selectedRentCompIds.includes(id)) {
      dispatch(setSelectedRentCompIds(without(selectedRentCompIds, id)));
    } else {
      dispatch(setSelectedRentCompIds([...selectedRentCompIds, id]));
    }
  }, [dispatch, selectedRentCompIds]);

  const columns = useMemo(() => {
    // transform array of IDs into object where keys are IDs and values are true to make it easier to access selected
    const selectedIdsObj = selectedRentCompIds?.reduce((obj, rentCompId) => {
      // eslint-disable-next-line no-param-reassign
      obj[rentCompId] = true;
      return obj;
    }, {}) || {};

    return rentCompTableColumns(setShowPhoto, setShowInfo, selectedIdsObj, toggleSelected);
  }, [setShowPhoto, setShowInfo, selectedRentCompIds, toggleSelected]);

  const onRowMouseEnter = (row) => {
    dispatch(setHoveredId(row.original.id));
  };

  const onRowMouseLeave = () => {
    dispatch(setHoveredId(null));
  };

  const defaultPresetId = getDefaultPresetId({ tableId: TABLE_ID });
  const rowPinning = useMemo(() => {
    const tableDataIds = tableData.map(d => d.id);
    return (selectedRentCompIds && every(selectedRentCompIds, id => tableDataIds.includes(id)))
      ? { top: ['subject', ...selectedRentCompIds] } : {};
  }, [tableData, selectedRentCompIds]);
  const table = useBuildTable({
    columnFilters,
    columns,
    data: tableData,
    initialState: RENT_COMP_TABLE_INITIAL_STATE,
    setColumnFilters,
    columnVisibility: {
      geo: false,
      id: false,
      isSubject: false,
    },
    rowPinning,
    onRowMouseEnter,
    onRowMouseLeave,
    trClassName: row => (row.getIsPinned() ? 'bg-blue-50' : 'bg-white'),
    meta: { ...enableConfigPresets({ presets: { [defaultPresetId]: { ...EMPTY_PRESET, id: defaultPresetId } } }) },
  });

  const { getRowModel } = table;
  const rowModel = getRowModel();
  const { rows: filteredRows } = rowModel;

  useEffect(() => {
    dispatch(setFilteredRentCompIds(filteredRows.map(row => row.id)));
    if (filteredRows && !baseSelectedRentComps) {
      const newBaseSelectedRentComps = (activeRentCompSetId === CURRENT_COMP_SET.id)
        ? getDefaultComps(property, filteredRows)
        : selectedIds;
      dispatch(setBaseSelectedRentComps(newBaseSelectedRentComps))
    }
  }, [dispatch, filteredRows]);

  return (
    <DataTableProvider table={table}>
      <div className="border-y" ref={filterRef}>
        <div className="bg-white z-10 flex flex-row items-stretch justify-between px-4 py-2">
          <div className="grow">
            <FilterBar
              filters={columnFilters}
              setFilter={setFilter}
              filterContext={filterContext}
              setColumnFilters={setColumnFilters}
            />
          </div>
          <div className="w-fit flex flex-col justify-between gap-y-2">
            <div className="flex justify-end">
              <DataTableConfig tableId={TABLE_ID}>
                <TableConfigButton />
              </DataTableConfig>
            </div>
            <div className="w-full flex justify-end whitespace-nowrap text-sm text-neutral-light">
              {(filteredRows.length !== rentComps.length)
                ? `Filters Match ${filteredRows.length} of ${rentComps.length} Comps`
                : `${rentComps.length} Comps`}
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto">
        <RenderTable
          id="rent-comp-table"
          table={table}
          tableContainerClassName="overflow-x-scroll"
          tableHeight={tableHeight}
          trHeight={TABLE_ROW_HEIGHT}
        />
      </div>
    </DataTableProvider>
  );
}

// TODO: implement for multi-familiy support
const activeUnitMix = null;

export default function RentCompTable(props) {
  const { deal, homeModel, listing, parcel, property } = props;
  const dispatch = useDispatch();
  const { activeRentCompSetId } = useSelector(state => state.rentComps);

  const fipsApn = parcel?.fipsApn || property?.fipsApn;

  const {
    currentData: {
      rentComps,
      selectedIds,
    },
  } = useFetchRentCompsQuery({
    activeRentCompSetId,
    dealId: deal?.id,
    fipsApn,
    homeModelId: homeModel?.id,
    listingId: listing?.id,
    // TODO: need to add unitMix to support multi-family
  }, {
    skip: isNil(activeRentCompSetId),
    selectFromResult: result => ({
      ...result,
      currentData: rentCompsSelector(result),
    }),
  });

  useEffect(() => {
    dispatch(setAllComps(rentComps));
  }, [dispatch, rentComps]);

  if (!rentComps) {
    return (
      <div className="w-full pt-8 flex justify-center">
        <LoadingIndicator className="text-gray-500 w-7 h-7" />
      </div>
    );
  }

  return (
    <RentCompDataTable
      {...props}
      activeUnitMix={activeUnitMix}
      rentComps={rentComps}
      selectedIds={selectedIds}
    />
  );
}
