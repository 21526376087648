import { useCallback, useId, useMemo } from 'react';
import { useAllFlatColumns, useSetColumnFilters } from 'components/shared/Table/DataTableContext';
import Filter from './Filter';
import { useFilterSubtreeStates } from './filterHooks';
import ReactTableColumnFilter from './ReactTableColumnFilter';

const useFilterableColumnIds = () => {
  const columns = useAllFlatColumns();
  return useMemo(() => (
    new Set(
      columns
        .filter((column) => column.getCanFilter())
        .map(({ id }) => id),
    )
  ), [columns]);
};

/**
 * Create ReactTableColumnFilter for all filterable columns that have not otherwise been created.
 *
 * This allows visibility into active filters that do not have UI elements.
 */
function AllRemainingFilterableColumns() {
  const nodes = useFilterSubtreeStates()();
  const columnIds = useFilterableColumnIds();
  const id = useId();

  return Array.from(columnIds)
    .filter((columnId) => !Object.hasOwn(nodes, columnId))
    .map((columnId) => (
      <ReactTableColumnFilter key={columnId} columnId={columnId} filterId={`${id}${columnId}`} />
    ));
}

export default function ReactTableFilter({ children }) {
  const { setColumnFilters, resetColumnFilters, clearColumnFilters } = useSetColumnFilters();
  const columnIds = useFilterableColumnIds();

  const onApply = useCallback((_, filterStates) => {
    setColumnFilters((prev) => (
      Object
        .entries({
          ...(Object.fromEntries((prev ?? []).map(({ id, value }) => [id, value]))),
          ...filterStates,
        })
        .filter(([id]) => columnIds.has(id))
        .map(([id, value]) => ({ id, value }))
    ));
  }, [setColumnFilters, columnIds]);

  return (
    <Filter root onApply={onApply} onClear={clearColumnFilters} onReset={resetColumnFilters}>
      {children}
      <AllRemainingFilterableColumns />
    </Filter>
  );
}
