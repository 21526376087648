/* eslint-disable no-nested-ternary */
import { useMemo, useRef, useState } from 'react';
import { flatMap, some, map, uniq } from 'lodash';
import { useFetchPropertyManagementRecordIndexQuery } from 'redux/apiSlice';
import Header from 'components/AssetManagement/Header';
import DataTable from 'components/shared/Table/DataTable';
import { AddressCell, CompRentCell } from 'components/shared/Table/Cells';
import { dataTableMeta, tableConfigMeta } from 'components/shared/Table/table.helpers';
import useElementHeight from 'hooks/useElementHeight';
import { LAYOUT } from 'components/constants';
import { coalesceProperty, formatCurrency, formatInteger } from 'components/utils';
import ListSummary from './ListSummary';
import PropertyMap from './PropertyMap';

const addressPathFn = (row) => `/asset_management/${row.id}/overview`;

const COLUMNS = [{
  id: 'address',
  header: 'Address',
  enableHiding: false,
  accessorKey: 'property.address',
  cell: AddressCell,
  sortingFn: 'text',
  meta: {
    className: 'pl-3',
    pathFn: addressPathFn,
    ...tableConfigMeta({ order: 'readonly' }),
  },
}, {
  id: 'market',
  header: 'Market',
  accessorKey: 'property.market',
}, {
  id: 'propertyType',
  header: 'Property Type',
  accessorKey: 'property.propertyType',
}, {
  id: 'bed',
  header: 'Bed',
  accessorKey: 'property.bedrooms',
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'bath',
  header: 'Bath',
  accessorKey: 'property.bathrooms',
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'sqft',
  header: 'Sq Ft',
  accessorKey: 'property.livingArea',
  cell: ({ getValue }) => formatInteger(getValue()),
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'yearBuilt',
  header: 'Year Built',
  accessorKey: 'property.yearBuilt',
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'compRent',
  header: 'Comp Rent',
  accessorKey: 'compRent',
  cell: CompRentCell,
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'avm',
  header: 'AVM',
  accessorKey: 'avm',
  cell: ({ getValue }) => formatCurrency(getValue()),
  meta: { ...dataTableMeta.textRight },
}];

function PropertyTable({ isLoading, propertyManagementRecords, setHoveredId, tableHeight }) {
  const onRowMouseEnter = (row) => {
    setHoveredId(row.original.id);
  };

  const onRowMouseLeave = () => {
    setHoveredId(null);
  };

  return (
    <DataTable
      columns={COLUMNS}
      data={propertyManagementRecords}
      isLoading={isLoading}
      onRowMouseEnter={onRowMouseEnter}
      onRowMouseLeave={onRowMouseLeave}
      tableHeight={tableHeight}
    />
  );
}

export default function PropertyList() {
  const { data: rawPropertyManagementRecords = [], isLoading } = useFetchPropertyManagementRecordIndexQuery();
  const [filters, setFilters] = useState({ name: '', markets: [], tags: [] });
  const [hoveredId, setHoveredId] = useState(null);
  const [minimizeMap, setMinimizeMap] = useState(false);

  const propertyManagementRecords = useMemo(() => rawPropertyManagementRecords.map(pmr => ({
    ...pmr,
    property: coalesceProperty(pmr.property),
  })), [rawPropertyManagementRecords]);

  const uniqueMarkets = map(uniq(map(propertyManagementRecords, ({ property: { market } }) => market)), (market) => ({ label: market, value: market }));
  const uniqueTags = uniq(flatMap(propertyManagementRecords, 'tags')).map(tag => ({ label: tag, value: tag }));
  const nameFilteredRecords = filters.name ? propertyManagementRecords.filter(record => record.property.address.toLowerCase().includes(filters.name.toLowerCase())) : propertyManagementRecords;
  const marketFilteredRecords = filters.markets?.length > 0 ? nameFilteredRecords.filter(record => filters.markets.includes(record.property.market)) : nameFilteredRecords;
  const filteredRecords = filters.tags?.length > 0 ? marketFilteredRecords.filter(record => some(record.tags, tag => filters.tags.includes(tag))) : marketFilteredRecords;

  const tableContainerRef = useRef();
  const tableContainerRefHeight = useElementHeight(tableContainerRef);

  return (
    <div className="bg-gray-100 h-screen">
      <Header />
      <ListSummary
        filters={filters}
        minimizeMap={minimizeMap}
        propertyManagementRecords={filteredRecords}
        setFilters={setFilters}
        setMinimizeMap={setMinimizeMap}
      />
      <div
        ref={tableContainerRef}
        className="w-full flex"
        style={{ height: `calc(100vh - ${LAYOUT.assetManagementHeaderHeight + LAYOUT.assetManagementFilterHeight}px)` }}
      >
        <div className={minimizeMap ? 'w-full' : 'w-3/5'}>
          <PropertyTable
            isLoading={isLoading}
            propertyManagementRecords={filteredRecords}
            setHoveredId={setHoveredId}
            tableHeight={tableContainerRefHeight}
          />
        </div>
        {!minimizeMap && !isLoading && (
          <div className="w-2/5 h-full">
            <PropertyMap
              hoveredId={hoveredId}
              propertyManagementRecords={filteredRecords}
            />
          </div>
        )}
      </div>
    </div>
  );
}
