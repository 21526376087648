import { LoadingIndicator, Map } from 'components/icons';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatCurrency, formatInteger } from 'components/utils';
import OfferForm from 'components/deal/offer/OfferForm';
import NewPhotoModal from 'components/property/components/NewPhotoModal';
import MapModal from 'components/property/components/MapModal';
import Badge from 'components/shared/Badge';
import StreetView from 'components/property/components/StreetView';
import { useSubmitExternalOfferMutation, useFetchDealByExternalIdQuery } from 'redux/offerApiSlice';
import { snakeCase } from 'lodash';

const UNPROCESSABLE_ENTITY_CODE = 422;
const TOO_MANY_REQUEST_CODE = 429;
const TOO_MANY_REQUEST_MESSAGE = 'Too many requests. Please try again later.';

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  cameraControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
  zoom: 12,
  minZoom: 7,
  mapTypeControl: true,
  mapTypeControlOptions: { mapTypeIds: ['roadmap', 'satellite', 'hybrid'] },
};

function NoLongerAcceptingOffers() {
  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-red-600">Offer Closed</h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-de-900 md:text-4xl">
            This deal is no longer accepting offers.
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            We're sorry, but this deal is no longer open for new offers.
          </p>
          <a
            href="/"
            className="inline-flex text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
          >
            Back to Homepage
          </a>
        </div>
      </div>
    </section>
  );
}

function NotFoundPage() {
  return (
    <section className="bg-white ">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">404</h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">Something's missing.</p>
          <p className="mb-4 text-lg font-light text-gray-500 ">Sorry, we can't find that page. </p>
          <a
            href="/"
            className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
          >
            Back to Homepage
          </a>
        </div>
      </div>
    </section>
  );
}

function PropertyDetail({ deal }) {
  const { oneLineAddress, listing, property } = deal.data.attributes;

  const allItems = [
    ['Address', oneLineAddress],
    ['List Price', formatCurrency(listing.listPrice)],
    ['Property Type', listing.propertyType],
    ['Beds', property.propertyBedroomsTotal],
    ['Baths', property.propertyBathroomsTotal],
    ['Stories', property.numberOfFloors],
    ['Building Area', formatInteger(property.livingAreaSf || property.rentableBuildingArea)],
    ['Lot Area', formatInteger(property.landAreaSf)],
    ['Year Built', property.yearBuilt],
    ['Construction', property.constructionMaterial],
    ['County', property.county],
  ];
  return (
    <div className="w-full bg-white rounded-xl h-max py-3 px-4 border border-gray-300">
      <div className="pb-3 text-neutral-dark font-medium text-base">Property Details</div>
      {allItems.map((item, index) => (
        <div key={item[0]} className={`flex justify-between items-center min-h-9 py-2 items-center${index === allItems.length - 1 ? '' : ' border-b'}`}>
          <div className="text-sm text-neutral font-semibold uppercase">{item[0]}</div>
          <div className="text-neutral-dark text-sm font-semibold max-w-48 text-right">{item[1]}</div>
        </div>
      ))}
    </div>
  );
}

function PropertyMap({ property }) {
  const [showMapModal, setShowMapModal] = useState(false);
  return (
    <div className="w-full h-72">
      <StreetView property={property} setShowMapModal={setShowMapModal} showMapModal={showMapModal} />
      <div className="relative">
        <Map properties={[property]} options={defaultMapOptions} borderRadius="0.5rem" />
        <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={property}>
          <Map properties={[property]} />
        </MapModal>
        <Badge label="Click to expand" className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg" visible onClick={() => setShowMapModal(true)} />
      </div>
    </div>
  );
}

export default function ExternalPage() {
  const { externalId } = useParams();
  const [alert, setAlert] = useState(null);
  const [offerData, setOfferData] = useState({});
  const { data: deal, error, isError, isLoading } = useFetchDealByExternalIdQuery(externalId);
  const [submitOffer, { isLoading: isSubmitting }] = useSubmitExternalOfferMutation();

  if (isError) {
    if (error.status === 404) {
      return <NotFoundPage />;
    } else if (error.status === 403) {
      return <NoLongerAcceptingOffers />;
    }
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="py-10">
          <LoadingIndicator className="inline ml-2 h-6" />
        </div>
      </div>
    );
  }

  const { property, listing, pictures } = deal.data.attributes;

  const handleSubmit = async submitData => {
    setAlert(null);
    try {
      const formData = new FormData();
      formData.append('external_id', externalId);
      formData.append('deal_id', deal.data.id);

      Object.keys(submitData).forEach((key) => {
        const snakeCasedKey = snakeCase(key);
        if (key !== 'attachments') {
          formData.append(`offer[${snakeCasedKey}]`, submitData[key]);
        }
      });

      if (submitData.attachments) {
        Array.from(submitData.attachments).forEach((file) => {
          formData.append('offer[attachments][]', file);
        });
      }

      const { error: submitError } = await submitOffer(formData);

      if (submitError) {
        if (submitError.status === UNPROCESSABLE_ENTITY_CODE) {
          setAlert({ type: 'danger', text: submitError.data.error });
        } else if (submitError?.originalStatus === TOO_MANY_REQUEST_CODE) {
          setAlert({ type: 'danger', text: TOO_MANY_REQUEST_MESSAGE });
        }
      } else {
        setAlert({ type: 'success', text: 'Offer Submitted Successfully.' });
        setOfferData({
          price: '',
          earnestMoneyDeposit: '',
          concession: '',
          financingContingencyDays: '',
          appraisalContingencyDays: '',
          attachments: null,
        });
      }
    } catch (e) {
      console.error('Failed to submit offer:', e);
      setAlert({ type: 'danger', text: 'An error occurred while submitting the offer.' });
    }
  };

  return (
    <div className="w-full p-2 md:px-40 bg-neutral-surface-light">
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-5">
        <div className="rounded-lg border border-gray-200">
          <OfferForm
            offerData={offerData}
            setOfferData={setOfferData}
            onSubmit={handleSubmit}
            onClose={() => setOfferData({})}
            isLoading={isSubmitting}
            alert={alert}
            setAlert={setAlert}
            title={`Submit offer for ${deal.data.attributes.name}`}
            includeAttachments
          />
        </div>
        <PropertyDetail deal={deal} />
        <div className="flex flex-col gap-y-4">
          <NewPhotoModal pictures={pictures} property={property} listing={listing} />
          <PropertyMap property={property} />
        </div>
      </div>
    </div>
  );
}
