import cx from 'classnames';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import { isNil, startCase, sumBy } from 'lodash';
import { useFetchUsersQuery } from 'redux/apiSlice';
import { markAsDeadModal, newBuildOfferModal, setShowCreateDealModal } from 'actions/deal_navigation';
import { Chevron, Folder, Plus, NotAllowed } from 'components/icons';
import Button from 'components/shared/NewButton';
import { determinePipelineStatus, formatAddress, formatCounty, formatDate, formatInteger, titleCase, userDisplay } from 'components/utils';
import HoverChip from 'components/shared/HoverChip';
import { formatDistanceStrict } from 'date-fns';
import DealActionMenu from 'components/DealBase/DealActionMenu';
import DownloadMenu from 'components/DealBase/DownloadMenu';
import Badge from 'components/shared/Badge';
import { getNextTask } from 'components/dashboard/milestone';
import { DEAL_STATE_CLOSED, DEAL_STATE_DEAD } from 'components/constants';
import { HorizontalTabs, LinkTab } from 'components/shared/Tabs';
import { createStaticMapUrl } from 'components/staticmap';
import { CenterOnAnchor, PunchHoleMarker } from 'components/DealSourcing/MarkerIcons';
import IndicatorBadge from 'components/shared/IndicatorBadge';
import { useIsPortfolioDeal, useShowPortfolioLayout } from './hooks';

function DealContext({ deal }) {
  const { data: users = [] } = useFetchUsersQuery({ organizationId: deal?.organizationId });
  const usersById = users.reduce((agg, user) => ({ ...agg, [user.id]: user }), {});

  const nextTask = useMemo(() => getNextTask(deal), [deal]);

  let hoverInfo = [
    { label: 'Next Task', value: nextTask?.name },
    { label: 'Due', value: formatDate(nextTask?.dueDate, 'MMM d, yyyy') || 'N/A' },
    { label: 'Assigned To', value: userDisplay(usersById[nextTask?.userId], 'Unassigned') },
  ];

  // TODO: lazy format for date distance
  // need to clean this up
  const closingDate = formatDate(deal.closingDate, 'MMM d, yyyy') || 'N/A';
  let daysRemaining = formatDistanceStrict(new Date(deal.closingDate), new Date(), { addSuffix: true, unit: 'day' });

  if (daysRemaining.includes('ago')) {
    daysRemaining = daysRemaining.replace('ago', '');
    daysRemaining = `-${daysRemaining}`;
  }
  daysRemaining = daysRemaining.replace('in', '');
  daysRemaining = daysRemaining.replace('days', '');
  daysRemaining = daysRemaining.replace('day', '');

  const closingInfo = [
    { label: 'Closing', value: closingDate || 'N/A' },
    { label: 'Days Remaining', value: daysRemaining || 'N/A' },
    { label: 'Lead', value: deal.leadName || 'N/A' },
  ];
  if (deal.pipelineStatus === DEAL_STATE_CLOSED) {
    hoverInfo = [{ label: DEAL_STATE_CLOSED, value: closingDate || 'N/A' }];
  } else if (deal.pipelineStatus === DEAL_STATE_DEAD) {
    hoverInfo = [{ label: 'Reason', value: titleCase(deal.deadReason) || 'N/A' }];
  }

  // TODO: indicate if no parcels assigned once we finalize design and workflow for assigning parcel
  // const noParcelsAssigned = isEmpty(parcels) || every(parcels, p => isNil(p.id) && isNil(p.fipsApn));
  const pipelineStatus = determinePipelineStatus(deal.pipelineStatus, deal.transactionType);

  const shouldShowCloseDate = deal?.closingDate && ['Diligence', 'Closing'].includes(deal?.pipelineStatus);

  return (
    <div className="flex items-center gap-x-2">
      <HoverChip label={pipelineStatus} hoverInfo={hoverInfo} color="blue" />
      {shouldShowCloseDate && <HoverChip label={`Closing ${closingDate}`} hoverInfo={closingInfo} /> }
    </div>
  );
}

function DealActions() {
  const { data: { listing, deal, subdivision }, modelData } = useOutletContext();
  const dispatch = useDispatch();

  const isNewBuild = !!subdivision;
  if (deal) {
    const showMakeOffer = isNil(deal.offerCreatedAt);

    return (
      <div className="flex items-center gap-x-2">
        <div className="h-9 border-l" />
        {isNewBuild && showMakeOffer && (
          <>
            <Button textOnly label="Pass on Model" leadingIcon={<NotAllowed className="w-4" />} onClick={() => dispatch(markAsDeadModal())} />
            <Button filled label="Make Offer" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(newBuildOfferModal())} />
          </>
        )}
        {!isNewBuild && (
          <DownloadMenu deal={deal} modelData={modelData} />
        )}
        <DealActionMenu deal={deal} />
      </div>
    );
  } else if (isNewBuild) {
    return (
      <>
        <Button textOnly label="Pass on Model" leadingIcon={<NotAllowed className="w-4" />} onClick={() => dispatch(markAsDeadModal())} />
        <Button filled label="Start Underwriting" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
      </>
    );
  } else if (listing?.id) {
    return (
      <>
        <Button textOnly label="Mark as Dead" leadingIcon={<NotAllowed className="w-4" />} onClick={() => dispatch(markAsDeadModal())} />
        <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />
      </>
    );
  }
  return <Button filled label="Create Deal" leadingIcon={<Plus className="w-4" />} onClick={() => dispatch(setShowCreateDealModal(true))} />;
}

function DealBreadcrumb({ children }) {
  return (
    <div className="relative flex items-center gap-x-4 p-2 h-14 rounded-xl text-neutral-dark border-transparent hover:bg-primary-hover focus-within:bg-primary-focus">
      {children}
    </div>
  );
}

function DealBreadcrumbThumbnail({ className, children }) {
  return (
    <div className={cx('relative h-full w-auto aspect-square flex justify-center items-center overflow-clip bg-white rounded-xl border', className)}>
      {children}
    </div>
  );
}

function DealBreadcrumbLink({ className, children, ...props }) {
  return (
    <Link {...props} className={cx('focus:outline-none focus-visible:outline-none after:absolute after:inset-0', className)}>
      {children}
    </Link>
  );
}

function PortfolioBreadCrumb() {
  const { data: { deal, portfolio } } = useOutletContext();
  const markets = portfolio.markets.length > 1 ? `${portfolio.markets.length} markets` : `${portfolio.markets[0]}`;

  return (
    <DealBreadcrumb>
      <DealBreadcrumbThumbnail className={deal ? '!bg-primary-light border-primary-light' : undefined}>
        <Folder className="w-full h-auto m-2 fill-primary-dark" filled={!!deal} />
      </DealBreadcrumbThumbnail>
      <div className="h-full flex flex-col justify-between">
        <DealBreadcrumbLink to="/pipeline">{portfolio.name}</DealBreadcrumbLink>
        <p className="text-xs text-neutral-light">{portfolio.client ?? markets}</p>
      </div>
    </DealBreadcrumb>
  );
}

function NewBuildAvailabilityBadge({ homeModel }) {
  const { futureDeliveries, numAvailable } = homeModel;
  const availableCount = numAvailable + sumBy(futureDeliveries, fd => fd[1]);
  return (
    <Badge className="flex items-center bg-gray-100 border border-gray-200" px="px-2" py="py-px" label={`${availableCount} Available`} />
  );
}

function ContextBreadCrumb() {
  const { id, path, data } = useOutletContext();
  const { listing, properties, deal, property, homeModels, subdivision, homeBuilder } = data;

  const isPortfolio = useIsPortfolioDeal();
  const photos = listing?.pictures || property?.pictures || properties[0]?.pictures;
  const propertyInfo = [];
  if (isPortfolio) {
    if (deal && homeModels?.length) {
      propertyInfo.push('New Build');
      propertyInfo.push('·');
      propertyInfo.push(subdivision.name);
      propertyInfo.push('·');
      propertyInfo.push(homeBuilder.name);
    } else {
      propertyInfo.push('Portfolio Deal');
      propertyInfo.push('·');
      propertyInfo.push(`${properties.length} Properties`);
      propertyInfo.push('·');
      propertyInfo.push(`${startCase(deal.modellingMethod)} Model`);
    }
  } else {
    if (property?.isSingleFamily) {
      propertyInfo.push(`${property.bedrooms} Bed`);
      propertyInfo.push('·');
      propertyInfo.push(`${property.bathrooms} Bath`);
    } else {
      propertyInfo.push(`${property.numberOfUnits} Units`);
    }
    if (property?.livingArea) {
      propertyInfo.push('·');
      propertyInfo.push(`${formatInteger(property?.livingArea)} Sq Ft.`);
    }
    if (property?.yearBuilt) {
      propertyInfo.push('·');
      propertyInfo.push(`Built ${property.yearBuilt}`);
    }
  }
  const homeModel = property?.homeModelId && homeModels.find(hm => hm.id === property.homeModelId);
  const dealLabel = isPortfolio ? deal.name : formatAddress({
    ...property,
    address: deal?.name || property?.address,
  });

  return (
    <DealBreadcrumb>
      {photos.length > 0 && (
        <DealBreadcrumbThumbnail>
          <img
            src={photos[0].url}
            alt="listing"
            referrerPolicy="no-referrer"
            className="size-full object-cover"
          />
        </DealBreadcrumbThumbnail>
      )}
      <div className="h-full flex flex-col content-between">
        <div className="flex justify-between">
          <DealBreadcrumbLink to={`/${path}/${id}`}>
            {dealLabel}
          </DealBreadcrumbLink>
          {homeModel && <NewBuildAvailabilityBadge homeModel={homeModel} />}
        </div>
        <div className="flex gap-x-2 text-xs text-neutral-light">
          {propertyInfo.map((info, index) => (<span key={index}>{info}</span>))}
        </div>
      </div>
    </DealBreadcrumb>
  );
}

function PropertyBreadcrumbNav() {
  const { id, path, data: { property } } = useOutletContext();
  const linkTo = `/${path}/${id}/properties/${property.id}`;
  return <PropertyBreadcrumb id={id} linkTo={linkTo} property={property} />;
}

export function PropertyBreadcrumb({ linkTo, property }) {
  const photos = property.pictures || [];
  const propertyInfo = [];
  if (property?.isSingleFamily) {
    propertyInfo.push(`${property.bedrooms} Bed`);
    propertyInfo.push('·');
    propertyInfo.push(`${property.bathrooms} Bath`);
  } else {
    propertyInfo.push(`${property.numberOfUnits} Units`);
  }
  if (property?.livingArea) {
    propertyInfo.push('·');
    propertyInfo.push(`${formatInteger(property?.livingArea)} Sq Ft.`);
  }
  if (property?.yearBuilt) {
    propertyInfo.push('·');
    propertyInfo.push(`Built ${property.yearBuilt}`);
  }

  return (
    <DealBreadcrumb>
      {photos.length > 0 && (
        <DealBreadcrumbThumbnail>
          <img
            src={photos[0].url}
            alt="listing"
            referrerPolicy="no-referrer"
            className="size-full object-cover"
          />
        </DealBreadcrumbThumbnail>
      )}
      <div className="h-full flex flex-col justify-between">
        <DealBreadcrumbLink to={linkTo}>
          {titleCase(property.address)}
        </DealBreadcrumbLink>
        <div className="flex gap-x-2 text-xs text-neutral-light">
          {propertyInfo.map((info, index) => (<span key={index}>{info}</span>))}
        </div>
      </div>
    </DealBreadcrumb>
  );
}

function HomeModelBreadcrumb() {
  const { id, path, propertyId, data: { homeModel } } = useOutletContext();
  const { images } = homeModel;

  const homeModelInfo = [
    `${homeModel.bedrooms} Bed`,
    '·',
    `${homeModel.bathroomsFull} Bath`,
  ];

  return (
    <DealBreadcrumb>
      {images.length > 0 && (
        <DealBreadcrumbThumbnail>
          <img
            src={images[0].url}
            alt="listing"
            referrerPolicy="no-referrer"
            className="size-full object-cover"
          />
        </DealBreadcrumbThumbnail>
      )}
      <div className="h-full flex flex-col justify-between">
        <DealBreadcrumbLink to={`/${path}/${id}/properties/${propertyId}`}>
          {homeModel.plan}
        </DealBreadcrumbLink>
        <div className="flex gap-x-2 text-xs text-neutral-light">
          {homeModelInfo.map((info, index) => (<span key={index}>{info}</span>))}
        </div>
      </div>
    </DealBreadcrumb>
  );
}

function SubdivisionBreadcrumb() {
  const { id, path, data: { subdivision, homeBuilder } } = useOutletContext();
  const url = createStaticMapUrl({
    latitude: subdivision.latitude,
    longitude: subdivision.longitude,
    width: 64,
    height: 64,
  });

  return (
    <DealBreadcrumb>
      <DealBreadcrumbThumbnail>
        <img
          src={url}
          alt="subdivision location"
          referrerPolicy="no-referrer"
          className="size-full object-cover"
        />
        <CenterOnAnchor className="text-primary-600">
          <PunchHoleMarker dim="20" fill="currentColor" strokeWidth="2" />
        </CenterOnAnchor>
      </DealBreadcrumbThumbnail>
      <div className="h-full flex flex-col justify-between">
        <DealBreadcrumbLink to={`/${path}/${id}/subdivision`}>
          {subdivision.name}
        </DealBreadcrumbLink>
        <div className="flex gap-x-2 text-xs text-neutral-light">
          <span>{homeBuilder.name}</span>
          <span>·</span>
          <span>{`${formatCounty(subdivision.county)} County`}</span>
        </div>
      </div>
      <IndicatorBadge
        count={subdivision.unreviewedHomeModelsCount}
        title={`${subdivision.unreviewedHomeModelsCount} unreviewed home ${subdivision.unreviewedHomeModelsCount === 1 ? 'model' : 'models'}`}
      />
    </DealBreadcrumb>
  );
}

function NavTop({ canTakeDealActions }) {
  const { data, propertyId } = useOutletContext();
  const { parcels, deal, homeModel, subdivision } = data;

  const chevron = <Chevron direction="right" className="w-4 text-gray-500" />;

  return (
    <div className="flex justify-between items-center pt-3 px-2">
      <div className="w-8/12 px-4 flex items-center gap-x-2">
        <PortfolioBreadCrumb />
        {subdivision && (
          <>
            {chevron}
            <SubdivisionBreadcrumb />
          </>
        )}
        {chevron}
        <ContextBreadCrumb />
        {propertyId && (
          <>
            {chevron}
            {homeModel ? <HomeModelBreadcrumb /> : <PropertyBreadcrumbNav />}
          </>
        )}
      </div>
      {canTakeDealActions && (
        <div className="flex gap-x-4 items-center">
          {deal && <DealContext deal={deal} parcels={parcels} />}
          <DealActions />
        </div>
      )}
    </div>
  );
}

function NavBottom({ canTakeDealActions }) {
  const { id, path, propertyId, data: { currentUser } } = useOutletContext();
  const location = useLocation();
  const { newBuildOnly } = currentUser.organization.config;

  // TODO: typicaly NavLink active functionality doesn't work due to nested routes
  const isModel = location.pathname.includes('/model');
  const isRentComps = location.pathname.includes('/rent_comps');
  const isSaleComps = location.pathname.includes('/sale_comps');
  const isWorkflow = location.pathname.includes('/workflow');
  const isProperty = !isModel && !isRentComps && !isSaleComps && !isWorkflow;

  const showPortfolioLayout = useShowPortfolioLayout();
  const basePath = `${path}/${id}${propertyId ? `/properties/${propertyId}` : ''}`;

  return (
    <HorizontalTabs>
      <LinkTab to={basePath} active={isProperty}>
        {showPortfolioLayout ? 'Portfolio' : 'Property'}
      </LinkTab>
      {(!showPortfolioLayout && !newBuildOnly) && (
        <>
          <LinkTab to={`${basePath}/rent_comps`} active={isRentComps}>Rent Comps</LinkTab>
          <LinkTab to={`${basePath}/sale_comps`} active={isSaleComps}>Sale Comps</LinkTab>
        </>
      )}
      <LinkTab to={`${basePath}/model`} active={isModel}>Model</LinkTab>
      {canTakeDealActions && <LinkTab to={`${basePath}/workflow`} active={isWorkflow}>Workflow</LinkTab>}
    </HorizontalTabs>
  );
}

export default function Navigation({ canTakeDealActions }) {
  return (
    <div className="w-full top-0 left-0 flex flex-col bg-white border-b">
      <NavTop canTakeDealActions={canTakeDealActions} />
      <NavBottom canTakeDealActions={canTakeDealActions} />
    </div>
  );
}
