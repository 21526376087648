import { Disclosure } from '@headlessui/react';
import { ABBR_MONTHS, HISTORY_RECORD_TYPES, UNIT_RENT_INACTIVE_STATUS } from 'components/constants';
import { formatCurrency, formatListingSource, formatPercentage, titleCase } from 'components/utils';

const STATUS_BADGE_COLORS = {
  'Active': 'bg-success-100 text-success',
  'Closed': 'bg-gray-100',
  'Cancelled': 'border border-gray-200',
  'Inactive': 'border border-gray-200',
  'Coming Soon': 'border border-gray-200',
  'Expired': 'border border-gray-200',
  'Pending': 'border border-gray-200',
};

function StatusBadge({ status }) {
  return (
    <div className={`flex items-center text-xs py-2 h-full px-4 rounded-lg font-medium ${STATUS_BADGE_COLORS[status]}`}>{status}</div>
  );
}

export function formattedHistoryDate(date) {
  const month = ABBR_MONTHS[Number(date.match(/([0-9])\w+/g)[1]) - 1];
  const day = Number(date.match(/([0-9])\w+/g)[2]);
  return month;
}

export function extractHistoryValues(historyItem) {
  const { attributes } = historyItem;
  const { type, details } = attributes;

  switch (type) {
    case HISTORY_RECORD_TYPES.RECORDER: {
      const { documentAmount } = details;
      return documentAmount > 0 ? documentAmount : null;
    }

    case HISTORY_RECORD_TYPES.RENT_LISTING: {
      const { actualRent, currentAskingRent } = details;
      return actualRent || currentAskingRent;
    }

    case HISTORY_RECORD_TYPES.LISTING: {
      const { listPrice } = details;
      return listPrice;
    }

    case HISTORY_RECORD_TYPES.VALUATION: {
      const { estimatedValueAmount } = details;
      return estimatedValueAmount;
    }

    default:
      console.warn(`Unknown history record type: ${type}`);
      return null;
  }
}

function RecorderEvent({ details, item }) {
  const { documentAmount } = details;
  const { recorderDocumentType } = item.attributes;

  return (
    <div className="grow flex justify-between items-center">
      <div className="flex items-center gap-x-4">
        <div>
          <div className="font-medium text-lg text-black">Recorder</div>
          <div className="font-light text-xs">{titleCase(recorderDocumentType)}</div>
        </div>
      </div>
      {(documentAmount > 0) && (
        <div className="px-4 py-2 bg-gray-50 border rounded-xl font-medium text-md text-black">{formatCurrency(documentAmount)}</div>
      )}
    </div>
  );
}

function RentEvent({ details }) {
  const { actualRent, currentAskingRent, resolvedStatus, sourceName, sourceId } = details;
  const subText = sourceId ? `${sourceName} - #${sourceId.toUpperCase()}` : sourceName;

  return (
    <div className="grow flex justify-between items-center">
      <div className="flex items-center gap-x-4">
        <div>
          <div className="font-medium text-lg text-black">Rental Listing</div>
          <div className="font-light text-xs">{subText}</div>
        </div>
        {(resolvedStatus !== UNIT_RENT_INACTIVE_STATUS) && <StatusBadge status={resolvedStatus} />}
      </div>
      <div className="px-4 py-2 bg-gray-50 border rounded-xl font-medium text-md text-black">{formatCurrency(actualRent || currentAskingRent)}</div>
    </div>
  );
}

function ListingEvent({ details }) {
  const { listingId, listPrice, source, standardStatus } = details;
  const subText = `${formatListingSource(source)} - #${listingId.toUpperCase()}`;

  return (
    <div className="grow flex justify-between items-center">
      <div className="flex items-center gap-x-4">
        <div>
          <div className="font-medium text-lg text-black">Listing</div>
          <div className="font-light text-xs">{subText}</div>
        </div>
        <StatusBadge status={standardStatus} />
      </div>
      <div className="px-4 py-2 bg-gray-50 border rounded-xl font-medium text-md text-black">{formatCurrency(listPrice)}</div>
    </div>
  );
}

function ValuationEvent({ details }) {
  const { confidenceScore, estimatedValueAmount } = details;

  return (
    <div className="grow flex justify-between items-center">
      <div className="flex items-center gap-x-4">
        <div>
          <div className="font-medium text-lg text-black">Valuation</div>
          <div className="font-light text-xs">{formatPercentage(confidenceScore / 100, 0)}</div>
        </div>
      </div>
      <div className="px-4 py-2 bg-gray-50 border rounded-xl font-medium text-md text-black">{formatCurrency(estimatedValueAmount)}</div>
    </div>
  );
}

function HistoryRow({ item, setSelectedModalItem }) {
  const { date, details } = item.attributes;
  const month = ABBR_MONTHS[Number(date.match(/([0-9])\w+/g)[1]) - 1];
  const day = Number(date.match(/([0-9])\w+/g)[2]);

  let eventComponent;
  if (item.attributes.type === HISTORY_RECORD_TYPES.LISTING) {
    eventComponent = <ListingEvent details={details} />;
  } else if (item.attributes.type === HISTORY_RECORD_TYPES.RECORDER) {
    eventComponent = <RecorderEvent details={details} item={item} />;
  } else if (item.attributes.type === HISTORY_RECORD_TYPES.RENT_LISTING) {
    eventComponent = <RentEvent details={details} />;
  } else if (item.attributes.type === HISTORY_RECORD_TYPES.VALUATION) {
    eventComponent = <ValuationEvent details={details} />;
  } else {
    throw new Error(`Unknown history record type: ${item.attributes.type}`);
  }

  return (
    <div className="w-full flex flex-row justify-between items-center px-6 py-5 border-t cursor-pointer hover:bg-gray-50 rounded-b-xl" onClick={() => setSelectedModalItem(item)}>
      <div className="flex flex-col rounded-xl bg-primary-100 text-primary-7000 px-4 py-2 mr-4 justify-evenly items-center text-primary-600 font-normal tracking-widest">
        <span>{month}</span>
        <span className="text-xl">{day}</span>
      </div>
      {eventComponent}
    </div>
  );
}

export default function HistoryRows({ records, year, setSelectedModalItem }) {
  return (
    <div className="w-full mx-auto rounded-xl border bg-white mb-6">
      <Disclosure defaultOpen>
        {() => (
          <>
            <Disclosure.Button className="flex justify-between items-center w-full px-4 py-4 rounded-xl text-sm text-left hover:bg-gray-50 hover:rounded-b-xl focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
              <div className="flex flex-row">
                <h2 className="text-xl mr-4">{year}</h2>
                <div className="px-3 py-1 border text-xs text-gray-500 rounded-xl">{records.length < 2 ? `${records.length} event` : `${records.length} events`}</div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="flex flex-col text-sm text-gray-500 rounded-b-xl">
              {records.map(item => <HistoryRow item={item} key={item.id} setSelectedModalItem={setSelectedModalItem} />)}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
