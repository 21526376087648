import { useCallback, useState } from 'react';
import { Config } from 'components/icons';
import Chip from 'components/shared/Chip';
import AllFiltersModal from './AllFiltersModal';
import { useSelectedFilters } from './filterHooks';

export default function AllFiltersChip() {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => { setIsOpen(false); }, []);
  const open = useCallback(() => { setIsOpen(true); }, []);

  const selectedFilters = useSelectedFilters();

  return (
    <>
      <Chip
        className="[&:not([data-chip-selected])]:bg-white"
        preferLeadingIcon
        label={`All Filters${selectedFilters.length ? ` • ${selectedFilters.length}` : ''}`}
        leadingIcon={<Config />}
        onClick={open}
        selected={!!selectedFilters.length}
      />
      <AllFiltersModal show={isOpen} close={close} />
    </>
  );
}
