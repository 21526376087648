import ComboboxDropdown from 'components/shared/ComboboxDropdown';
import { useCallback, useMemo } from 'react';
import FilterControl from './FilterControl';
import { useFilterInputState } from './useFilterInputState';

export default function ComboboxFilter({ options, keyBy, keyOnly = false, ...props }) {
  const [filterState, setFilterState] = useFilterInputState();
  const keyOnlyFilterState = !!keyOnly && (keyBy !== undefined && keyBy !== null);

  const onChange = useCallback((value) => {
    setFilterState(keyOnlyFilterState ? value[keyBy] : value);
  }, [keyBy, keyOnlyFilterState, setFilterState]);

  const value = useMemo(() => {
    if (keyOnlyFilterState) {
      return options.find((option) => option[keyBy] === filterState);
    }

    return filterState;
  }, [filterState, keyBy, keyOnlyFilterState, options]);

  return (
    <FilterControl>
      <ComboboxDropdown
        {...props}
        options={options}
        keyBy={keyBy}
        value={value}
        onChange={onChange}
      />
    </FilterControl>
  );
}
