import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Check, Pencil, Plus, Refresh } from 'components/icons';
import Button from 'components/shared/NewButton';
import { useClearRowUpdates, useGetUpdatedRowData, useIsTableEdited } from 'components/shared/Table/DataTableContext';
import GlobalSearchInput from 'components/shared/Table/GlobalSearchInput';
import { useUpdateOffMarketListingsMutation } from 'redux/listingApiSlice';
import OffMarketInventoryFilters from './OffMarketInventoryFilters';
import OffMarketInventorySummaryStats from './OffMarketInventorySummaryStats';

export default function OffMarketInventoryTableHeader({ currentUser, isEditMode, setIsEditMode }) {
  const hasChanged = useIsTableEdited();
  const clearChanges = useClearRowUpdates();

  const getUpdatedRows = useGetUpdatedRowData();
  const [
    updateListings,
    {
      isLoading: isUpdating,
      isSuccess,
      isError,
      error,
      reset: resetMutation,
    },
  ] = useUpdateOffMarketListingsMutation();

  if (error) {
    console.error(error);
  }

  const onEditToggle = useCallback(() => {
    resetMutation();
  }, [resetMutation]);

  const toggleEdit = useCallback(() => {
    onEditToggle?.();
    setIsEditMode((prev) => !prev);
  }, [onEditToggle, setIsEditMode]);

  const onCancel = useCallback(() => {
    clearChanges();
    toggleEdit();
  }, [clearChanges, toggleEdit]);

  useEffect(() => {
    if (isSuccess) {
      clearChanges();
      setIsEditMode(false);
    }
  }, [clearChanges, setIsEditMode, isSuccess]);

  const onSave = useCallback(() => {
    updateListings({ listings: getUpdatedRows() });
  }, [getUpdatedRows, updateListings]);

  return (
    <div className="flex justify-between items-center gap-x-8 py-1.5 px-3">
      <OffMarketInventoryFilters currentUser={currentUser} />
      <OffMarketInventorySummaryStats className="flex-none" />
      <GlobalSearchInput className="w-64 rounded-full" />
      <div className="flex gap-x-6">
        {isEditMode ? (
          <>
            <Button
              filled
              danger
              label="Cancel"
              className="justify-self-end"
              onClick={onCancel}
            />
            <Button
              filled
              label={isError ? 'Retry' : 'Save'}
              leadingIcon={isError ? <Refresh /> : <Check />}
              disabled={!hasChanged}
              onClick={onSave}
              isLoading={isUpdating}
            />
          </>
        ) : (
          <>
            <Button
              className="relative overflow-clip [overflow-clip-margin:content-box]"
              filled
              label={(
                <Link
                  to="/off_market_marketplace/listings/new"
                  className="after:absolute after:inset-0"
                >
                  Add Listing
                </Link>
              )}
              leadingIcon={<Plus />}
            />
            <Button
              filled
              label="Edit"
              leadingIcon={<Pencil />}
              onClick={toggleEdit}
            />
          </>
        )}
      </div>
    </div>
  );
}
