import { isEmpty } from 'lodash';
import { parseISO } from 'date-fns';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchDealImportJobsQuery } from 'redux/dealImportJobApiSlice';
import DataTable from 'components/shared/Table/DataTable';
import { dataTableMeta, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { RouteLinkCell } from 'components/shared/Table/Cells';
import { CheckCircle, ClockArrowUp, LoadingIndicator, Warning } from 'components/icons';
import { formatDate, formatInteger, titleCase } from 'components/utils';
import { DEAL_IMPORT_INDIVIDUAL_DEALS_IMPORT_TYPE, DEAL_IMPORT_JOB_STATUS } from 'components/constants';

const columnHelper = createColumnHelper();

const {
  STATUS_VALIDATING,
  STATUS_MATCHED,
  STATUS_IMPORTING,
  STATUS_COMPLETE,
  STATUS_ERROR,
} = DEAL_IMPORT_JOB_STATUS;

const TABLE_INITIAL_STATE = {
  columnVisibility: { id: false },
  sorting: [{ id: 'id', desc: true }],
};

const parseJobStatus = ({ resultData, validationData }) => {
  if (!isEmpty(resultData)) {
    return isEmpty(resultData.results) ? STATUS_IMPORTING : STATUS_COMPLETE;
  }
  if (!isEmpty(validationData)) {
    const hasValidationError = validationData.matchResults?.filter(([, parcel]) => !parcel)?.length;
    return hasValidationError ? STATUS_ERROR : STATUS_MATCHED;
  }
  return STATUS_VALIDATING;
};

const IMPORT_STATUS_LABEL_MAP = {
  [STATUS_COMPLETE]: (
    <div className="flex items-center gap-x-2">
      <div className="flex justify-center w-6">
        <CheckCircle className="size-6 text-success-500" filled />
      </div>
      <div>Complete</div>
    </div>
  ),
  [STATUS_ERROR]: (
    <div className="flex items-center gap-x-2">
      <div className="flex justify-center w-6">
        <Warning className="size-5 text-warning-500" />
      </div>
      <div>Verify addresses</div>
    </div>
  ),
  [STATUS_IMPORTING]: (
    <div className="flex items-center gap-x-2">
      <div className="flex justify-center w-6">
        <LoadingIndicator className="size-5 text-gray-500" />
      </div>
      <div>Creating deal...</div>
    </div>
  ),
  [STATUS_MATCHED]: (
    <div className="flex items-center gap-x-2">
      <div className="flex justify-center w-6">
        <ClockArrowUp className="size-5 text-gray-500" />
      </div>
      <div>Awaiting confirmation</div>
    </div>
  ),
  [STATUS_VALIDATING]: (
    <div className="flex items-center gap-x-2">
      <div className="flex justify-center w-6">
        <LoadingIndicator className="size-5 text-gray-500" />
      </div>
      <div>Matching addresses...</div>
    </div>
  ),
};

function ImportStatusCell({ getValue }) {
  return IMPORT_STATUS_LABEL_MAP[getValue()] || 'Check';
}

const parseImportName = jobData => {
  const { importData } = jobData;
  if (importData.settings.importType === DEAL_IMPORT_INDIVIDUAL_DEALS_IMPORT_TYPE) {
    return `${importData.csvData[0].dealName}...`;
  } else {
    return importData.settings.dealName;
  }
};

const COLUMNS = [{
  id: 'id',
  accessorKey: 'id',
  meta: { ...dataTableMeta.disableTableConfig },
},
columnHelper.display({
  id: 'link',
  cell: RouteLinkCell,
  meta: {
    linkPath: '/import/import_deals/:id',
    ...tableConfigMeta({ visibility: false, order: 'hidden' }),
  },
}), {
  id: 'name',
  header: 'Name',
  accessorFn: parseImportName,
}, {
  id: 'status',
  header: 'Status',
  accessorFn: parseJobStatus,
  cell: ImportStatusCell,
}, {
  id: 'importType',
  header: 'Import Type',
  accessorFn: ({ importData }) => importData.settings.importType,
  cell: ({ getValue }) => titleCase(getValue()),
}, {
  id: 'properties',
  header: 'Properties',
  accessorFn: ({ importData }) => importData.csvData.length,
  cell: ({ getValue }) => formatInteger(getValue()),
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'updatedAt',
  header: 'Last Update',
  accessorFn: ({ updatedAt }) => (updatedAt ? parseISO(updatedAt) : null),
  cell: ({ getValue }) => formatDate(getValue(), 'yyyy-MM-dd HH:mm:ss zzz'),
  sortingFn: 'datetime',
  sortDescFirst: true,
}, {
  id: 'createdAt',
  header: 'Start Time',
  accessorFn: ({ createdAt }) => (createdAt ? parseISO(createdAt) : null),
  cell: ({ getValue }) => formatDate(getValue(), 'yyyy-MM-dd HH:mm:ss zzz'),
  sortingFn: 'datetime',
  sortDescFirst: true,
}];

export default function ActiveImports() {
  const { data, isLoading } = useFetchDealImportJobsQuery();

  return (
    <div className="py-12 px-8 flex flex-col h-container w-container">
      <h1 className="mb-12 text-2xl text-gray-700">
        Active Imports
      </h1>
      <DataTable
        columns={COLUMNS}
        data={data}
        emptyStateComponent="No import jobs"
        initialState={TABLE_INITIAL_STATE}
        isLoading={isLoading}
      />
    </div>
  );
}
