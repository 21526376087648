import { useMemo } from 'react';
import { Trash } from 'components/icons';
import { ButtonEditCell } from 'components/shared/Table/Cells';
import { restoreCellValueSymbol } from 'components/shared/Table/table.features';

/** @param {import('react').MouseEvent<HTMLButtonElement>} evt */
const onClickEventParser = (evt) => {
  evt.stopPropagation();
  return evt.currentTarget.value === 'Delete' ? restoreCellValueSymbol : 'Delete';
};

const baseEditButtonProps = Object.freeze({
  label: (
    // render both labels to prevent layout shift
    // use grid to display both labels at the same position
    <div className="inline-grid text-left *:row-[1/1] *:col-[1/1] *:invisible">
      <span className="group-[:not([value=Delete])]/btn:visible">Delete</span>
      <span className="group-[[value=Delete]]/btn:visible">Restore</span>
    </div>
  ),
  filled: true,
  small: true,
  leadingIcon: <Trash className="h-[1lh] w-auto" />,
  // use opaque class to unset td opacity
  className: 'opaque group/btn [tr:has(&[value=Delete])]:bg-red-200/25',
});

const editButtonProps = Object.freeze({
  [true]: Object.freeze({ ...baseEditButtonProps, danger: true }),
  [false]: Object.freeze({ ...baseEditButtonProps, danger: false }),
});

/**
 * @param {import('@tanstack/react-table').CellContext} props
 */
export default function DeleteListingCell(props) {
  const { column } = props;

  const columnCopy = useMemo(() => ({
    ...column,
    columnDef: {
      ...column.columnDef,
      meta: {
        ...column.columnDef.meta,
        editButtonProps: (value) => editButtonProps[value !== 'Delete'],
        editEventValueParser: onClickEventParser,
      },
    },
  }), [column]);

  return <ButtonEditCell {...props} column={columnCopy} />;
}
