/* eslint-disable import/no-cycle */
import { cloneDeep, first, get, groupBy, isNil, last, map, partial, pick, range, set, sortBy, sum, take, takeRight, unionBy, uniq, zip, zipWith } from 'lodash';
import { addMonths, startOfMonth } from 'date-fns';
import {
  calcMonthlyCapitalProjects,
  calcBelowTheLineExpenses,
  enabledCapitalItems,
  enabledCapitalFees,
} from './capital';
import {
  calcFutureFundingMonthlyDraws,
  calcLoanOriginationFee,
  calcMonthlyLoanSchedule,
  calcMonthlyOriginationFees,
  calcMonthlyPaymentRepayment,
} from './debt';
import {
  calcExpenseItemCashFlow,
  calcExpenseRatioBasedExpenses,
  calcHoldPlusOneTaxRate,
  calcReimbursableExpenses,
  calcTaxes,
  calcTotalControllableExpenses,
  calcTotalNonControllableExpensesBeforeTaxes,
  calcTurnoverCosts,
} from './expense';
import {
  ITEMIZED_INPUT_METHOD_DOLLAR,
  ITEMIZED_INPUT_METHOD_DPU,
  ITEMIZED_INPUT_METHOD_PRICE,
  ITEMIZED_INPUT_METHODS,
} from './itemizedItem';
import {
  calcConcessions,
  calcGrossPotentialRents,
  calcLossToLeases,
  calcRolloverVacancies,
} from './rent';
import {
  annualizeMonthlyReturns,
  calcStabilizedYields,
  equityMultiple,
  irr,
  newtonRaphson,
} from '../finance';
import { capExStabilizedMonth, dateFromString, deepFlattenKeys, sumArrays, unitStabilizedMonth } from '../utils';

export const ACCOUNTING_METHOD_CASH_FLOW = 'cash_flow';
export const ACCOUNTING_METHOD_FOLLOW_ON = 'follow_on';
export const ACQUISITION_METHOD_ITEMIZED = 'itemized';
export const ACQUISITION_METHOD_PERCENT = 'percent';
export const EXPENSE_METHOD_ITEMIZED = 'itemized';
export const EXPENSE_METHOD_RATIO = 'expense_ratio';
export const TAX_METHOD_PURCHASE_PRICE = 'purchase_price';
export const TAX_METHOD_ASSESSED_VALUED = 'assessed_value';
export const CUSTOM_EXIT_PRICE = 'custom';
export const CALCULATED_EXIT_PRICE = 'calculated';

export const ACQUISITION_CLOSING_COST_INPUT_METHODS = pick(
  ITEMIZED_INPUT_METHODS,
  ITEMIZED_INPUT_METHOD_DOLLAR,
  ITEMIZED_INPUT_METHOD_PRICE,
  ITEMIZED_INPUT_METHOD_DPU,
);

export const ACCOUNTING_METHODS = [
  ACCOUNTING_METHOD_CASH_FLOW,
  ACCOUNTING_METHOD_FOLLOW_ON,
];

export const holdPeriodInMonths = (dcfParams) => (dcfParams.ltOneYrHold ? dcfParams.holdPeriod : dcfParams.holdPeriod * 12);
export const holdPeriodInYears = (dcfParams) => (dcfParams.ltOneYrHold ? 1 : dcfParams.holdPeriod);

const createZeroMonthlyArray = (dcfParams) => new Array(holdPeriodInMonths(dcfParams) + 1).fill(0);

export const isItemized = (dcfParams) => dcfParams.expenseMethod === EXPENSE_METHOD_ITEMIZED;

export const isAcquisitionCostItemized = (dcfParams) => dcfParams.acquisitionCostMethod === ACQUISITION_METHOD_ITEMIZED;

export const isExitPriceCustomized = (dcfParams) => dcfParams.exitPriceMethod === CUSTOM_EXIT_PRICE;

export const acquisitionCostParams = (dcfParams) => ({
  quantity: 1,
  price: dcfParams.purchasePrice ?? 0,
  numberOfUnits: dcfParams.units?.length ?? 0,
});

export const calcAcquisitionCosts = (dcfParams) => {
  if (dcfParams.acquisitionCostMethod === ACQUISITION_METHOD_PERCENT) {
    return dcfParams.purchasePrice * dcfParams.acquisitionCostPercent;
  }
  if (dcfParams.acquisitionCostMethod === ACQUISITION_METHOD_ITEMIZED) {
    const params = acquisitionCostParams(dcfParams);
    return (dcfParams.acquisitionCostItems || []).reduce(
      (total, costItem) => total + (ACQUISITION_CLOSING_COST_INPUT_METHODS[costItem.inputMethod].func(costItem, params)),
      0,
    );
  }
  return 0;
};

export const calculatedCapRate = (cashFlows, dcfParams) => {
  const {
    customExitPrice,
    exitCapRate,
  } = dcfParams;
  const holdPlusOneNoi = sum(takeRight(cashFlows.netOperatingIncome, 12));
  return customExitPrice ? (holdPlusOneNoi / dcfParams.customExitPrice) : exitCapRate;
};

export const calcOpExRatio = (cashFlows) => sum(cashFlows.expenses.totalOperatingExpenses) / sum(cashFlows.revenue.effectiveGrossRevenues);
export const calcOpExRatios = (cashFlows) => cashFlows.expenses.totalOperatingExpenses.map((num, idx) => num / cashFlows.revenue.effectiveGrossRevenues[idx]);

export const calcTotalAcquisitionCost = (dcfParams) => dcfParams.purchasePrice + calcAcquisitionCosts(dcfParams);

// income items have same properties and calculation methods as expense items, so re-use logic but do not require EGR param
export const calcIncomeItemCashFlow = (item, dcfParams, grossRent) => calcExpenseItemCashFlow(item, dcfParams, null, grossRent);

const calcOtherIncomes = (dcfParams, physicalOccupancyRates, grossRent) => {
  if (dcfParams.incomeItems.length === 0) {
    return Array(dcfParams.ltOneYrHold ? (dcfParams.holdPeriod + 12) : ((dcfParams.holdPeriod + 1) * 12)).fill(0);
  }
  return zipWith(
    physicalOccupancyRates,
    ...dcfParams.incomeItems.map(item => calcIncomeItemCashFlow(item, dcfParams, grossRent)),
    (occupancyRate, ...otherIncomePeriodTotal) => sum(otherIncomePeriodTotal) * occupancyRate,
  );
};

export const calcAboveTheLineCashFlows = (dcfParams) => {
  const grossPotentialRents = calcGrossPotentialRents(dcfParams);
  const lossToLeases = calcLossToLeases(dcfParams);
  const rolloverVacancies = calcRolloverVacancies(dcfParams);
  const physicalOccupancyRates = zipWith(grossPotentialRents, rolloverVacancies, (gpr, rolloverVacancy) => (gpr + rolloverVacancy) / gpr);
  const concessions = calcConcessions(dcfParams);
  const grossRents = sumArrays(grossPotentialRents, lossToLeases, rolloverVacancies, concessions);
  const reimbursableExpenses = calcReimbursableExpenses(dcfParams, physicalOccupancyRates);
  const otherIncomes = calcOtherIncomes(dcfParams, physicalOccupancyRates, grossRents);
  const grossRevenues = sumArrays(grossRents, reimbursableExpenses, otherIncomes);
  const staticVacancies = grossRents.map((grossRent, index) => -1 * grossRent * dcfParams.vacancyRates[Math.floor(index / 12)]);
  const collectionLosses = grossRents.map((grossRent, index) => -1 * grossRent * dcfParams.collectionLossRates[Math.floor(index / 12)]);
  const effectiveGrossRevenues = sumArrays(grossRevenues, staticVacancies, collectionLosses);
  const economicOccupancyRates = zipWith(grossPotentialRents, lossToLeases, rolloverVacancies, staticVacancies, collectionLosses, (gpr, ltl, ...rest) => (gpr + ltl + sum(rest)) / (gpr + ltl));

  const occupancy = { physicalOccupancyRates, economicOccupancyRates };

  // TODO: add unit-level breakdown
  const revenue = {
    grossPotentialRents,
    lossToLeases,
    rolloverVacancies,
    concessions,
    grossRents,
    reimbursableExpenses,
    otherIncomes,
    grossRevenues,
    staticVacancies,
    collectionLosses,
    effectiveGrossRevenues,
  };

  let expenses;

  if (dcfParams.expenseMethod === EXPENSE_METHOD_RATIO) {
    const totalOperatingExpenses = calcExpenseRatioBasedExpenses(dcfParams, effectiveGrossRevenues);
    expenses = { totalOperatingExpenses };
  } else {
    const turnoverCosts = calcTurnoverCosts(dcfParams);
    // TODO: break out individual expense items
    const controllableExpenses = calcTotalControllableExpenses(dcfParams, effectiveGrossRevenues, grossRents);
    const nonControllableExpensesBeforeTaxes = calcTotalNonControllableExpensesBeforeTaxes(dcfParams, effectiveGrossRevenues, grossRents);
    // pass in cashflows to calcTaxes calculation to avoid introducing circular dependency
    const taxes = calcTaxes(dcfParams, { revenue, expenses: { controllableExpenses, nonControllableExpensesBeforeTaxes } });
    const nonControllableExpenses = sumArrays(nonControllableExpensesBeforeTaxes, taxes);
    const totalOperatingExpenses = sumArrays(
      controllableExpenses,
      nonControllableExpenses,
    );

    expenses = {
      turnoverCosts,
      controllableExpenses,
      taxes,
      nonControllableExpensesBeforeTaxes,
      nonControllableExpenses,
      totalOperatingExpenses,
    };
  }
  const netOperatingIncome = sumArrays(effectiveGrossRevenues, expenses.totalOperatingExpenses.map(val => val * -1));

  return {
    occupancy,
    revenue,
    expenses,
    netOperatingIncome,
  };
};

export const calculateTotalEquity = (cashFlows) => {
  const purchasePrice = cashFlows.acquisition.price[0] * -1;
  const acquisitionCost = cashFlows.acquisition.cost[0] * -1;
  const acquisitionLoan = cashFlows.financing.acquisitionLoanPaymentRepayments[0];
  const originationFee = cashFlows.financing.loanOriginationFees[0] * -1;
  const totalFutureFundingDraws = sum(cashFlows.financing.futureFundingMonthlyDraws);
  const followOnCapitalProjects = sum(cashFlows.capital.followOnCapitalExpenses) * -1;
  const totalAcquisitionCapital = purchasePrice + acquisitionCost + originationFee;
  const totalUses = totalAcquisitionCapital + followOnCapitalProjects;
  return totalUses - acquisitionLoan - totalFutureFundingDraws;
};

export const calcExitPrice = (dcfParams, cashFlows) => {
  if (!isNil(dcfParams.customExitPrice)) return dcfParams.customExitPrice;

  if (isNil(cashFlows)) {
    // eslint-disable-next-line no-param-reassign
    cashFlows = calcAboveTheLineCashFlows(dcfParams);
  }
  const { exitCapRate, expenseMethod, assessedValueExitPercent } = dcfParams;
  if (assessedValueExitPercent && expenseMethod !== EXPENSE_METHOD_RATIO) {
    const egr = cashFlows.revenue.effectiveGrossRevenues;
    const holdPlusOneEgr = last(annualizeMonthlyReturns(egr, holdPeriodInMonths(dcfParams)));
    const holdPlusOneExpensesWithoutTaxes = last(annualizeMonthlyReturns(sumArrays(
      cashFlows.expenses.controllableExpenses,
      cashFlows.expenses.nonControllableExpensesBeforeTaxes,
    ), holdPeriodInMonths(dcfParams)));
    const holdPlusOneTaxRate = calcHoldPlusOneTaxRate(dcfParams);
    return (holdPlusOneEgr - holdPlusOneExpensesWithoutTaxes) / (exitCapRate + (holdPlusOneTaxRate * assessedValueExitPercent));
  }

  let exitNoi;
  const holdPlusOneNoi = sum(takeRight(cashFlows.netOperatingIncome, 12));
  if (dcfParams.expenseMethod === EXPENSE_METHOD_RATIO) {
    exitNoi = holdPlusOneNoi;
  } else {
    // when calculating the hold period + 1 NOIs, we back out the real estate tax
    // so that we can apply the final real estate tax rate for the entire 12 months
    // to avoid the scenario where the final tax rate is only applied for a few months
    // due to the calendar year alignment which erroneously inflates the exit value
    const holdPlusOneTaxes = takeRight(cashFlows.expenses.taxes, 12);
    const finalMonthlyTax = last(holdPlusOneTaxes);
    exitNoi = holdPlusOneNoi + sum(holdPlusOneTaxes) - (finalMonthlyTax * 12);
  }
  return exitNoi / exitCapRate;
};

export const calcCostOfSale = (exitPrice, dcfParams) => -1 * exitPrice * dcfParams.costOfSalePercent;

export const monthlyCashFlowDates = (dcfParams) => {
  const months = range(1, 13 + holdPeriodInMonths(dcfParams));
  const parsedClosingDate = dateFromString(dcfParams.closingDate);
  const dates = months.map(month => startOfMonth(addMonths(parsedClosingDate, month)));
  dates.unshift(parsedClosingDate);
  return dates;
};

export const calculateLeveredPurchase = (proceeds, dcfParams) => proceeds - calcLoanOriginationFee(proceeds, false, dcfParams) - calcTotalAcquisitionCost(dcfParams);

const calcBasis = (cashFlows, operatingCashFlows, followOnCapitalExpenses) => {
  const draw = [cashFlows[0] * -1].concat([...operatingCashFlows].map((ocf, idx) => (ocf < 0 ? -1 * ocf : 0) - followOnCapitalExpenses[idx + 1]));
  const drawRollUp = [0].concat(draw).map((prevValue => d => prevValue += d)(0));
  const beginningBasis = drawRollUp.splice(0, drawRollUp.length - 1);
  const endingBasis = draw.map((num, idx) => num + beginningBasis[idx]);

  return {
    beginningBasis,
    draw,
    endingBasis,
  };
};

export const calcCashFlows = (dcfParams) => {
  const aboveTheLineCashFlows = calcAboveTheLineCashFlows(dcfParams);
  const cashFlows = aboveTheLineCashFlows;
  const { revenue, netOperatingIncome } = cashFlows;

  const zeroMonthlyArray = createZeroMonthlyArray(dcfParams);
  const purchasePriceArray = [...zeroMonthlyArray].fill(-1 * dcfParams.purchasePrice, 0, 1);
  const acquisitionCostArray = [...zeroMonthlyArray].fill(-1 * calcAcquisitionCosts(dcfParams), 0, 1);

  cashFlows.acquisition = {
    price: purchasePriceArray,
    cost: acquisitionCostArray,
  };

  const exitPrice = calcExitPrice(dcfParams, aboveTheLineCashFlows);
  const salePriceArray = [...zeroMonthlyArray].fill(exitPrice, zeroMonthlyArray.length - 1, zeroMonthlyArray.length);
  const saleCostArray = [...zeroMonthlyArray].fill(calcCostOfSale(exitPrice, dcfParams), zeroMonthlyArray.length - 1, zeroMonthlyArray.length);

  cashFlows.sale = {
    price: salePriceArray,
    cost: saleCostArray,
  };

  const annualizedNOIs = annualizeMonthlyReturns(netOperatingIncome, holdPeriodInMonths(dcfParams));
  const loanSchedule = calcMonthlyLoanSchedule(annualizedNOIs, dcfParams);
  const interestPayments = loanSchedule.map(payments => -1 * payments[0]);
  const principalPayments = loanSchedule.map(payments => -1 * payments[1]);
  const futureFundingMonthlyDraws = calcFutureFundingMonthlyDraws(dcfParams);
  const acquisitionLoanPaymentRepayments = calcMonthlyPaymentRepayment(annualizedNOIs, dcfParams, false);
  const refinancingLoanPaymentRepayments = calcMonthlyPaymentRepayment(annualizedNOIs, dcfParams, true);
  const loanOriginationFees = calcMonthlyOriginationFees(annualizedNOIs, dcfParams).map(cf => cf * -1);

  cashFlows.financing = {
    acquisitionLoanPaymentRepayments,
    refinancingLoanPaymentRepayments,
    loanOriginationFees,
    interestPayments,
    principalPayments,
    futureFundingMonthlyDraws,
  };

  const capitalExpenses = calcMonthlyCapitalProjects(dcfParams);
  const cashFlowCapitalExpenses = capitalExpenses[ACCOUNTING_METHOD_CASH_FLOW].map(cf => cf * -1);
  const followOnCapitalExpenses = capitalExpenses[ACCOUNTING_METHOD_FOLLOW_ON].map(cf => cf * -1);

  cashFlows.capital = {
    cashFlowCapitalExpenses,
    followOnCapitalExpenses,
  };

  const totalEquity = calculateTotalEquity(cashFlows);
  cashFlows.totalEquity = totalEquity;
  const belowTheLineExpenses = calcBelowTheLineExpenses(dcfParams, revenue.effectiveGrossRevenues, revenue.grossRents, totalEquity).map(cf => cf * -1);
  const totalCapital = sumArrays(belowTheLineExpenses, cashFlowCapitalExpenses, followOnCapitalExpenses);

  cashFlows.capital.belowTheLineExpenses = belowTheLineExpenses;
  cashFlows.capital.total = totalCapital;

  cashFlows.unleveredOperatingCashFlows = take(sumArrays(netOperatingIncome, belowTheLineExpenses.slice(1), cashFlowCapitalExpenses.slice(1)), holdPeriodInMonths(dcfParams));

  cashFlows.unleveredCashFlows = sumArrays(
    [0].concat(cashFlows.unleveredOperatingCashFlows),
    followOnCapitalExpenses,
    purchasePriceArray,
    acquisitionCostArray,
    salePriceArray,
    saleCostArray,
  );

  cashFlows.leveredOperatingCashFlows = sumArrays(
    cashFlows.unleveredOperatingCashFlows,
    interestPayments,
    principalPayments,
  );

  cashFlows.leveredCashFlows = sumArrays(
    [0].concat(cashFlows.leveredOperatingCashFlows),
    followOnCapitalExpenses,
    [0].concat(futureFundingMonthlyDraws),
    acquisitionLoanPaymentRepayments,
    refinancingLoanPaymentRepayments,
    loanOriginationFees,
    purchasePriceArray,
    acquisitionCostArray,
    salePriceArray,
    saleCostArray,
  );

  cashFlows.unleveredBasis = calcBasis(
    cashFlows.unleveredCashFlows,
    cashFlows.unleveredOperatingCashFlows,
    cashFlows.capital.followOnCapitalExpenses,
  );

  cashFlows.leveredBasis = calcBasis(
    cashFlows.leveredCashFlows,
    cashFlows.leveredOperatingCashFlows,
    cashFlows.capital.followOnCapitalExpenses,
  );

  return cashFlows;
};

const calcStabilizationMonth = (dcfParams) => {
  const unitRenovationStabMonth = dcfParams.units.map(unit => unitStabilizedMonth(unit));
  const followOnCapExStabMonth = unionBy(enabledCapitalItems(dcfParams), enabledCapitalFees(dcfParams)).map(item => capExStabilizedMonth(dcfParams.closingDate, item));
  return Math.max(...(unitRenovationStabMonth.concat(followOnCapExStabMonth).concat([1])));
};

export const calculateStabilizedNetYieldError = (targetYield, dcfParams, purchasePrice) => {
  const updatedDcfParams = { ...dcfParams, purchasePrice };
  const { unleveredBasis, netOperatingIncome, revenue: { grossPotentialRents } } = calcCashFlows(updatedDcfParams);
  const stabilizationMonth = calcStabilizationMonth(updatedDcfParams);
  const totalMarketRent = sum(updatedDcfParams.units.map((unit) => unit.marketRent * 12));

  const yieldArgs = {
    netOperatingIncome,
    unleveredBasis,
    stabilizationMonth,
    totalMarketRent,
    grossPotentialRents,
  };
  return calcStabilizedYields(yieldArgs).stabilizedYield - targetYield;
};

const YIELD_TOLERANCE = 0.0001;
const YIELD_H = 1e-4;
const PURCHASE_PRICE_GUESS = 1;
export const solveToYield = (targetYield, dcfParams) => newtonRaphson(
  partial(calculateStabilizedNetYieldError, targetYield, dcfParams),
  PURCHASE_PRICE_GUESS,
  YIELD_TOLERANCE,
  YIELD_H,
);

export const calcReturnMetrics = (cashflows, dcfParams, { skipIrr = false } = {}) => {
  const {
    unleveredCashFlows,
    leveredCashFlows,
    unleveredBasis,
    leveredBasis,
    capital: { cashFlowCapitalExpenses, followOnCapitalExpenses },
    expenses: { taxes },
  } = cashflows;

  const stabilizationMonth = calcStabilizationMonth(dcfParams);
  const totalMarketRent = sum(dcfParams.units.map((unit) => unit.marketRent * 12));
  const totalProfits = sum(leveredCashFlows);

  const { currentStabilizedYield, grossStabilizedYield, stabilizedYield } = calcStabilizedYields({
    netOperatingIncome: cashflows.netOperatingIncome,
    unleveredBasis,
    stabilizationMonth,
    totalMarketRent,
    grossPotentialRents: cashflows.revenue.grossPotentialRents,
  });

  const unleveredEquityMultiple = equityMultiple(unleveredCashFlows);
  const leveredEquityMultiple = equityMultiple(leveredCashFlows);

  const unleveredCashOnCashRate = zip([0].concat(cashflows.unleveredOperatingCashFlows), unleveredBasis.beginningBasis).map(arr => arr[0] / arr[1]).slice(1);
  const annualizedUnleveredCashOnCash = annualizeMonthlyReturns(unleveredCashOnCashRate, holdPeriodInMonths(dcfParams));
  const unleveredAverageCashOnCash = sum(annualizedUnleveredCashOnCash) / annualizedUnleveredCashOnCash.length;

  const leveredCashOnCashRate = zip([0].concat(cashflows.leveredOperatingCashFlows), leveredBasis.beginningBasis).map(cf => cf[0] / cf[1]).slice(1);
  const annualizedLeveredCashOnCash = annualizeMonthlyReturns(leveredCashOnCashRate, holdPeriodInMonths(dcfParams));
  const leveredAverageCashOnCash = sum(annualizedLeveredCashOnCash) / annualizedLeveredCashOnCash.length;

  const cashFlowDates = monthlyCashFlowDates(dcfParams);

  // CAGR //
  const fv = last(cashflows.sale.price);
  const totalFollowOnCapitalExpenses = sum(followOnCapitalExpenses);
  const pv = totalFollowOnCapitalExpenses + dcfParams.purchasePrice;
  const appreciationCagr = ((fv / pv) ** (1 / dcfParams.holdPeriod)) - 1;
  const totalCapital = Math.abs(totalFollowOnCapitalExpenses + sum(cashFlowCapitalExpenses));

  let unleveredIrr, leveredIrr;
  try {
    if (!skipIrr) {
      unleveredIrr = irr(unleveredCashFlows, cashFlowDates);
    }
  } catch (error) {
    unleveredIrr = null;
  }

  try {
    if (!skipIrr) {
      leveredIrr = irr(leveredCashFlows, cashFlowDates);
    }
  } catch (error) {
    leveredIrr = null;
    console.error(error);
  }
  return {
    unleveredEquityMultiple,
    unleveredCashOnCashRate,
    annualizedUnleveredCashOnCash,
    unleveredAverageCashOnCash,
    unleveredIrr,
    leveredEquityMultiple,
    leveredCashOnCashRate,
    annualizedLeveredCashOnCash,
    leveredAverageCashOnCash,
    leveredIrr,
    stabilizationMonth,
    stabilizedYield,
    grossStabilizedYield,
    currentStabilizedYield,
    totalProfits,
    appreciationCagr,
    unleveredBasis: unleveredBasis.endingBasis,
    taxes,
    totalCapital,
  };
};

// fields in cash flows that should be averaged instead of summed
const MEAN_KEYS = ['occupancy.economicOccupancyRates', 'occupancy.physicalOccupancyRates'];
// fields in cash flows that contain scalar values
const SCALAR_KEYS = ['totalEquity'];

export const calcCashFlowsOfIndividualScenarios = (scenarios) => {
  const scenarioCashFlows = scenarios.map(scenario => calcCashFlows(scenario.parameters));
  const totalMultiplicity = scenarios.reduce((total, { parameters: { multiplicity } }) => total + (multiplicity ?? 1), 0);

  const cashFlowKeys = deepFlattenKeys(scenarioCashFlows[0]);
  const zipped = cashFlowKeys.reduce((result, key) => {
    set(
      result,
      key,
      zipWith(
        ...scenarioCashFlows.map((cfs, idx) => {
          const cashFlow = get(cfs, key);
          const normalized = Array.isArray(cashFlow) ? cashFlow : [cashFlow];
          const scenarioMultiplicity = scenarios[idx].parameters.multiplicity ?? 1;
          return scenarioMultiplicity === 1 ? normalized : normalized.map((val) => val * scenarioMultiplicity);
        }),
        (...vals) => (
          MEAN_KEYS.includes(key) ? (sum(vals) / totalMultiplicity) : sum(vals)
        ),
      ),
    );
    return result;
  }, {});

  SCALAR_KEYS.forEach((k) => set(zipped, k, get(zipped, k)[0]));
  return zipped;
};

export const calcReturnMetricsOfIndividualScenarios = (scenarios, cashFlows = null, { skipIrr = false } = {}) => {
  const stabilizationMonth = Math.max(...scenarios.map(scenario => calcStabilizationMonth(scenario.parameters)));
  const holdPeriodMonth = Math.max(...scenarios.map(scenario => holdPeriodInMonths(scenario.parameters)));
  const cashFlowDates = sortBy(scenarios.map(scenario => monthlyCashFlowDates(scenario.parameters)), dates => dates.length)[0];
  const totalMarketRent = 12 * sum(scenarios.map(({ parameters: { units, multiplicity } }) => sum(units.map(({ marketRent }) => marketRent)) * (multiplicity ?? 1)));

  const {
    unleveredCashFlows,
    leveredCashFlows,
    capital: { followOnCapitalExpenses },
    unleveredOperatingCashFlows,
    leveredOperatingCashFlows,
    netOperatingIncome,
    revenue: { grossPotentialRents },
  } = cashFlows || calcCashFlowsOfIndividualScenarios(scenarios);

  const unleveredBasis = calcBasis(
    unleveredCashFlows,
    unleveredOperatingCashFlows,
    followOnCapitalExpenses,
  );

  const leveredBasis = calcBasis(
    leveredCashFlows,
    leveredOperatingCashFlows,
    followOnCapitalExpenses,
  );

  const { currentStabilizedYield, grossStabilizedYield, stabilizedYield } = calcStabilizedYields({
    netOperatingIncome,
    unleveredBasis,
    stabilizationMonth,
    totalMarketRent,
    grossPotentialRents,
  });

  const unleveredCashOnCashRate = zip([0].concat(unleveredOperatingCashFlows), unleveredBasis.beginningBasis).map(arr => arr[0] / arr[1]).slice(1);
  const annualizedUnleveredCashOnCash = annualizeMonthlyReturns(unleveredCashOnCashRate, holdPeriodMonth);
  const unleveredAverageCashOnCash = sum(annualizedUnleveredCashOnCash) / annualizedUnleveredCashOnCash.length;

  const leveredCashOnCashRate = zip([0].concat(leveredOperatingCashFlows), leveredBasis.beginningBasis).map(cf => cf[0] / cf[1]).slice(1);
  const annualizedLeveredCashOnCash = annualizeMonthlyReturns(leveredCashOnCashRate, holdPeriodMonth);
  const leveredAverageCashOnCash = sum(annualizedLeveredCashOnCash) / annualizedLeveredCashOnCash.length;

  let unleveredIrr, leveredIrr;
  try {
    if (!skipIrr) {
      unleveredIrr = irr(unleveredCashFlows, cashFlowDates);
    }
  } catch (error) {
    unleveredIrr = null;
  }

  try {
    if (!skipIrr) {
      leveredIrr = irr(leveredCashFlows, cashFlowDates);
    }
  } catch (error) {
    leveredIrr = null;
    console.error(error);
  }

  return {
    annualizedLeveredCashOnCash,
    annualizedUnleveredCashOnCash,
    currentStabilizedYield,
    grossStabilizedYield,
    leveredAverageCashOnCash,
    leveredEquityMultiple: equityMultiple(leveredCashFlows),
    leveredIrr,
    stabilizationMonth,
    stabilizedYield,
    totalProfits: sum(leveredCashFlows),
    unleveredAverageCashOnCash,
    unleveredEquityMultiple: equityMultiple(unleveredCashFlows),
    unleveredIrr,
  };
};

export const displayUnits = dcfParams => {
  const { units, unitTypeEntry } = dcfParams;
  const unitsByType = groupBy(units, unit => [unit.address, unit.groupId, unit.group, unit.bedrooms, unit.bathrooms]);
  const unitTypeAggregates = map(
    unitsByType,
    unitsOfType => {
      const address = uniq(unitsOfType.map(unit => unit.address))[0];
      return Object.assign(cloneDeep(first(unitsOfType)), { number: unitsOfType.length, address });
    },
  );
  return unitTypeEntry ? unitTypeAggregates : units;
};

export const calcSourcesAndUses = (cashFlows) => {
  const acquisitionLoan = cashFlows.financing.acquisitionLoanPaymentRepayments[0];
  const purchasePrice = cashFlows.acquisition.price[0] * -1;
  const acquisitionCost = cashFlows.acquisition.cost[0] * -1;
  const followOnCapitalProjects = sum(cashFlows.capital.followOnCapitalExpenses) * -1;
  const originationFee = cashFlows.financing.loanOriginationFees[0] * -1;
  const totalFutureFundingDraws = sum(cashFlows.financing.futureFundingMonthlyDraws);
  const totalFinancing = acquisitionLoan + totalFutureFundingDraws;
  const totalAcquisitionCapital = purchasePrice + acquisitionCost + originationFee;
  const totalUses = totalAcquisitionCapital + followOnCapitalProjects;
  const totalEquity = totalUses - totalFinancing;
  const totalSources = totalEquity + totalFinancing;

  return {
    totalEquity,
    totalFinancing,
    totalSources,
    purchasePrice,
    acquisitionCost,
    originationFee,
    totalAcquisitionCapital,
    followOnCapitalProjects,
    totalUses,
  };
};
