import { useColumnFilter } from 'components/shared/Table/DataTableContext';
import Filter from './Filter';

/**
 * See {@link Filter}
 *
 * @param {import('react').ReactNode} label
 * @param displayValue
 * @param staticDisplayValue
 * @param {string} columnId
 * @param {string} [filterId]
 * @param {import('react').ReactNode} children
 */
export default function ReactTableColumnFilter({ label, displayValue, staticDisplayValue, columnId, filterId, children }) {
  const {
    isFiltered,
    filterValue,
    setFilterValue,
    resetFilterValue,
    clearFilterValue,
  } = useColumnFilter(columnId) ?? {};

  return (
    <Filter
      filterId={filterId ?? columnId}
      label={label}
      selected={isFiltered}
      filterState={filterValue}
      displayValue={displayValue}
      staticDisplayValue={staticDisplayValue}
      onApply={setFilterValue}
      onReset={resetFilterValue}
      onClear={clearFilterValue}
    >
      {children}
    </Filter>
  );
}
