import { useState } from 'react';
import { parse } from 'date-fns';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { isEmpty, isNil } from 'lodash';
import { useFetchPropertyLocalQuery } from 'redux/apiSlice';
import { useFetchSubdivisionQuery } from 'redux/subdivisionApiSlice';
import { LoadingIndicator } from 'components/icons';
import { formatCurrency, formatDate, formatPercentage, titleCase } from 'components/utils';
import { PropertyLayoutContainer } from 'components/property/PropertyLayout';
import { RecordGridCell, RecordGridColumn } from 'components/property/components/RecordGrid';
import Map from 'components/property/components/Map';
import Badge from 'components/shared/Badge';
import DemographicsComparisonTable from './DemographicsComparisonTable';
import PropertyMapModal from '../components/MapModal';

const MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: false,
  cameraControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
};
const PRIMARY_COLOR = '#002D6F';
const SECONDARY_COLOR = '#34d399';
const GRID_COLOR = '#f5f5f5';

function School({ school }) {
  const {
    collegeBoundPercent,
    districtName,
    gradeLevelRangeHigh,
    gradeLevelRangeLow,
    institutionName,
    institutionType,
    studentTeacherRatio,
    testRating,
  } = school;

  return (
    <div className="w-full mb-4 px-6 py-3 bg-white border rounded-lg">
      <div className="text-lg">{institutionName}</div>
      <div className="text-gray-500 text-xs mb-6">{districtName}</div>
      <RecordGridColumn>
        <RecordGridCell label="Type" value={institutionType} />
        <RecordGridCell label="Grades" value={`${gradeLevelRangeLow} - ${gradeLevelRangeHigh}`} />
        <RecordGridCell label="Student Teacher Ratio" value={studentTeacherRatio} />
        <RecordGridCell label="Test Rating" value={testRating} />
        {collegeBoundPercent && <RecordGridCell label="College Bound %" value={formatPercentage(collegeBoundPercent / 100, 0)} />}
      </RecordGridColumn>
    </div>
  );
}

function RentChart({ rentIndexItem }) {
  const { data, regionName, regionType, yearOverYear } = rentIndexItem;
  const yearOverYearObj = yearOverYear.reduce((ret, pair) => {
    if (isNil(pair.value)) {
      return ret;
    }
    return {
      ...ret,
      [pair.date]: pair.value,
    };
  }, {});

  const regionTypeLabel = regionType === 'msa' ? 'MSA' : titleCase(regionType);

  const formattedData = Object.entries(data).filter(pair => pair[1] > 0).map(pair => {
    const date = parse(pair[0].toString(), 'yyyyMMdd', new Date());
    return {
      date,
      yearOverYear: yearOverYearObj[formatDate(date, 'yyyy-MM-dd')],
      dollars: pair[1],
    };
  });

  return (
    <div className="w-full">
      <div className="w-full text-center text-sm my-3">{`${regionTypeLabel}: ${regionName}`}</div>
      <ResponsiveContainer width="90%" height={240}>
        <ComposedChart data={formattedData} margin={{ right: 20, left: 20 }}>
          <XAxis
            dataKey="date"
            minTickGap={90}
            tickLine={false}
            tickFormatter={value => formatDate(value, 'MMM yyyy')}
          />
          <YAxis
            axisLine={false}
            dataKey="dollars"
            tickLine={{ stroke: GRID_COLOR }}
            tickFormatter={value => formatCurrency(value)}
            yAxisId="left"
          />
          <YAxis
            axisLine={false}
            dataKey="yearOverYear"
            interval="preserveEnd"
            orientation="right"
            tickLine={{ stroke: GRID_COLOR }}
            tickFormatter={value => formatPercentage(value)}
            yAxisId="right"
          />
          <CartesianGrid stroke={GRID_COLOR} />
          <Line
            dataKey="yearOverYear"
            dot={false}
            stroke={PRIMARY_COLOR}
            strokeWidth={1}
            type="monotone"
            yAxisId="right"
          />
          <Line
            dataKey="dollars"
            dot={false}
            stroke={SECONDARY_COLOR}
            strokeWidth={2}
            type="monotone"
            yAxisId="left"
          />
          <Legend formatter={(value) => (value === 'yearOverYear' ? 'YOY % Chg' : '$')} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

function NoDemographics() {
  return (
    <>
      <div className="text-xl mb-2">Demographics</div>
      <div className="text-gray-500">No demographic data available</div>
    </>
  );
}

function PropertyMap({ demographicGeoms, property }) {
  const {
    block,
    censusTract,
    zip,
  } = demographicGeoms;

  return (
    <Map
      blockGeom={block}
      censusTractGeom={censusTract}
      options={MAP_OPTIONS}
      properties={[property]}
      zipCodeGeom={zip}
    />
  );
}

function Demographics({ demographics, demographicGeoms, property }) {
  const [showMapModal, setShowMapModal] = useState(false);
  if (!demographics) return <NoDemographics />;

  return (
    <div className="grid grid-cols-2 gap-4">
      <DemographicsComparisonTable demographics={demographics} property={property} />
      <div className="relative">
        <PropertyMap demographicGeoms={demographicGeoms} property={property} />
        <PropertyMapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={property}>
          <PropertyMap demographicGeoms={demographicGeoms} property={property} />
        </PropertyMapModal>
        <Badge
          className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg"
          label="Click to expand"
          onClick={() => setShowMapModal(true)}
          visible
        />
      </div>
    </div>
  );
}

export default function Neighborhood({ parcel, property, homeModel }) {
  const { data: local = {}, error, isLoading } = useFetchPropertyLocalQuery({
    fipsApn: parcel?.fipsApn,
    propertyId: property?.id,
    latitude: property.latitude,
    longitude: property.longitude,
  });
  const { data: subdivision, isLoading: isLoadingSubdivision } = useFetchSubdivisionQuery(homeModel?.subdivisionId, { skip: !homeModel });

  if (isLoading || isLoadingSubdivision) {
    return (
      <div className="flex justify-start items-center">
        <LoadingIndicator className="w-8 text-blue-400" />
      </div>
    );
  }

  if (error || isEmpty(local)) {
    return (
      <div className="w-full mx-auto rounded-xl border bg-white mb-6">
        <p className="p-6 text-gray-400">Unable to find information</p>
      </div>
    );
  }

  const { demographics: localDemographics, demographicGeoms: localDemographicGeoms, rentIndex, schools } = local;
  const demographics = subdivision?.demographics || localDemographics;
  const demographicGeoms = subdivision ? {
    block: subdivision.demographics.block?.geom,
    censusTract: subdivision.demographics.censusTract?.geom,
    zip: subdivision.demographics.zip?.geom,
    msa: subdivision.demographics.msa?.geom,
  } : localDemographicGeoms;

  return (
    <>
      <Demographics demographics={demographics} property={property} demographicGeoms={demographicGeoms} />
      {rentIndex && (
        <>
          <div className="text-xl mt-8 mb-2">Rent Trends</div>
          <div className="text-gray-500 text-xs mb-6">
            Source:
            <a className="ml-1" href="https://www.zillow.com/research/data/" rel="noreferrer" target="_blank">Zillow</a>
          </div>
          <div className={`grid grid-cols-${rentIndex.length === 3 ? 3 : 2} gap-x-6 gap-y-12`}>
            {rentIndex.map(item => <RentChart key={item.regionType} rentIndexItem={item} />)}
          </div>
        </>
      )}
      {!homeModel && (
        <>
          <div className="text-xl mt-8 mb-6">Schools</div>
          <div className="grid grid-cols-3 gap-x-6">
            {isEmpty(schools) ? (
              <div className="text-gray-500">No school data available</div>
            ) : schools.map(school => (
              <School key={school.institutionId} school={school} />
            ))}
          </div>
        </>
      )}
    </>
  );
}

export function EmbeddedNeighborhood({ context }) {
  const { data } = context;
  const { parcel, property, homeModel } = data;

  return (
    <PropertyLayoutContainer>
      <Neighborhood parcel={parcel} property={property} homeModel={homeModel} />
    </PropertyLayoutContainer>
  );
}
