import { useMemo } from 'react';
import { naturalSortComparator } from 'components/utils';
import { useCoreRowModel } from 'components/shared/Table/DataTableContext';
import ComboboxFilter from './ComboboxFilter';
import { useFilterId } from './filterHooks';

export default function ReactTableColumnUniqueValuesComboboxFilter({ labelBy }) {
  const filterId = useFilterId();
  const { flatRows } = useCoreRowModel();

  const colValuesSorted = useMemo(() => {
    const resolvedLabelBy = labelBy ?? filterId;
    const colValuesUnique = new Map(flatRows.map((row) => (
      [row.getValue(filterId), row.getValue(resolvedLabelBy)]
    )));
    colValuesUnique.delete(null);
    colValuesUnique.delete(undefined);
    colValuesUnique.delete('');

    const comparator = naturalSortComparator();
    return Array.from(colValuesUnique.entries())
      .toSorted(([, labelLeft], [, labelRight]) => comparator(labelLeft, labelRight))
      .map(([key, label]) => ({ [filterId]: key, [resolvedLabelBy]: label }));
  }, [filterId, labelBy, flatRows]);

  return (
    <ComboboxFilter keyOnly options={colValuesSorted} keyBy={filterId} labelBy={labelBy ?? filterId} />
  );
}
