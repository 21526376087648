/* eslint-disable no-nested-ternary */
import { Map, Search } from 'components/icons';
import { merge, size, sumBy } from 'lodash';
import { formatCurrencyAbbreviated, formatInteger, formatPercentage } from 'components/utils';

function AssetStat({ label, value }) {
  return (
    <div className="text-center px-3 py-1.5 flex flex-col items-center justify-evenly bg-white rounded shadow">
      <div className="text-lg">{value}</div>
      <div className="text-xs font-light text-center">{label}</div>
    </div>
  );
}

export default function ListSummary({ filters, minimizeMap, propertyManagementRecords, setFilters, setMinimizeMap }) {
  const totalAvm = sumBy(propertyManagementRecords, pmr => (pmr.avm || 0));

  const stats = [{
    label: 'Properties',
    value: formatInteger(size(propertyManagementRecords)),
  }, {
    label: 'AVM',
    value: formatCurrencyAbbreviated(totalAvm),
  }];

  return (
    <div className="h-16 px-8 w-full flex items-center justify-between border-b shadow bg-gray-100">
      <div className="justify-center flex gap-x-4">
        {stats.map(stat => <AssetStat key={stat.label} label={stat.label} value={stat.value} />)}
      </div>
      <div className="flex items-center gap-x-3">
        <div className="relative flex w-96">
          <span className="z-20 font-normal absolute text-center text-slate-300 rounded text-base items-center justify-center w-8 pl-3 py-3.5">
            <Search className="text-black w-6 h-6" />
          </span>
          <input
            value={filters.name || ''}
            onChange={(e) => setFilters(merge({}, filters, { name: e.target.value }))}
            type="text"
            placeholder="Search for a property"
            className="px-3 py-3.5 relative rounded text-sm outline-none focus:outline-none border border-slate-300 shadow-sm w-full pl-10"
          />
        </div>
        <Map
          className="border rounded p-2 size-12 bg-white hover:bg-gray-100 cursor-pointer"
          filled={!minimizeMap}
          onClick={() => setMinimizeMap(!minimizeMap)}
        />
      </div>
    </div>
  );
}
