import { forwardRef, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { Popover } from '@headlessui/react';
import { useBlocker } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import { compact, groupBy, isEmpty, isNil, isNumber, partial, set, sum, sumBy, uniq } from 'lodash';
import { clearToast, showToast } from 'redux/toastSlice';
import { showSaveChangesModal } from 'actions/deal_navigation';
import { useUpdateScenariosMutation } from 'redux/dealApiSlice';
import Button from 'components/shared/NewButton';
import {
  coalesceProperty,
  dotProductBy,
  formatCurrency,
  formatDate,
  formatEquityMultiple,
  formatInteger,
  formatPercentage,
  parseEventValue,
} from 'components/utils';
import { HOA_FEE_EXPENSE_ITEM_NAME, LAYOUT } from 'components/constants';
import { TOAST_UNSAVED_CHANGES } from 'components/toast';
import { calcCashFlows, calcReturnMetrics, calcReturnMetricsOfIndividualScenarios, solveToYield } from 'components/dcf/dcf';
import DataTable from 'components/shared/Table/DataTable';
import { AddressCell, BuyBoxCell, CompRentCell, InputCell } from 'components/shared/Table/Cells';
import useElementHeight from 'hooks/useElementHeight';
import { Arrow, Check, LoadingIndicator, Pencil, X } from 'components/icons';
import { ITEMIZED_INPUT_METHOD_DPM } from 'components/dcf/itemizedItem';
import { dataTableMeta, enableConfigPresets, enableEditing, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { InlineFormField, InlineInfoField } from 'components/Form';
import Modal from 'components/Modal';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import DataTableConfigPanelBody from 'components/shared/Table/dataTableConfig/DataTableConfigPanelBody';
import { EMPTY_PRESET, getDefaultPresetId } from 'components/shared/Table/dataTableConfig/utils';
import {
  DataTableContent,
  useClearRowUpdates,
  useFilteredRows,
  useGetUpdatedRowData,
  useIsTableEdited,
  useRowUpdates,
  useRowUpdatesForRow,
  useUpdateRows,
} from 'components/shared/Table/DataTableContext';
import ChipGroup from 'components/shared/ChipGroup';
import BooleanFilterChip from 'components/shared/newfilters/BooleanFilterChip';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ComboboxFilter from 'components/shared/newfilters/ComboboxFilter';
import RangeFilter from 'components/shared/newfilters/RangeFilter';
import FilterChip from 'components/shared/newfilters/FilterChip';
import TrueFalseToggle from 'components/shared/newfilters/TrueFalseToggle';
import IndeterminateCheckbox from 'components/shared/IndeterminateCheckbox';
import useRangeDisplayValue from 'components/shared/newfilters/useRangeDisplayValue';
import { rangeFilter } from 'components/shared/Table/table.filterFns';
import { useSelectedFilters } from 'components/shared/newfilters/filterHooks';
import { getUpdatedRowData } from 'components/shared/Table/table.features';
import { useFetchHomeModelQuery } from '../../redux/homeModelApiSlice';

const columnHelper = createColumnHelper();
const TABLE_INITIAL_STATE = { columnVisibility: { isBuyBoxMatch: false } };
const meetsBuyBoxFilterDisplayFn = (value) => {
  const icon = value ? <Check /> : <X />;
  return (
    <div className="flex items-center">
      <div className="mr-2 w-4">{icon}</div>
      <div>Matches Buy Box</div>
    </div>
  );
};

const getTableId = ({ newBuildDeal = false }) => {
  if (newBuildDeal) {
    return 'PortfolioDealSummaryNewBuild';
  }

  return 'PortfolioDealSummaryIndividual';
};

const ALL_TABLE_IDS = Object.freeze([
  getTableId({ individuallyModelled: true }),
  getTableId({ newBuildDeal: true }),
]);

const DEFAULT_PRESET = Object.freeze({
  ...EMPTY_PRESET,
  visibility: Object.freeze({
    capital: false,
    stabilizationMonth: false,
    taxes: false,
    hoa: false,
    rollToMarket: false,
  }),
});
const CONFIG_PRESETS = Object.freeze(Object.fromEntries(ALL_TABLE_IDS.map((tableId) => (
  [
    tableId,
    Object.freeze({
      [getDefaultPresetId({ tableId })]: Object.freeze({
        ...DEFAULT_PRESET,
        id: getDefaultPresetId({ tableId }),
      }),
    }),
  ]
))));

const getScenario = (property, scenarios) => scenarios.find(s => s.primary && (property.id === s.propertyId));
const getUnits = (_, scenario) => scenario.parameters.units;
const getParcel = (property, parcels) => parcels.find(parcel => parcel.apn === property.apnMin);
const getReturnMetric = (scenario, metric) => scenario?.returnMetrics?.[metric];
const parameterMutatorFn = selector => (row, value) => {
  set(row, selector, value);
  const updatedReturnMetrics = calcReturnMetrics(calcCashFlows(row.parameters), row.parameters);
  set(row, ['returnMetrics'], updatedReturnMetrics);
};

function FilterMessage({ tableData }) {
  const selectedFilters = useSelectedFilters();
  const filteredRows = useFilteredRows();
  if (!selectedFilters.length) {
    return null;
  }

  return (
    <div className="text-sm text-gray-500">{`Filters Match ${filteredRows.length} of ${tableData.length} Properties`}</div>
  );
}

const useAllRowUpdates = () => {
  const tableEditedSelector = useCallback((updates, { table }) => (
    updates.size > 0 ? (
      table
        .getRowModel()
        .rows
        .filter((row) => updates.has(row.id))
    ) : []
  ), []);
  return useRowUpdates(tableEditedSelector);
};

function ActiveHeader() {
  const filteredRows = useFilteredRows();
  const updatedRows = useAllRowUpdates();

  const [checked, indeterminate] = useMemo(() => {
    const updatedRowsById = groupBy(updatedRows, 'id');
    const effectiveScenarios = filteredRows.map(row => (updatedRowsById[row.id] ? getUpdatedRowData(updatedRowsById[row.id][0])[0] : row.original));
    const activeScenariosCount = effectiveScenarios.filter(r => r.active).length;
    const isChecked = activeScenariosCount > 0;
    const isIndeterminate = isChecked && (activeScenariosCount < effectiveScenarios.length);
    return [isChecked, isIndeterminate];
  }, [filteredRows, updatedRows]);

  const filteredRowIds = filteredRows.map(r => r.id);
  const [, setUpdate] = useUpdateRows({ rowIds: filteredRowIds, columnId: 'active' });
  const [, startTransition] = useTransition();

  const onChange = useCallback(() => {
    const isEnabling = !checked || indeterminate;
    startTransition(() => setUpdate(isEnabling));
  }, [checked, indeterminate, setUpdate]);

  return (
    <IndeterminateCheckbox
      className="cursor-pointer size-4 accent-primary-dark focus:outline-none"
      checked={checked}
      indeterminate={indeterminate}
      onChange={onChange}
    />
  );
}

function PercentDiffCell({ getValue, showListLabel = false, className = null, arrowClassName = null }) {
  const value = getValue();
  return (
    <div className={cx(className, 'flex items-center')}>
      {(value !== 0) && <Arrow className={cx(arrowClassName, 'w-4')} fill={null} direction={(value < 0) ? 'down' : 'up'} />}
      {`${formatPercentage(Math.abs(value))}${showListLabel ? ' List' : ''}`}
    </div>
  );
}

function ReturnMetricCell({ column, row, getValue }) {
  const { accessorFn } = column;
  const { columnDef: { meta: { editImpliedChanges, formatter } = {} } } = column;
  const rowUpdates = useRowUpdatesForRow(row.id);
  const changeImplied = (editImpliedChanges ?? []).some((colId) => rowUpdates?.has(colId));
  let effectiveValue = getValue();
  if (rowUpdates) {
    const updates = getUpdatedRowData(row);
    effectiveValue = accessorFn(updates[0]);
  }

  return (
    <div className={changeImplied ? 'py-1 px-2 bg-blue-50' : null}>
      {formatter(effectiveValue)}
    </div>
  );
}

function TableConfigButton() {
  return (
    <Popover className="relative">
      <Popover.Button as="div">
        <Button label="Toggle Columns" outlined small />
      </Popover.Button>
      <Popover.Panel className="absolute right-0 z-30 w-max max-h-96 overflow-auto bg-white rounded shadow">
        <DataTableConfigPanelBody className="p-2" />
      </Popover.Panel>
    </Popover>
  );
}

function HomeModelQuantityCell({
  getValue,
  row: {
    original: {
      property: { homeModelId },
      scenario: {
        id: scenarioId,
        parameters: { homeModelDeliverySchedule },
      },
    },
  },
  table: { options: { meta: { onScenarioChange } } },
}) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable, futureDeliveries } = currentData ?? {};
  // combine dates specified in the scenario and dates in home model
  // there could be dates in the scenario that are not in the home model
  const deliveryDates = useMemo(() => (
    Array
      .from(new Set(
        [...(futureDeliveries ?? []), ...(homeModelDeliverySchedule ?? [])].map(([date]) => date),
      ))
      .toSorted()
  ), [homeModelDeliverySchedule, futureDeliveries]);

  const futureDeliveriesMap = useMemo(() => (
    Object.fromEntries(futureDeliveries ?? [])
  ), [futureDeliveries]);
  const deliveryScheduleMap = useMemo(() => (
    Object.fromEntries(homeModelDeliverySchedule ?? [])
  ), [homeModelDeliverySchedule]);

  return (
    <Popover className="relative w-20">
      <Popover.Button className="rounded border cursor-text py-1 px-2 w-full bg-white text-right">
        {getValue()}
      </Popover.Button>

      <Popover.Panel className="absolute left-0 w-80 mt-1 p-2 z-30 flex flex-col gap-y-3 rounded border bg-white shadow">
        {!currentData ? (
          <LoadingIndicator className="size-6 mx-auto text-tertiary" />
        ) : (
          <>
            <InlineInfoField label="Availability">
              <div className="flex flex-col text-right tabular-nums text-sm">
                <div>{`Available Now: ${numAvailable}`}</div>
                {deliveryDates.map((date) => (
                  <div key={date}>{`${formatDate(date, 'MMM yyyy')}: ${futureDeliveriesMap[date] ?? 0}`}</div>
                ))}
              </div>
            </InlineInfoField>
            <InlineFormField
              type="number"
              name="quantity"
              value={getValue()}
              onChange={(evt) => onScenarioChange(scenarioId, 'multiplicity', parseEventValue(evt))}
            />
            {deliveryDates.map((date) => (
              <InlineFormField
                key={date}
                type="number"
                name={`quantity${date}`}
                label={`Quantity (${formatDate(date, 'MMM yyyy')})`}
                value={deliveryScheduleMap?.[date] ?? 0}
                onChange={(evt) => (
                  onScenarioChange(
                    scenarioId,
                    'homeModelDeliverySchedule',
                    Object.entries({ ...deliveryScheduleMap, [date]: parseEventValue(evt) }),
                  )
                )}
              />
            ))}
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
}

function HomeModelAvailableNow({ row: { original: { property: { homeModelId } } } }) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable } = currentData ?? {};
  return numAvailable;
}

function HomeModelAvailableTotal({ row: { original: { property: { homeModelId } } } }) {
  const { currentData } = useFetchHomeModelQuery(homeModelId);
  const { numAvailable, futureDeliveries } = currentData ?? {};
  const totalFuture = futureDeliveries ? sumBy(futureDeliveries, pair => pair[1]) : 0;
  return numAvailable + totalFuture;
}

const ADDRESS_COLUMN = {
  id: 'address',
  header: 'Address',
  enableHiding: false,
  accessorKey: 'property.address',
  cell: AddressCell,
  sortingFn: 'text',
  meta: { className: 'pl-3', ...tableConfigMeta({ order: 'readonly' }) },
};

const HOME_MODEL_COLUMNS = [
  {
    id: 'homeModelType',
    header: 'Home Model',
    accessorKey: 'property.homeModelType',
  },
  {
    id: 'community',
    header: 'Community',
    accessorKey: 'property.community',
  },
];

const commonColumns = (includeHomeModelType) => compact([
  columnHelper.accessor(row => !row.property.buyBoxMatch.length, {
    id: 'isBuyBoxMatch',
    filterFn: 'equals',
    meta: { ...dataTableMeta.disableTableConfig },
  }),
  ADDRESS_COLUMN,
  ...(includeHomeModelType ? HOME_MODEL_COLUMNS : []),
  {
    id: 'market',
    header: 'Market',
    accessorKey: 'property.market',
  },
  {
    id: 'propertyType',
    header: 'Property Type',
    accessorKey: 'property.propertyType',
  },
  {
    id: 'bed',
    header: 'Bed',
    accessorKey: 'property.bedrooms',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'bath',
    header: 'Bath',
    accessorKey: 'property.bathrooms',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'sqft',
    header: 'Sq Ft',
    accessorKey: 'property.livingArea',
    cell: ({ getValue }) => formatInteger(getValue()),
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'yearBuilt',
    header: 'Year Built',
    accessorKey: 'property.yearBuilt',
    filterFn: rangeFilter,
    meta: { ...dataTableMeta.textRight },
  },
]);

const NEW_BUILD_COLUMNS = [
  {
    id: 'multiplicity',
    header: 'Quantity',
    accessorKey: 'parameters.multiplicity',
    cell: HomeModelQuantityCell,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'availableNow',
    header: 'Available Now',
    cell: HomeModelAvailableNow,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'availableTotal',
    header: 'Available Total',
    cell: HomeModelAvailableTotal,
    meta: { ...dataTableMeta.textRight },
  },
];

const INDIVIDUAL_COLUMNS = [
  {
    id: 'listPrice',
    header: 'List Price',
    accessorKey: 'parameters.listPrice',
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'underwrittenPrice',
    header: 'Underwritten Price',
    accessorKey: 'parameters.purchasePrice',
    meta: {
      mutatorFn: parameterMutatorFn(['parameters', 'purchasePrice']),
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
      ...dataTableMeta.textRight,
    },
  },
  {
    id: 'percentOfList',
    header: '% of List',
    accessorFn: row => {
      const underwrittenPrice = row.scenario?.parameters?.purchasePrice;
      const listPrice = row.scenario?.parameters?.listPrice;
      return (underwrittenPrice && listPrice && (underwrittenPrice > 0)) ? ((underwrittenPrice / listPrice) - 1) : null;
    },
    cell: PercentDiffCell,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'avm',
    header: 'AVM',
    accessorFn: row => row.property?.data?.avm?.estimatedValueAmount,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'totalCost',
    header: 'Total Cost',
    accessorFn: row => row.scenario?.returnMetrics?.unleveredBasis?.[0],
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'arv',
    header: 'ARV',
    accessorFn: row => row.scenario?.parameters?.arv,
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'builtInEquity',
    header: 'Built-In Equity',
    accessorFn: row => {
      const arv = row.scenario?.parameters?.arv;
      const totalAcquistionCost = row.scenario?.returnMetrics?.unleveredBasis?.[0];
      return (arv && totalAcquistionCost) ? (arv / totalAcquistionCost) - 1 : null;
    },
    cell: ({ getValue }) => formatPercentage(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'inPlaceRent',
    header: 'In-Place Rent',
    accessorKey: 'parameters.units.0.inPlaceRent',
    filterFn: rangeFilter,
    meta: {
      mutatorFn: parameterMutatorFn(['parameters', 'units', 0, 'inPlaceRent']),
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
      ...dataTableMeta.textRight,
    },
  },
  {
    id: 'marketRent',
    header: 'Market Rent',
    accessorKey: 'parameters.units.0.marketRent',
    filterFn: rangeFilter,
    meta: {
      mutatorFn: parameterMutatorFn(['parameters', 'units', 0, 'marketRent']),
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
      ...dataTableMeta.textRight,
    },
  },
  {
    id: 'compRent',
    header: 'Comp Rent',
    accessorFn: row => row.parameters.units[0].compRent,
    cell: CompRentCell,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'capital',
    header: 'Renovation',
    accessorFn: row => getReturnMetric(row.scenario, 'totalCapital'),
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'stabilizationMonth',
    header: 'Stab. Month',
    accessorFn: row => row.scenario?.returnMetrics?.stabilizationMonth,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'taxes',
    header: 'Taxes',
    accessorFn: row => {
      const taxes = row.scenario?.returnMetrics?.taxes;
      return taxes ? sum(taxes.slice(0, 12)) : null;
    },
    cell: ({ getValue }) => (getValue() ? formatCurrency(getValue()) : '-'),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'hoa',
    header: 'HOA',
    accessorFn: row => {
      const hoaExpenseItem = row.scenario?.parameters?.expenseItems?.find(ei => ei.name === HOA_FEE_EXPENSE_ITEM_NAME);
      if (hoaExpenseItem) {
        const { inputMethod, inputValue } = hoaExpenseItem;
        return inputMethod === ITEMIZED_INPUT_METHOD_DPM ? inputValue * 12 : inputValue;
      } else {
        return null;
      }
    },
    cell: ({ getValue }) => formatCurrency(getValue()),
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'rollToMarket',
    header: 'Lease End Month',
    accessorFn: row => row.scenario?.parameters?.units?.[0]?.rollToMarket,
    meta: { ...dataTableMeta.textRight },
  },
  {
    id: 'yield',
    header: 'Yield',
    accessorFn: row => getReturnMetric(row, 'stabilizedYield'),
    cell: ReturnMetricCell,
    meta: {
      editImpliedChanges: ['underwrittenPrice', 'inPlaceRent', 'marketRent'],
      formatter: formatPercentage,
      ...dataTableMeta.textRight,
    },
  },
  {
    id: 'irr',
    header: 'IRR',
    accessorFn: row => getReturnMetric(row, 'unleveredIrr'),
    cell: ReturnMetricCell,
    meta: {
      editImpliedChanges: ['underwrittenPrice', 'inPlaceRent', 'marketRent'],
      formatter: formatPercentage,
      ...dataTableMeta.textRight,
    },
  },
  {
    id: 'equityMultiple',
    header: 'Eq Mult',
    accessorFn: row => getReturnMetric(row, 'unleveredEquityMultiple'),
    cell: ReturnMetricCell,
    meta: {
      editImpliedChanges: ['underwrittenPrice', 'inPlaceRent', 'marketRent'],
      formatter: formatEquityMultiple,
      ...dataTableMeta.textRight,
    },
  },
];

const getColumns = ({ includeHomeModelType, newBuildDeal }) => {
  const columns = [
    ...commonColumns(includeHomeModelType),
    ...(newBuildDeal ? NEW_BUILD_COLUMNS : []),
    ...INDIVIDUAL_COLUMNS,
  ];

  columns.push({
    id: 'buybox',
    header: 'Buy Box',
    accessorKey: 'property.buyBoxMatch',
    cell: BuyBoxCell,
  });

  columns.push({
    id: 'active',
    header: ActiveHeader,
    enableHiding: false,
    accessorKey: 'active',
    enableSorting: false,
    meta: {
      ...enableEditing({ cell: InputCell, inputType: 'checkbox' }),
      textAlign: 'center',
      ...dataTableMeta.disableTableConfig,
    },
  });

  if (newBuildDeal) {
    const addressCellIdx = columns.indexOf(ADDRESS_COLUMN);
    columns[addressCellIdx] = { ...ADDRESS_COLUMN, header: 'Home Model' };
  }
  return columns;
};

const YIELD_LABEL = 'Yield';
function StatCell({ label, value, setShowYieldTargetModal }) {
  if (label === YIELD_LABEL) {
    return (
      <div className="bg-white px-3 py-1.5 rounded shadow">
        <div className="flex gap-x-3">
          <div className="text-center">
            <div>{value}</div>
            <div className="text-xs text-gray-500">{label}</div>
          </div>
          <Pencil
            className="mt-0.5 size-5 cursor-pointer text-tertiary hover:text-tertiary-lighter"
            onClick={() => setShowYieldTargetModal(true)}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white px-3 py-1.5 rounded shadow text-center">
      <div>{value}</div>
      <div className="text-xs text-gray-500">{label}</div>
    </div>
  );
}

const SummarySection = forwardRef(({ newBuildDeal, setShowYieldTargetModal, showYieldTargetModal }, ref) => {
  const filteredRows = useFilteredRows();
  const updatedScenarios = useAllRowUpdates();
  const [effectiveScenarios, activeEffectiveScenarios] = useMemo(() => {
    const updatedRowsById = groupBy(updatedScenarios, 'id');
    const scenarios = filteredRows.map(row => (updatedRowsById[row.id] ? getUpdatedRowData(updatedRowsById[row.id][0])[0] : row.original));
    return [scenarios, scenarios.filter(s => s.active)];
  }, [filteredRows, updatedScenarios]);

  const {
    stabilizedYield,
    unleveredIrr,
    unleveredEquityMultiple,
  } = useMemo(() => (activeEffectiveScenarios.length ? calcReturnMetricsOfIndividualScenarios(activeEffectiveScenarios) : {}), [activeEffectiveScenarios]);

  const listPrice = dotProductBy(effectiveScenarios, 'parameters.listPrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
  const underwrittenPrice = dotProductBy(effectiveScenarios, 'parameters.purchasePrice', ({ parameters: { multiplicity } }) => multiplicity ?? 1);
  const underwrittenListPercentDiff = (underwrittenPrice > 0) ? ((underwrittenPrice / listPrice) - 1) : null;
  const underwrittenListPercentDiffComp = (isNil(underwrittenListPercentDiff) || (underwrittenListPercentDiff === 0)) ? null : (
    <PercentDiffCell
      arrowClassName="fill-gray-500"
      className="text-sm text-gray-500"
      getValue={() => underwrittenListPercentDiff}
      showListLabel
    />
  );

  const stats = [
    {
      label: newBuildDeal ? 'Home Models' : 'Properties',
      value: (activeEffectiveScenarios.length < effectiveScenarios.length) ? (
        <div className="flex justify-center items-center">
          <div>{activeEffectiveScenarios.length}</div>
          <div className="ml-2 text-gray-400 text-sm">{`/  ${effectiveScenarios.length}`}</div>
        </div>
      ) : effectiveScenarios.length,
    },
    {
      label: 'List Price',
      value: formatCurrency(listPrice),
    },
    {
      label: 'Underwritten Price',
      value: underwrittenListPercentDiffComp ? (
        <div className="flex items-center gap-x-2">
          <div>{formatCurrency(underwrittenPrice)}</div>
          {underwrittenListPercentDiffComp}
        </div>
      ) : formatCurrency(underwrittenPrice),
    },
    {
      label: YIELD_LABEL,
      value: formatPercentage(stabilizedYield, 2),
    },
    {
      label: 'IRR',
      value: formatPercentage(unleveredIrr),
    },
    {
      label: 'Equity Multiple',
      value: formatEquityMultiple(unleveredEquityMultiple),
    },
    {
      label: 'Buy Box Match',
      value: (
        <div className="flex justify-center items-center">
          <div>{effectiveScenarios.filter(scenario => isEmpty(scenario.property.buyBoxMatch)).length}</div>
          <div className="ml-2 text-gray-400 text-sm">{`/  ${effectiveScenarios.length}`}</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div ref={ref} className="h-16 ml-12 flex items-center gap-x-4 border-b">
        {stats.map(stat => (
          <StatCell key={stat.label} {...stat} setShowYieldTargetModal={setShowYieldTargetModal} />
        ))}
      </div>
      {showYieldTargetModal && (
        <YieldTargetModal
          effectiveScenarios={effectiveScenarios}
          setShowYieldTargetModal={setShowYieldTargetModal}
        />
      )}
    </>
  );
});

function EditControls({ deal }) {
  const dispatch = useDispatch();
  const getUpdatedRows = useGetUpdatedRowData();
  const pendingChanges = useIsTableEdited();
  const clearChanges = useClearRowUpdates();
  const [updateScenariosMutation] = useUpdateScenariosMutation();

  const onSave = useCallback(async (onSuccess) => {
    const updatedScenarios = getUpdatedRows();
    const response = await updateScenariosMutation({ dealId: deal.id, scenarios: updatedScenarios });
    if (response.error) {
      console.error(response.error);
    } else {
      onSuccess();
    }
  }, [deal.id, getUpdatedRows, updateScenariosMutation]);

  useEffect(() => {
    if (pendingChanges) {
      dispatch(showToast(TOAST_UNSAVED_CHANGES({ reset: clearChanges, save: partial(onSave, () => dispatch(clearToast())) })));
    } else {
      dispatch(clearToast());
    }
  }, [clearChanges, dispatch, pendingChanges, onSave]);

  const {
    state: blockerState,
    proceed,
    reset,
  } = useBlocker(pendingChanges);

  useEffect(() => {
    if (blockerState === 'blocked') {
      const onCancel = () => reset();
      const onDoNotSave = () => {
        clearChanges();
        dispatch(clearToast());
        proceed();
      };
      dispatch(showSaveChangesModal(true, onCancel, onDoNotSave, partial(onSave, () => proceed())));
    }
  }, [blockerState]);

  return null;
}

function YieldTargetModal({ effectiveScenarios, setShowYieldTargetModal }) {
  const onClose = () => setShowYieldTargetModal(false);
  const [yieldTarget, setYieldTarget] = useState();
  const [isLoading, setIsLoading] = useState();

  const [, setUpdate] = useUpdateRows({ rowIds: effectiveScenarios.map(s => s.id), columnId: 'underwrittenPrice' });

  const onUpdate = async (targetYield) => {
    setIsLoading(true);
    // calculating solveToYield for every scenario can take time, so unblock UI thread
    // by splitting work into promises
    const pricePromises = effectiveScenarios.map(scenario => new Promise(resolve => {
      setTimeout(() => resolve(Math.round(solveToYield(targetYield, scenario.parameters))));
    }));
    const result = await Promise.all(pricePromises);
    const resultsByScenarioId = effectiveScenarios.reduce((agg, scenario, index) => ({
      ...agg,
      [scenario.id]: result[index],
    }), {});
    setUpdate((rowId) => resultsByScenarioId[rowId]);
    setIsLoading(false);
    onClose();
  };

  // TODO: should we add some warning that it might take a long time?
  return (
    <Modal show showCloseAction onClose={onClose} title="Solve to Target Yield">
      <div className="w-96">
        <p className="mt-8">Enter a target net stabilized yield. The underwritten prices will be updated to result in the desired yield.</p>
        <InlineFormField
          name="yieldTarget"
          value={yieldTarget}
          type="percent"
          padding="py-2 px-3"
          className="mt-6"
          onChange={(e) => setYieldTarget(parseEventValue(e))}
        />
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={onClose}
          />
          <Button
            filled
            disabled={!(isNumber(yieldTarget) && (yieldTarget > 0))}
            label="Update Prices"
            isLoading={isLoading}
            onClick={() => onUpdate(yieldTarget)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default function PortfolioDealSummary({ context }) {
  const [showYieldTargetModal, setShowYieldTargetModal] = useState(false);
  const { data, modelData } = context;
  const { deal, parcels, properties, homeModels } = data;
  const { model: { scenarios } } = modelData;
  const newBuildDeal = homeModels && !isEmpty(homeModels);

  if (newBuildDeal) {
    const findMin = ({ parameters: { multiplicity, homeModelAddresses, homeModelDeliverySchedule } }) => (
      Math.max(
        multiplicity ?? 0,
        homeModelAddresses?.length ?? 0,
        sum(homeModelDeliverySchedule?.map(([, value]) => value)),
      )
    );

    if (scenarios.some(({ parameters: { multiplicity, homeModelAddresses, homeModelDeliverySchedule } }) => {
      const minMultiplicity = findMin({ parameters: { homeModelAddresses, homeModelDeliverySchedule } });
      return (multiplicity ?? 0) < minMultiplicity;
    })) {
      // TODO:
      // setScenarios((prev) => (
      //   prev.map((scenario) => ({
      //     ...scenario,
      //     parameters: {
      //       ...scenario.parameters,
      //       multiplicity: findMin(scenario),
      //     },
      //   }))
      // ));
    }
  }

  // include filterable Home Model and Community columns if any property contains that info
  const includeHomeModelType = useMemo(() => properties.find(p => p.community || p.homeModelType), [properties]);
  const columns = useMemo(() => getColumns({ newBuildDeal, includeHomeModelType }), [includeHomeModelType, newBuildDeal]);

  const tableData = useMemo(() => properties.map(property => {
    const parcel = getParcel(property, parcels);
    const scenario = getScenario(property, scenarios);
    const normalizedProperty = coalesceProperty(property, parcel, null, getUnits(property, scenario));
    return {
      ...scenario,
      parcel,
      property: normalizedProperty,
      scenario,
    };
  }), [parcels, properties, scenarios]);

  const containerRef = useRef();
  const containerHeight = useElementHeight(containerRef);
  const filterRef = useRef();
  const filterHeight = useElementHeight(filterRef);
  const summaryRef = useRef();
  const summaryHeight = useElementHeight(summaryRef);

  const filterOptions = useMemo(() => ({
    community: uniq(tableData.map(td => td.property.community)).sort(),
    homeModelType: uniq(tableData.map(td => td.property.homeModelType)).sort(),
    market: uniq(tableData.map(td => td.property.market)).sort(),
    propertyType: uniq(tableData.map(td => td.property.propertyType)).sort(),
  }), [tableData]);
  const bedRangeDisplayValue = useRangeDisplayValue({ suffix: ' bd' });
  const bathRangeDisplayValue = useRangeDisplayValue({ suffix: ' ba' });
  const sqftRangeDisplayValue = useRangeDisplayValue({ suffix: ' sqft' });
  const basicRangeDisplayValue = useRangeDisplayValue();
  const percentOfListRangeDisplayValue = useRangeDisplayValue({ formatter: formatPercentage, suffix: ' List' });
  const yieldRangeDisplayValue = useRangeDisplayValue({ formatter: formatPercentage, suffix: ' yield' });
  const currencyRangeDisplayValue = useRangeDisplayValue({ formatter: formatCurrency });

  const tableId = getTableId({ newBuildDeal });
  return (
    <div
      className="bg-gray-50"
      ref={containerRef}
      style={{
        width: `calc(100vw - ${LAYOUT.rightNavWidth + LAYOUT.sidebarWidth}px)`,
        height: `calc(100vh - ${LAYOUT.dealHeaderHeight}px)`,
      }}
    >
      <DataTable
        enableEditing
        columns={columns}
        data={tableData}
        tableHeight={containerHeight - (filterHeight + summaryHeight)}
        initialState={TABLE_INITIAL_STATE}
        meta={{ ...enableConfigPresets({ presets: CONFIG_PRESETS[tableId] }) }}
      >
        <EditControls deal={deal} />
        <div className="w-full">
          <SummarySection
            ref={summaryRef}
            newBuildDeal={newBuildDeal}
            tableData={tableData}
            setShowYieldTargetModal={setShowYieldTargetModal}
            showYieldTargetModal={showYieldTargetModal}
          />
          <div
            ref={filterRef}
            className="flex justify-between gap-x-8 px-3 py-1 items-center bg-white"
          >
            <ReactTableFilter>
              <ChipGroup>
                {(filterOptions.homeModelType.length > 1) && (
                  <ReactTableColumnFilter
                    label="Home Model"
                    columnId="homeModelType"
                    displayValue="Home Model"
                  >
                    <FilterChip>
                      <ComboboxFilter
                        isLoading={false}
                        options={filterOptions.homeModelType}
                      />
                    </FilterChip>
                  </ReactTableColumnFilter>
                )}
                {(filterOptions.community.length > 1) && (
                  <ReactTableColumnFilter
                    label="Community"
                    columnId="community"
                    displayValue="Community"
                  >
                    <FilterChip>
                      <ComboboxFilter
                        isLoading={false}
                        options={filterOptions.community}
                      />
                    </FilterChip>
                  </ReactTableColumnFilter>
                )}
                {(filterOptions.market.length > 1) && (
                  <ReactTableColumnFilter
                    label="Market"
                    columnId="market"
                    displayValue="Market"
                  >
                    <FilterChip>
                      <ComboboxFilter
                        isLoading={false}
                        options={filterOptions.market}
                      />
                    </FilterChip>
                  </ReactTableColumnFilter>
                )}
                {(filterOptions.propertyType.length > 1) && (
                  <ReactTableColumnFilter
                    label="Property Type"
                    columnId="propertyType"
                    displayValue="Property Type"
                  >
                    <FilterChip>
                      <ComboboxFilter
                        isLoading={false}
                        options={filterOptions.propertyType}
                      />
                    </FilterChip>
                  </ReactTableColumnFilter>
                )}
                <ReactTableColumnFilter
                  label="Beds"
                  columnId="bed"
                  displayValue={bedRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Baths"
                  columnId="bath"
                  displayValue={bathRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Sq Ft"
                  columnId="sqft"
                  displayValue={sqftRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Year Built"
                  columnId="yearBuilt"
                  displayValue={basicRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="List Price"
                  columnId="listPrice"
                  displayValue={currencyRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="currency" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="% of List"
                  columnId="percentOfList"
                  displayValue={percentOfListRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="percent" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="In-Place Rent"
                  columnId="inPlaceRent"
                  displayValue={currencyRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="currency" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Market Rent"
                  columnId="marketRent"
                  displayValue={currencyRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="currency" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Yield"
                  columnId="yield"
                  displayValue={yieldRangeDisplayValue}
                >
                  <FilterChip>
                    <RangeFilter type="percent" />
                  </FilterChip>
                </ReactTableColumnFilter>
                <ReactTableColumnFilter
                  label="Matches Buy Box"
                  displayValue={meetsBuyBoxFilterDisplayFn}
                  columnId="isBuyBoxMatch"
                >
                  <BooleanFilterChip leadingIcon={false}>
                    <TrueFalseToggle />
                  </BooleanFilterChip>
                </ReactTableColumnFilter>
              </ChipGroup>
              <FilterMessage tableData={tableData} />
            </ReactTableFilter>
            <DataTableConfig tableId={tableId}>
              <TableConfigButton />
            </DataTableConfig>
          </div>
          <DataTableContent />
        </div>
      </DataTable>
    </div>
  );
}
