import FloatingTooltip from 'components/shared/FloatingTooltip';

export default function TruncatedWithTooltipCell({ column, getValue, width = 'w-48' }) {
  const { columnDef: { meta } } = column;
  const formattedValue = meta.formatter ? meta.formatter(getValue()) : getValue();

  return (
    <FloatingTooltip contents={formattedValue} tooltipClassName={width}>
      <p className="max-w-60 truncate text-ellipsis line-clamp-1">
        {formattedValue}
      </p>
    </FloatingTooltip>
  );
}
