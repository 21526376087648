import { useCallback, useMemo } from 'react';
import { identity } from 'lodash';
import {
  useAllFlatColumns,
  useColumnFilter,
  useCoreRowModel,
  useGlobalFilter,
  useSelectedRowModel,
  useSetSorting,
} from 'components/shared/Table/DataTableContext';
import { selectRowOnClick } from 'components/shared/Table/table.helpers';
import useSyncSearchParam, { useQueuedSearchParams } from 'hooks/useSyncSearchParam';
import { useCurrentSideNavPanel, useSetCurrentSideNavPanel } from './InventorySideNav';

export function SelectLinkedRow() {
  const { flatRows: [selectedRow] } = useSelectedRowModel();
  const rowModel = useCoreRowModel();

  // TODO: scroll to row

  useSyncSearchParam({
    key: 'id',
    value: selectedRow?.id,
    defaultValue: undefined,
    onChange: useCallback((rowId) => (
      rowId && rowModel.rowsById[rowId] && selectRowOnClick(rowModel.rowsById[rowId])
    ), [rowModel.rowsById]),
  });

  return null;
}

export function OpenLinkedPanel() {
  useSyncSearchParam({
    key: 'panel',
    value: useCurrentSideNavPanel(),
    defaultValue: '',
    serialize: identity,
    deserialize: identity,
    onChange: useSetCurrentSideNavPanel(),
  });

  return null;
}

/**
 * @param {import('@tanstack/react-table').Column} column
 * @param setSearchParams
 */
function ApplyLinkedColumnFilter({ column, setSearchParams }) {
  const { filterValue, setFilterValue, filterInitialValue } = useColumnFilter(column.id);

  // use a separate key for each filter (instead of a single key for all filters)
  // so the filter_* parameters could be used to partially set the filters (and defer to the default state for the rest)
  useSyncSearchParam({
    key: `filter_${column.id}`,
    value: filterValue,
    defaultValue: filterInitialValue,
    onChange: setFilterValue,
    onSearchParamsChange: setSearchParams,
  });

  return null;
}

export function ApplyLinkedFilters() {
  // filters for all columns need to be queued to the same queue
  // so that updates to different filters done in the same render cycle
  // do not overwrite each other
  const [, setSearchParams] = useQueuedSearchParams();
  const columns = useAllFlatColumns();

  return useMemo(() => (
    columns
      .filter((column) => column.getCanFilter())
      .map((column) => (
        <ApplyLinkedColumnFilter key={column.id} column={column} setSearchParams={setSearchParams} />
      ))
  ), [columns, setSearchParams]);
}

export function ApplyLinkedSorting() {
  const [sorting, setSorting, initialSorting] = useSetSorting();

  useSyncSearchParam({
    key: 'sorting',
    value: sorting,
    defaultValue: initialSorting,
    onChange: setSorting,
  });

  return null;
}

export function ApplyLinkedGlobalFilter() {
  const [globalFilter, setGlobalFilter, initialGlobalFilter] = useGlobalFilter();

  useSyncSearchParam({
    key: 'globalFilter',
    value: globalFilter,
    defaultValue: initialGlobalFilter,
    serialize: identity,
    deserialize: identity,
    onChange: setGlobalFilter,
  });

  return null;
}
