import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty, isNil, pull, remove, trim, xor } from 'lodash';
import {
  ACTIVE_STATUS,
  CHANNEL_LISTED,
  CHANNEL_NEW_BUILD,
  CHANNEL_OFF_MARKET,
  CHANNEL_OFF_MARKET_MARKETPLACE,
  GENERALIZED_PIPELINE_STATUS,
  LISTING_STATUSES,
  RESIDENTIAL_INCOME_PROPERTY_TYPE,
  RESIDENTIAL_PROPERTY_TYPE,
} from 'components/constants';
import { ArrowBack } from 'components/icons';
import Button from 'components/shared/Button';
import Input from 'components/Input';
import { titleCase, parseEventValue } from 'components/utils';
import Chip from 'components/shared/Chip';
import { PoolFlagFilter } from 'components/portfolio/Menu/BuyBox';
import { DEFAULT_FILTERS, defaultFilters } from 'components/DealSourcing/dealSourcing';
import { BedBathButton } from 'components/shared/filters/BedBathFilter';

function FilterSection({ children, label }) {
  return (
    <div className="px-6 mt-6 border-b">
      <div className="text-2xl font-light mb-6">{label}</div>
      {children}
    </div>
  );
}

function FilterSubSection({ children, label }) {
  return (
    <div className="mb-6">
      <div className="mb-4">{label}</div>
      {children}
    </div>
  );
}

function BedFilters({ localFilters, updateLocalFilter }) {
  const [bedExactMatch, setBedExactMatch] = useState(localFilters?.bedroomsTotal?.useExact || false);
  const { selections } = localFilters.bedroomsTotal;

  function onBedSelection(bedFilter, e) {
    const updatedBedFilter = { ...bedFilter };
    const { selections: updatedSelections = [] } = updatedBedFilter;
    if (updatedSelections?.includes(e)) {
      remove(updatedSelections, (n) => n === e);
    } else {
      selections.push(e);
    }
    updateLocalFilter('bedroomsTotal', { useExact: bedExactMatch, selections: selections.sort() });
  }

  return (
    <>
      <div className="flex w-full mb-3">
        {bedExactMatch ? (
          <>
            <BedBathButton label="Studio" active={selections?.includes(0)} onClick={() => onBedSelection(localFilters.bedroomsTotal, 0)} width="w-1/6" />
            <BedBathButton label="1" active={selections?.includes(1)} onClick={() => onBedSelection(localFilters.bedroomsTotal, 1)} width="w-1/6" />
            <BedBathButton label="2" active={selections?.includes(2)} onClick={() => onBedSelection(localFilters.bedroomsTotal, 2)} width="w-1/6" />
            <BedBathButton label="3" active={selections?.includes(3)} onClick={() => onBedSelection(localFilters.bedroomsTotal, 3)} width="w-1/6" />
            <BedBathButton label="4" active={selections?.includes(4)} onClick={() => onBedSelection(localFilters.bedroomsTotal, 4)} width="w-1/6" />
            <BedBathButton label="5+" active={selections?.includes(5)} onClick={() => onBedSelection(localFilters.bedroomsTotal, 5)} lastButton width="w-1/6" />
          </>
        ) : (
          <>
            <BedBathButton label="Any" active={isEmpty(selections)} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [] })} width="w-1/6" />
            <BedBathButton label="Studio+" active={selections ? selections[0] === 0 : false} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [0, null] })} width="w-1/6" />
            <BedBathButton label="1+" active={selections ? selections[0] === 1 : false} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [1, null] })} width="w-1/6" />
            <BedBathButton label="2+" active={selections ? selections[0] === 2 : false} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [2, null] })} width="w-1/6" />
            <BedBathButton label="3+" active={selections ? selections[0] === 3 : false} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [3, null] })} width="w-1/6" />
            <BedBathButton label="4+" active={selections ? selections[0] === 4 : false} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [4, null] })} width="w-1/6" />
            <BedBathButton label="5+" active={selections ? selections[0] === 5 : false} onClick={() => updateLocalFilter('bedroomsTotal', { ...localFilters.bedroomsTotal, selections: [5, null] })} lastButton width="w-1/6" />
          </>
        )}
      </div>
      <div>
        <input
          checked={bedExactMatch}
          className="mr-4"
          id="bedroom-checkbox"
          onChange={() => { setBedExactMatch(!bedExactMatch); updateLocalFilter('bedroomsTotal', { ...localFilters?.bedroomsTotal, useExact: !bedExactMatch, selections: [] }); }}
          type="checkbox"
        />
        <label className="text-gray-600" htmlFor="bedroom-checkbox">Use exact match</label>
      </div>
    </>
  );
}

function BathFilters({ localFilters, updateLocalFilter }) {
  const [bathExactMatch, setBathExactMatch] = useState(localFilters?.bathroomsTotalInteger?.useExact || false);
  const { selections } = localFilters.bathroomsTotalInteger;

  function onBathSelection(bathFilter, e) {
    const updatedBathFilter = { ...bathFilter };
    const { selections: updatedSelections = [] } = updatedBathFilter;
    if (updatedSelections?.includes(e)) {
      remove(updatedSelections, (n) => n === e);
    } else {
      updatedSelections.push(e);
    }
    updateLocalFilter('bathroomsTotalInteger', { useExact: bathExactMatch, selections: updatedSelections.sort() });
  }

  return (
    <>
      <div className="flex w-full mb-3">
        {bathExactMatch ? (
          <>
            <BedBathButton label="1" active={selections?.includes(1)} onClick={() => onBathSelection(localFilters.bathroomsTotalInteger, 1)} width="w-1/5" />
            <BedBathButton label="2" active={selections?.includes(2)} onClick={() => onBathSelection(localFilters.bathroomsTotalInteger, 2)} width="w-1/5" />
            <BedBathButton label="3" active={selections?.includes(3)} onClick={() => onBathSelection(localFilters.bathroomsTotalInteger, 3)} width="w-1/5" />
            <BedBathButton label="4+" active={selections?.includes(4)} onClick={() => onBathSelection(localFilters.bathroomsTotalInteger, 4)} width="w-1/5" />
            <BedBathButton label="5+" active={selections?.includes(5)} onClick={() => onBathSelection(localFilters.bathroomsTotalInteger, 5)} lastButton width="w-1/5" />
          </>
        ) : (
          <>
            <BedBathButton label="Any" active={isEmpty(selections)} onClick={() => updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, selections: [] })} width="w-1/6" />
            <BedBathButton label="1+" active={selections ? selections[0] === 1 : false} onClick={() => updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, selections: [1, null] })} width="w-1/6" />
            <BedBathButton label="2+" active={selections ? selections[0] === 2 : false} onClick={() => updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, selections: [2, null] })} width="w-1/6" />
            <BedBathButton label="3+" active={selections ? selections[0] === 3 : false} onClick={() => updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, selections: [3, null] })} width="w-1/6" />
            <BedBathButton label="4+" active={selections ? selections[0] === 4 : false} onClick={() => updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, selections: [4, null] })} width="w-1/6" />
            <BedBathButton label="5+" active={selections ? selections[0] === 5 : false} onClick={() => updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, selections: [5, null] })} width="w-1/6" lastButton />
          </>
        )}
      </div>
      <div>
        <input
          checked={bathExactMatch}
          className="mr-4"
          id="bathroom-checkbox"
          onChange={() => { setBathExactMatch(!bathExactMatch); updateLocalFilter('bathroomsTotalInteger', { ...localFilters.bathroomsTotalInteger, useExact: !bathExactMatch, selections: [] }); }}
          type="checkbox"
        />
        <label className="text-gray-600" htmlFor="bathroom-checkbox">Use exact match</label>
      </div>
    </>
  );
}

const LISTING_TYPE_ID_MAP = {
  'all-checkbox': null,
  'residential-checkbox': RESIDENTIAL_PROPERTY_TYPE,
  'residential-income-checkbox': RESIDENTIAL_INCOME_PROPERTY_TYPE,
};

function ListingTypeFilters({ localFilters, updateLocalFilter }) {
  const onChange = e => { updateLocalFilter('propertyType', LISTING_TYPE_ID_MAP[e.target.id]) };
  return (
    <fieldset>
      <div>
        <input
          checked={isNil(localFilters.propertyType)}
          className="mr-4 mb-6"
          id="all-checkbox"
          name="listingType"
          onChange={onChange}
          type="radio"
        />
        <label className="font-light" htmlFor="all-checkbox">All</label>
      </div>
      <div>
        <input
          checked={localFilters.propertyType === RESIDENTIAL_PROPERTY_TYPE}
          className="mr-4 mb-6"
          id="residential-checkbox"
          name="listingType"
          onChange={onChange}
          type="radio"
        />
        <label className="font-light" htmlFor="residential-checkbox">Residential</label>
      </div>
      <div>
        <input
          checked={localFilters.propertyType === RESIDENTIAL_INCOME_PROPERTY_TYPE}
          className="mr-4"
          id="residential-income-checkbox"
          name="listingType"
          onChange={onChange}
          type="radio"
        />
        <label className="font-light" htmlFor="residential-income-checkbox">Residential Income</label>
      </div>
    </fieldset>
  );
}

function PipelineFilters({ localFilters, updateLocalFilter, pipeline }) {
  const selected = localFilters.pipeline.includes(pipeline);
  const onClick = () => {
    const updatedPipelineFilter = selected ? pull(localFilters.pipeline, pipeline) : localFilters.pipeline.concat([pipeline]);
    updateLocalFilter('pipeline', updatedPipelineFilter);
  };
  return (
    <Chip label={`Show ${titleCase(pipeline)}`} leadingIcon={selected} onClick={onClick} selected={selected} />
  );
}

function MlsStatusChip({ localFilters, updateLocalFilter, status }) {
  const selected = localFilters.standardStatus.includes(status);
  const onClick = () => {
    const updatedStatusFilter = selected ? pull(localFilters.standardStatus, status) : localFilters.standardStatus.concat([status]);
    // one status must always be enabled, so default that to Active
    if (isEmpty(updatedStatusFilter)) {
      updatedStatusFilter.push(ACTIVE_STATUS);
    }
    updateLocalFilter('standardStatus', updatedStatusFilter);
  };
  return (
    <Chip label={titleCase(status)} leadingIcon={selected} onClick={onClick} selected={selected} />
  );
}

function CheckboxFilterChip({ localFilters, updateLocalFilter, optionValue, filterKey }) {
  const selected = localFilters[filterKey]?.includes(optionValue);
  const onClick = () => {
    updateLocalFilter(filterKey, xor(localFilters[filterKey] ?? [], [optionValue]));
  };
  return (
    <Chip label={optionValue} leadingIcon={selected} onClick={onClick} selected={selected} />
  );
}

export default function AdvancedFilters({ filters, propertySubTypes, homeModelBuilders, homeModelSubdivisions, portfolio, setFilters, setShowAdvancedFilters, settings }) {
  const { channel } = settings;
  const [localFilters, setLocalFilters] = useState(cloneDeep(filters));

  useEffect(() => {
    setLocalFilters(cloneDeep(filters));
  }, [filters]);

  const updateLocalFilter = (key, value) => {
    setLocalFilters({ ...localFilters, [key]: value });
  };

  const onApply = () => {
    setFilters({ ...filters, ...localFilters });
    setShowAdvancedFilters(false);
  };

  const onReset = () => {
    setFilters(defaultFilters(channel, portfolio));
  };

  const onClear = () => {
    setFilters(DEFAULT_FILTERS[channel]);
  };

  const zipCodeStringFromFilters = (zipCodesString) => zipCodesString?.join(',') || '';

  const channelIsListing = channel === CHANNEL_LISTED || channel === CHANNEL_OFF_MARKET_MARKETPLACE;

  return (
    <div className="absolute left-0 bg-white h-full w-full z-20 shadow">
      <div className="flex justify-between items-center px-6 py-3 border-b">
        <div className="flex items-center gap-x-4">
          <ArrowBack className="w-8 cursor-pointer" onClick={() => setShowAdvancedFilters(false)} />
          <h1 className="text-2xl font-light">Filters</h1>
        </div>
        <div className="flex items-center gap-x-6">
          <Button label="Clear All" textOnly onClick={onClear} />
          <Button label="Reset All" textOnly onClick={onReset} />
          <Button label="Done" onClick={onApply} />
        </div>
      </div>
      <div className="overflow-y-auto" style={{ height: 'calc(100% - 65px)' }}>
        {(channel === CHANNEL_NEW_BUILD) && (
          <FilterSection label="New Build">
            <FilterSubSection label="Price">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('listPrice', [parseEventValue(e), localFilters.listPrice?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.listPrice?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('listPrice', [localFilters.listPrice?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.listPrice?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Builder">
              <div className="flex flex-wrap gap-2">
                {homeModelBuilders.map((builder) => (
                  <CheckboxFilterChip
                    key={builder}
                    localFilters={localFilters}
                    updateLocalFilter={updateLocalFilter}
                    filterKey="homeModelBuilder"
                    optionValue={builder}
                  />
                ))}
              </div>
            </FilterSubSection>
            <FilterSubSection label="Community">
              <div className="flex flex-wrap gap-2">
                {homeModelSubdivisions.map((subdivision) => (
                  <CheckboxFilterChip
                    key={subdivision}
                    localFilters={localFilters}
                    updateLocalFilter={updateLocalFilter}
                    filterKey="homeModelSubdivision"
                    optionValue={subdivision}
                  />
                ))}
              </div>
            </FilterSubSection>
          </FilterSection>
        )}
        {channelIsListing && (
          <FilterSection label="Listing">
            <FilterSubSection label="MLS Status">
              <div className="flex flex-wrap gap-2">
                {LISTING_STATUSES.map((status) => (
                  <MlsStatusChip
                    key={status}
                    localFilters={localFilters}
                    status={status}
                    updateLocalFilter={updateLocalFilter}
                  />
                ))}
              </div>
            </FilterSubSection>
            <FilterSubSection label="List Price">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('listPrice', [parseEventValue(e), localFilters.listPrice?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.listPrice?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('listPrice', [localFilters.listPrice?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.listPrice?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="List Price per Unit">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('pricePerUnit', [parseEventValue(e), localFilters.pricePerUnit?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.pricePerUnit?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('pricePerUnit', [localFilters.pricePerUnit?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.pricePerUnit?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="List Price per Square Foot">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('pricePerSquareFoot', [parseEventValue(e), localFilters.pricePerSquareFoot?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.pricePerSquareFoot?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('pricePerSquareFoot', [localFilters.pricePerSquareFoot?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.pricePerSquareFoot?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Days on Market">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('daysOnMarket', [parseEventValue(e), localFilters.daysOnMarket?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.daysOnMarket?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('daysOnMarket', [localFilters.daysOnMarket?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.daysOnMarket?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
          </FilterSection>
        )}
        {(channel !== CHANNEL_OFF_MARKET) && (
          <FilterSection label="Pipeline">
            <FilterSubSection label="Pipeline">
                <div className="flex flex-wrap gap-2">
                  {GENERALIZED_PIPELINE_STATUS.map((status) => (
                    <PipelineFilters
                      key={status}
                      localFilters={localFilters}
                      pipeline={status}
                      updateLocalFilter={updateLocalFilter}
                    />
                  ))}
                </div>
              </FilterSubSection>
          </FilterSection>
        )}
        <FilterSection label="Property">
          {(channel !== CHANNEL_OFF_MARKET) && (
            <>
              <FilterSubSection label="Beds">
                <BedFilters localFilters={localFilters} updateLocalFilter={updateLocalFilter} />
              </FilterSubSection>
              <FilterSubSection label="Baths">
                <BathFilters localFilters={localFilters} updateLocalFilter={updateLocalFilter} />
              </FilterSubSection>
            </>
          )}
          {channelIsListing && (
            <>
              <FilterSubSection label="Listing Type">
                <ListingTypeFilters localFilters={localFilters} updateLocalFilter={updateLocalFilter} />
              </FilterSubSection>
              <FilterSubSection label="Property Type">
                <div className="flex flex-wrap gap-2">
                  {propertySubTypes.map((propertySubType) => (
                    <CheckboxFilterChip
                      key={propertySubType}
                      localFilters={localFilters}
                      updateLocalFilter={updateLocalFilter}
                      filterKey="propertySubType"
                      optionValue={propertySubType}
                    />
                  ))}
                </div>
              </FilterSubSection>
            </>
          )}
          {channelIsListing && (
            <FilterSubSection label="Unit Count">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('numberOfUnitsTotal', [parseEventValue(e), localFilters.numberOfUnitsTotal?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.numberOfUnitsTotal?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('numberOfUnitsTotal', [localFilters.numberOfUnitsTotal?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.numberOfUnitsTotal?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
          )}
          {(channel !== CHANNEL_NEW_BUILD) && (
            <FilterSubSection label="Year Built">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('yearBuilt', [parseEventValue(e), localFilters.yearBuilt?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.yearBuilt?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('yearBuilt', [localFilters.yearBuilt?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.yearBuilt?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
          )}
          <FilterSubSection label="Square Footage">
            <div className="flex justify-between items-center">
              <Input
                onChange={(e) => updateLocalFilter('buildingArea', [parseEventValue(e), localFilters.buildingArea?.[1]])}
                placeholder="No min"
                type="number"
                value={localFilters.buildingArea?.[0]}
                width="w-2/5"
              />
              &mdash;
              <Input
                onChange={(e) => updateLocalFilter('buildingArea', [localFilters.buildingArea?.[0], parseEventValue(e)])}
                placeholder="No max"
                type="number"
                value={localFilters.buildingArea?.[1]}
                width="w-2/5"
              />
            </div>
          </FilterSubSection>
          {(channel !== CHANNEL_NEW_BUILD) && (
            <FilterSubSection label="Zip Code">
              <div className="flex flex-col">
                <Input
                  onChange={(e) => updateLocalFilter('zipCodes', e.target.value.split(',').map(trim))}
                  type="text"
                  value={zipCodeStringFromFilters(localFilters.zipCodes)}
                  width="w-full"
                />
                <div className="mt-2 text-sm text-gray-500 text-light">Separate multiple zip codes with a comma</div>
              </div>
            </FilterSubSection>
          )}
          <FilterSubSection>
            <PoolFlagFilter value={localFilters.excludesHomesWithPool} onChange={() => updateLocalFilter('excludesHomesWithPool', !localFilters.excludesHomesWithPool)} />
          </FilterSubSection>
        </FilterSection>
        {(channel !== CHANNEL_OFF_MARKET) && (
          <FilterSection label="Return Metrics">
            <FilterSubSection label="Unlevered IRR">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('unleveredIrr', [parseEventValue(e), localFilters.unleveredIrr?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.unleveredIrr?.[0] || ''}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('unleveredIrr', [localFilters.unleveredIrr?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.unleveredIrr?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Levered IRR">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('leveredIrr', [parseEventValue(e), localFilters.leveredIrr?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.leveredIrr?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('leveredIrr', [localFilters.leveredIrr?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.leveredIrr?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Year 1 Cash Yield">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('y1Yield', [parseEventValue(e), localFilters.y1Yield?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.y1Yield?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('y1Yield', [localFilters.y1Yield?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.y1Yield?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Unlevered Equity Multiple">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('unleveredEquityMultiple', [parseEventValue(e), localFilters.unleveredEquityMultiple?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.unleveredEquityMultiple?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('unleveredEquityMultiple', [localFilters.unleveredEquityMultiple?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.unleveredEquityMultiple?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Levered Equity Multiple">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('leveredEquityMultiple', [parseEventValue(e), localFilters.leveredEquityMultiple?.[1]])}
                  placeholder="No min"
                  type="number"
                  value={localFilters.leveredEquityMultiple?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('leveredEquityMultiple', [localFilters.leveredEquityMultiple?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="number"
                  value={localFilters.leveredEquityMultiple?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Stabilized Yield">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('stabilizedYield', [parseEventValue(e), localFilters.stabilizedYield?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.stabilizedYield?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('stabilizedYield', [localFilters.stabilizedYield?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.stabilizedYield?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Gross Yield">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('grossStabilizedYield', [parseEventValue(e), localFilters.grossStabilizedYield?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.grossStabilizedYield?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('grossStabilizedYield', [localFilters.grossStabilizedYield?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.grossStabilizedYield?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            {
              (channel !== CHANNEL_NEW_BUILD) && (
                <FilterSubSection label="Asking Cap Rate">
                  <div className="flex justify-between items-center mb-16">
                    <Input
                      onChange={(e) => updateLocalFilter('goingInCapRate', [parseEventValue(e), localFilters.goingInCapRate?.[1]])}
                      placeholder="No min"
                      type="percent"
                      value={localFilters.goingInCapRate?.[0]}
                      width="w-2/5"
                    />
                    &mdash;
                    <Input
                      onChange={(e) => updateLocalFilter('goingInCapRate', [localFilters.goingInCapRate?.[0], parseEventValue(e)])}
                      placeholder="No max"
                      type="percent"
                      value={localFilters.goingInCapRate?.[1]}
                      width="w-2/5"
                    />
                  </div>
                </FilterSubSection>
              )
            }
            <FilterSubSection label="Unlevered Cash Yield">
              <div className="flex justify-between items-center">
                <Input
                  onChange={(e) => updateLocalFilter('unleveredCashOnCashRate', [parseEventValue(e), localFilters.unleveredCashOnCashRate?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.unleveredCashOnCashRate?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('unleveredCashOnCashRate', [localFilters.unleveredCashOnCashRate?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.unleveredCashOnCashRate?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
            <FilterSubSection label="Levered Cash Yield">
              <div className="flex justify-between items-center mb-16">
                <Input
                  onChange={(e) => updateLocalFilter('leveredCashOnCashRate', [parseEventValue(e), localFilters.leveredCashOnCashRate?.[1]])}
                  placeholder="No min"
                  type="percent"
                  value={localFilters.leveredCashOnCashRate?.[0]}
                  width="w-2/5"
                />
                &mdash;
                <Input
                  onChange={(e) => updateLocalFilter('leveredCashOnCashRate', [localFilters.leveredCashOnCashRate?.[0], parseEventValue(e)])}
                  placeholder="No max"
                  type="percent"
                  value={localFilters.leveredCashOnCashRate?.[1]}
                  width="w-2/5"
                />
              </div>
            </FilterSubSection>
          </FilterSection>
        )}
      </div>
    </div>
  );
}
