import { chunk, last, partial, sum } from 'lodash';
import { differenceInDays } from 'date-fns';

export const annualizeMonthlyReturns = (monthlyReturns, holdPeriodInMonths, mapCallback = sum) => {
  if (holdPeriodInMonths < 12) {
    return [monthlyReturns.slice(0, 12), monthlyReturns.slice(holdPeriodInMonths)].map(mapCallback);
  } else {
    return chunk(monthlyReturns, 12).map(mapCallback);
  }
};

export const expandToMonthlyArray = (annualValues) => annualValues.flatMap((value) => new Array(12).fill(value));

export const compoundInterest = (initialValue, rates) => {
  return rates.reduce((output, rate) => {
    output.push(last(output) * (1 + rate));
    return output;
  }, [initialValue]);
};

export const pmt = (monthlyRate, months, presentValue) => {
  const temp = (1 + monthlyRate) ** months;
  const fact = (temp - 1) / monthlyRate;
  return (presentValue * temp) / fact;
};

export const pv = (monthlyRate, periods, paymentPerPeriod) => (paymentPerPeriod * (1 - ((1 + monthlyRate) ** -periods))) / monthlyRate;

export const cashOnCash = (totalCashFlow, operatingCashFlow) => {
  let basis = -1 * totalCashFlow[0];
  return operatingCashFlow.map((ocf, index) => {
    // increase cash-on-cash basis for any equity inputs
    if (totalCashFlow[index + 1] < 0) basis += (-1 * totalCashFlow[index + 1]);
    return ocf > 0 ? ocf / basis : 0;
  });
};

export const equityMultiple = (cashFlows) => {
  return -1 * sum(cashFlows.filter(cf => cf > 0)) / sum(cashFlows.filter(cf => cf < 0));
};

const MAX_ITER = 100;
export const newtonRaphson = (f, guess, tolerance, h) => {
  let x1, y, yp, yph, ymh, yp2h, ym2h;

  const hr = 1 / h;
  let iteration = 0;
  while (iteration++ < MAX_ITER) {
    // compute initial value of function using guess
    y = f(guess);

    // numerically compute first-derivate at point of guess
    yph = f(guess + h);
    ymh = f(guess - h);
    yp2h = f(guess + 2 * h);
    ym2h = f(guess - 2 * h);

    yp = ((ym2h - yp2h) + 8 * (yph - ymh)) * hr / 12;

    // updated guess based on first-derivative calculation
    x1 = guess - y / yp;

    // return if guess is within tolerance
    if (Math.abs(x1 - guess) <= tolerance) {
      return x1;
    }

    // update guess for next loop if it has not conerged
    guess = x1;
  }

  throw 'Newton-Raphson: Did not converge';
};

const npv = (cashFlows, cashFlowDates, discount) => {
  const d0 = cashFlowDates[0];
  return cashFlows.reduce((total, cashFlow, index) => {
    const d = cashFlowDates[index];
    const dDiff = differenceInDays(d, d0);
    return total + (cashFlow / ((1 + discount) ** (dDiff / 365)));
  }, 0);
};

const IRR_GUESS = 0.01;
const IRR_TOLERANCE = 1e-12;
const IRR_H = 1e-4;
export const irr = (cashFlows, cashFlowDates) => {
  const guess = sum(cashFlows) >= 0 ? IRR_GUESS : IRR_GUESS * -1;
  return newtonRaphson(partial(npv, cashFlows, cashFlowDates), guess, IRR_TOLERANCE, IRR_H);
};

export const calcMonthlyGrowthRates = (annualGrowthRates, holdPeriodInMonths) => {
  if (holdPeriodInMonths <= 13) {
    const yr1Rates = new Array(12).fill((1 + annualGrowthRates[0]) ** (1 / 12) - 1);
    return yr1Rates.concat(new Array(holdPeriodInMonths).fill((1 + annualGrowthRates[1]) ** (1 / 12) - 1));
  } else {
    return annualGrowthRates.flatMap(rate => new Array(12).fill((1 + rate) ** (1 / 12) - 1));
  }
};

export const calcMonthlyCashFlows = (value, growthRates, holdPeriodInMonths) => {
  if (holdPeriodInMonths < 12) {
    return expandToMonthlyArray(compoundInterest(value, growthRates)).slice(12 - holdPeriodInMonths);
  } else {
    return expandToMonthlyArray(compoundInterest(value, growthRates));
  }
};

export const calcStabilizedYields = (args) => {
  const {
    netOperatingIncome,
    unleveredBasis,
    stabilizationMonth,
    totalMarketRent,
    grossPotentialRents,
  } = args;
  const stablizedUnleveredBasisAmount = unleveredBasis.endingBasis[stabilizationMonth - 1];
  const stabilizedYieldCash = netOperatingIncome.slice(stabilizationMonth - 1, stabilizationMonth + 11);
  const stabilizedYield = sum(stabilizedYieldCash) / stablizedUnleveredBasisAmount;
  const currentStabilizedYield = totalMarketRent / stablizedUnleveredBasisAmount;
  const grossStabilizedYield = (grossPotentialRents[stabilizationMonth - 1] * 12) / stablizedUnleveredBasisAmount;
  return {
    currentStabilizedYield,
    grossStabilizedYield,
    stabilizedYield,
  };
};
