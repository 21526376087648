/* eslint-disable no-nested-ternary */
import { isValid, parseISO } from 'date-fns';
import { isEmpty, isNil, isNumber, isString, some } from 'lodash';
import { UNIT_RENTS_SOURCES } from 'components/constants';
import GoogleMapUtils from 'components/common/GoogleMap/utils';
import { normalizeStringForSearch } from 'components/utils';

// Generic filter function for determining if a row is inside the given shape
const isInsideShape = (row, drawing, pointExtractor) => {
  const { overlay, type } = drawing;

  const point = pointExtractor(row);
  switch (type) {
    case 'circle':
      return row.getValue('isSubject') || GoogleMapUtils.isInsideCircle(point, overlay);
    case 'polygon':
      return row.getValue('isSubject') || GoogleMapUtils.isInsidePolygon(point, overlay);
    case 'rectangle':
      return row.getValue('isSubject') || GoogleMapUtils.isInsideRectangle(point, overlay);
    default:
      console.error(`Drawing type ${type} unknown`);
      return false;
  }
};

export const mapFilter = (pointExtractor, row, drawings = []) => {
  if (isEmpty(drawings)) return true;
  return some(drawings, drawing => isInsideShape(row, drawing, pointExtractor));
};

const rentPointExtractor = row => ({
  lat: Number(row.original.latitude || row.original.property.latitude),
  lng: Number(row.original.longitude || row.original.property.longitude),
});

const salePointExtractor = row => ({
  lat: Number(row.original.latitude || row.original.comp.data.attributes.latitude),
  lng: Number(row.original.longitude || row.original.comp.data.attributes.longitude),
});

export const rentMapFilter = (row, _, filterValue) => mapFilter(rentPointExtractor, row, filterValue);

export const saleMapFilter = (row, _, filterValue) => mapFilter(salePointExtractor, row, filterValue);

export const statusFilter = (row, _, filterValue) => row.getValue('isSubject') || isEmpty(filterValue) || filterValue.includes(row.getValue('resolvedStatus'));

export const zipCodeFilter = (row, _, zipCodeString) => {
  // if zipCodeString is null or empty string - no filter applied
  if (!zipCodeString) return true;

  const zipCodes = zipCodeString.split(',').map(code => code.trim());

  if (!isEmpty(zipCodes)) {
    const { getValue, original } = row;
    const value = original.property?.zipCode;
    const filterResult = getValue('isSubject') || (!isNil(value) && zipCodes.includes(value));
    return filterResult;
  }
  return true;
};

export const sourceNameFilter = (row, _, sourceNames) => {
  const [zillow, mls, other] = UNIT_RENTS_SOURCES;

  if (!isEmpty(sourceNames)) {
    const { getValue, original: { listingId } } = row;
    if (getValue('isSubject')) return true;

    const itemSourceName = getValue('sourceName') === zillow ? zillow : (listingId ? mls : other);
    return sourceNames.includes(itemSourceName);
  }

  return true;
};

export const dateRangeFilter = (row, columnId, dateRange) => {
  const { getValue } = row;

  const rowValue = getValue(columnId);
  const filterableValue = (rowValue && isString(rowValue)) ? parseISO(rowValue) : rowValue;
  if (!filterableValue || !isValid(filterableValue)) {
    return false;
  }

  if (dateRange[0] && (filterableValue < dateRange[0])) {
    return false;
  }

  if (dateRange[1] && (filterableValue > dateRange[1])) {
    return false;
  }

  return true;
};

export const landUseFilter = (row, _, landUseCodes) => {
  if (!isEmpty(landUseCodes)) {
    const { getValue } = row;
    if (getValue('isSubject')) {
      return true;
    }
    const landUse = getValue('landUse');
    if (landUseCodes.includes('SF') && (landUse === 'SF')) {
      return true;
    }
    if (landUseCodes.includes('MF') && (landUse !== 'SF')) {
      return true;
    }
    return false;
  }

  return true;
};

export const visibilityFilter = (selectedIdsObj, row, _, visibilityStatus) => {
  const hideSelected = visibilityStatus[0][1];
  const hideUnselected = visibilityStatus[1][1];

  if (!hideSelected && !hideUnselected) return true;

  const { getValue, original: { id } } = row;
  const isSubject = getValue('isSubject');
  const isSelected = selectedIdsObj[id];

  if (hideSelected && hideUnselected) return isSubject;

  if (hideSelected) {
    return isSubject || !isSelected;
  }

  if (hideUnselected) {
    const filterResult = isSubject || isSelected;
    // make sure it is a boolean because returning undefined will not trigger filter
    return !!filterResult;
  }

  return false;
};

export const bedBathFilter = (row, columnId, [useExact, ...filterRange]) => {
  const { getValue } = row;

  const compFunc = {
    true: (quantity, filter) => filter.includes(quantity),
    false: (quantity, [filter]) => quantity >= filter,
  };

  let rowCount;
  if (['numberOfBedrooms', 'bedroomsTotal', 'numberOfBathroomsTotal', 'bathroomsTotalInteger'].includes(columnId)) {
    rowCount = getValue(columnId);
  } else {
    throw new Error(`Incompatible columnId for bedBath filter: ${columnId}`);
  }

  if (filterRange.length) {
    const filterResult = (isNumber(rowCount) && compFunc[useExact](rowCount, filterRange));
    return filterResult;
  }

  return true;
};

/** @type {import('@tanstack/react-table').FilterFn} */
export const valueRangeRangeFilter = (row, columnId, filterValue) => {
  if (!filterValue?.length) {
    return true;
  }

  const [valueMin, valueMax] = row.getValue(columnId) ?? [];
  if (isNil(valueMin) || isNil(valueMax)) {
    return false;
  }

  const filterMin = filterValue[0] ?? -Infinity;
  const filterMax = filterValue[1] ?? Infinity;
  return (valueMin >= filterMin && valueMin <= filterMax) || (valueMax >= filterMin && valueMax <= filterMax);
};

/** @type {import('@tanstack/react-table').FilterFn} */
export const rangeFilter = (row, columnId, filterValue) => {
  if (!filterValue?.length) {
    return true;
  }

  const value = row.getValue(columnId);
  if (isNil(value)) {
    return false;
  }

  const filterMin = filterValue[0] ?? -Infinity;
  const filterMax = filterValue[1] ?? Infinity;
  return value >= filterMin && value <= filterMax;
};

const rowTermsCache = new WeakMap();
const cachedColumnIds = new WeakMap();

/**
 * Functions similarly to the built-in includesString filter but allows the search term to span multiple columns.
 *
 * The filter value is split into whitespace-delimited terms after collapsing spaces and removing punctuations.
 * The value of each column is similarly split into terms.
 * A row is considered a match if all search terms in the filterValue can be
 *  1. found in one of the columns' terms
 *  2. or, is a substring of one of the columns' terms
 *
 * @type {import('@tanstack/react-table').FilterFn}
 */
export const multiColumnGlobalFilter = Object.assign((row, columnId, filterValue) => {
  let rowTerms = rowTermsCache.get(row);
  if (rowTerms === undefined) {
    rowTerms = new Set();
    rowTermsCache.set(row, rowTerms);
    cachedColumnIds.set(row, new Set());
  }

  if (!cachedColumnIds.get(row).has(columnId)) {
    normalizeStringForSearch(row.getValue(columnId)?.toString?.())
      .split(' ')
      .forEach((term) => {
        rowTerms.add(term);
      });

    cachedColumnIds.get(row).add(columnId);
  }

  const rowTermsArr = Array.from(rowTerms);
  return normalizeStringForSearch(filterValue)
    .split(' ')
    .every((filterTerm) => (
      rowTerms.has(filterTerm) || rowTermsArr.some((term) => term.includes(filterTerm))
    ));
}, {
  autoRemove: (value) => value === undefined || value === null || value === '',
});
