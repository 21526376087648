import { startOfDay } from 'date-fns';
import { noop } from 'lodash';
import { Link } from 'react-router-dom';
import { useFetchOffMarketMarketplaceDashboardStatisticsQuery } from 'redux/offMarketMarketplaceApiSlice';
import { DashboardCard } from 'components/NhimbleDashboard/Shared/ReturnMetricBoxes';
import EmptyLoadingState from 'components/shared/EmptyLoadingState';
import { dateFromString } from 'components/utils';
import { objectSerializer } from 'hooks/useSyncSearchParam';

const statusFilter = (status) => `filter_status="${encodeURIComponent(status)}"`;
const transactionStatusFilter = (status) => `filter_transactionStatus="${encodeURIComponent(status)}"`;
const createdAtFilter = (start, end) => `filter_createdAt=${encodeURIComponent(objectSerializer([start ?? null, end ?? null]))}`;
const sentToBuyerAtFilter = (start, end) => `filter_sentToBuyerAt=${encodeURIComponent(objectSerializer([start ?? null, end ?? null]))}`;
const expirationDateFilter = (start, end) => `filter_expirationDate=${encodeURIComponent(objectSerializer([start ?? null, end ?? null]))}`;

function SectionHeader({ label }) {
  return <h2 className="text-xl text-gray-700">{label}</h2>;
}

function Section({ label, children }) {
  return (
    <>
      <SectionHeader label={label} />
      <div className="py-8">
        {children}
      </div>
    </>
  );
}

export default function OffMarketDashboard() {
  const { data, isLoading } = useFetchOffMarketMarketplaceDashboardStatisticsQuery();

  if (isLoading) {
    return (
      <div className="w-full h-screen">
        <EmptyLoadingState />
      </div>
    );
  }

  const {
    all: {
      activeListings,
      activeOffers,
      closedListings,
      expiringWithin24,
      inPreInspection,
      inContract,
      offersNeedResponse,
      sentToBuyers,
      oneDayFromNow,
      today,
    },
    recent: { newListings, expiredListings, offers, sentToBuyers: sentToBuyersRecently, startsAt },
  } = data;
  const todayDate = dateFromString(today);
  const recentStartsAtDate = dateFromString(startsAt);
  const oneDayFromNowDate = dateFromString(oneDayFromNow);

  const linkClassName = 'after:absolute after:inset-0';

  return (
    <div className="py-12 px-8 flex flex-col h-container w-container overflow-y-auto">
      <h1 className="mb-12 text-3xl text-gray-700">Marketplace Dashboard</h1>
      <Section label="Recent Activity - Past Week">
        <div className="flex gap-x-6">
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${createdAtFilter(recentStartsAtDate)}`} className={linkClassName}>New Listings</Link>} value={newListings} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${expirationDateFilter(startOfDay(recentStartsAtDate), startOfDay(todayDate))}`} className={linkClassName}>Expired Listings</Link>} value={expiredListings} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to="/off_market_marketplace/offers" className={linkClassName}>Offers</Link>} value={offers} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${sentToBuyerAtFilter(recentStartsAtDate)}`} className={linkClassName}>Sent to Buyers</Link>} value={sentToBuyersRecently} width="w-60 relative" onClick={noop} />
        </div>
      </Section>
      <Section label="Inventory">
        <div className="flex gap-x-6">
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${statusFilter('Active')}`} className={linkClassName}>Active Listings</Link>} value={activeListings} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${expirationDateFilter(startOfDay(todayDate), startOfDay(oneDayFromNowDate))}`} className={linkClassName}>Expiring in Next Day</Link>} value={expiringWithin24} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${transactionStatusFilter('Closed')}`} className={linkClassName}>Closed Listings</Link>} value={closedListings} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to={`/off_market_marketplace/inventory?${sentToBuyerAtFilter()}`} className={linkClassName}>Sent to Buyers</Link>} value={sentToBuyers} width="w-60 relative" onClick={noop} />
        </div>
      </Section>
      <Section label="Offers">
        <div className="flex gap-x-6">
          <DashboardCard label={<Link to="/off_market_marketplace/offers" className={linkClassName}>Active Offers</Link>} value={activeOffers} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to="/off_market_marketplace/offers" className={linkClassName}>Offers Need Response</Link>} value={offersNeedResponse} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to="/off_market_marketplace/inventory" className={linkClassName}>In Pre-Inspection</Link>} value={inPreInspection} width="w-60 relative" onClick={noop} />
          <DashboardCard label={<Link to="/off_market_marketplace/offers" className={linkClassName}>In Contract</Link>} value={inContract} width="w-60 relative" onClick={noop} />
        </div>
      </Section>
    </div>
  );
}
