import { useMemo } from 'react';
import ChipGroup from 'components/shared/ChipGroup';
import AllFiltersChip from 'components/shared/newfilters/AllFiltersChip';
import FilterChip from 'components/shared/newfilters/FilterChip';
import ReactTableColumnFilter from 'components/shared/newfilters/ReactTableColumnFilter';
import ReactTableColumnUniqueValuesComboboxFilter from 'components/shared/newfilters/ReactTableColumnUniqueValuesComboboxFilter';
import ReactTableFilter from 'components/shared/newfilters/ReactTableFilters';
import RangeFilter from 'components/shared/newfilters/RangeFilter';
import useRangeDisplayValue from 'components/shared/newfilters/useRangeDisplayValue';
import { formatDate } from 'components/utils';
import { useFilterId } from 'components/shared/newfilters/filterHooks';
import { useCoreRowModel } from 'components/shared/Table/DataTableContext';

function UploadedByLabel({ id }) {
  const filterId = useFilterId();
  const { flatRows } = useCoreRowModel();

  const row = useMemo(() => (
    flatRows.find((r) => r.getValue(filterId) === id)
  ), [filterId, flatRows, id]);
  return flatRows.length ? (row?.getValue('uploadedBy') ?? id) : '—';
}

const uploadedByDisplayValue = (id) => (
  <UploadedByLabel id={id} />
);

export default function OffMarketInventoryFilters({ currentUser }) {
  const dateRangeDisplayValue = useRangeDisplayValue({ formatter: formatDate });
  const isBroker = currentUser.settings.offMarketBroker;

  return (
    <ReactTableFilter>
      <ChipGroup>
        <ReactTableColumnFilter
          label="Updated At"
          columnId="updatedAt"
          displayValue={dateRangeDisplayValue}
        >
          <FilterChip>
            <RangeFilter type="date" />
          </FilterChip>
        </ReactTableColumnFilter>
        {isBroker && (
          <ReactTableColumnFilter
            label="Uploaded By"
            columnId="marketplaceOrganization_id"
            displayValue={uploadedByDisplayValue}
          >
            <FilterChip>
              <ReactTableColumnUniqueValuesComboboxFilter labelBy="uploadedBy" />
            </FilterChip>
          </ReactTableColumnFilter>
        )}
        <ReactTableColumnFilter
          label="Market"
          columnId="market"
        >
          <FilterChip>
            <ReactTableColumnUniqueValuesComboboxFilter />
          </FilterChip>
        </ReactTableColumnFilter>
        <ReactTableColumnFilter
          label="Listing Status"
          columnId="status"
        >
          <FilterChip>
            <ReactTableColumnUniqueValuesComboboxFilter />
          </FilterChip>
        </ReactTableColumnFilter>
        <ReactTableColumnFilter
          label="Transaction Status"
          columnId="transactionStatus"
        >
          <FilterChip>
            <ReactTableColumnUniqueValuesComboboxFilter />
          </FilterChip>
        </ReactTableColumnFilter>
        <AllFiltersChip />
      </ChipGroup>
    </ReactTableFilter>
  );
}
