import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty, isNumber, uniqBy } from 'lodash';
import {
  useConfirmDealImportJobMutation,
  useFetchDealImportJobQuery,
  useRemovePropertyMutation,
} from 'redux/dealImportJobApiSlice';
import { Check, LoadingIndicator, X } from 'components/icons';
import { dealPath, portfolioPath } from 'components/routes';
import DataTable from 'components/shared/Table/DataTable';
import { dataTableMeta } from 'components/shared/Table/table.helpers';
import Button from 'components/shared/NewButton';
import Modal from 'components/Modal';
import { DEAL_IMPORT_JOB_STATUS } from 'components/constants';
import { formatCurrency, formatInteger } from 'components/utils'
import { PARCEL_MATCHING_NEW_BUILD } from 'components/Import/Deal/SettingsForm';

const {
  STATUS_COMPLETE,
  STATUS_ERROR,
  STATUS_IMPORTING,
  STATUS_MATCHED,
  STATUS_VALIDATING,
} = DEAL_IMPORT_JOB_STATUS;

const POLLING_FREQUENCY = 5_000;

const getImportStatus = (dealImportJob) => {
  if (!dealImportJob) return;

  const { importData: { csvData }, resultData, validationData } = dealImportJob;
  const { matchResults } = validationData;

  if (!isEmpty(resultData)) {
    // TODO: check if we can update incrementally when importing individual deals
    const { importError, results } = resultData;
    if (importError) {
      return STATUS_ERROR;
    }
    return (results && !isEmpty(results)) ? STATUS_COMPLETE : STATUS_IMPORTING;
  }

  return (matchResults?.length === csvData.length) ? STATUS_MATCHED : STATUS_VALIDATING;
};

function ParcelMatchCell({ column, getValue }) {
  const { columnDef: { meta: { matchedCount } } } = column;
  if (!isNumber(matchedCount)) {
    return <LoadingIndicator className="text-gray-500 size-5" />;
  }

  return getValue() ? <Check className="text-success-500 size-6" /> : <X className="text-error-500 size-6" />;
}

function ParcelApnCell({ getValue }) {
  const parcel = getValue();
  if (!parcel) return;
  return (
    <div>
      <div>{parcel.apn}</div>
      <div className="text-sm text-gray-500">{parcel.county}</div>
    </div>
  );
}

function RemoveCell({ column, getValue, row: { original } }) {
  const { parcel } = original;
  if (parcel) return null;
  const rowIndex = getValue();
  const { columnDef: { meta: { matchedCount, setRemoveRowIndex } } } = column;
  if (!matchedCount) {
    // do not give remove option if addresses have not been matched yet
    return null;
  }

  return (
    <div className="py-1.5">
      <Button filled small danger label="✕" onClick={() => setRemoveRowIndex(rowIndex)} />
    </div>
  );
}

const NEW_BUILD_COLUMNS = [{
  id: 'community',
  header: 'Community',
  accessorKey: 'community',
}, {
  id: 'homeModel',
  header: 'Home Model',
  accessorKey: 'homeModel',
}];

const ADDRESS_COLUMNS = [{
  id: 'address',
  header: 'Address',
  accessorKey: 'address',
}, {
  id: 'city',
  header: 'City',
  accessorKey: 'city',
}, {
  id: 'state',
  header: 'State',
  accessorKey: 'state',
}, {
  id: 'zip',
  header: 'Zip',
  accessorKey: 'zip',
}];

const PROPERTY_COLUMNS = [{
  id: 'bedrooms',
  header: 'Bedrooms',
  accessorKey: 'bedrooms',
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'bathrooms',
  header: 'Bathrooms',
  accessorKey: 'bathrooms',
  meta: { ...dataTableMeta.textRight },
}, {
  id: 'sqFt',
  header: 'Sq Ft',
  accessorKey: 'sqFt',
  cell: ({ getValue }) => formatInteger(getValue()),
  meta: { ...dataTableMeta.textRight },
}];

const LIST_PRICE_COLUMN = {
  id: 'listPrice',
  header: 'List Price',
  accessorKey: 'listPrice',
  cell: ({ getValue }) => formatCurrency(getValue()),
};

const parcelMatchColumns = (matchedCount, setRemoveRowIndex) => [{
  id: 'parcelMatchYn',
  header: 'Match',
  accessorFn: ({ parcel }) => !!parcel,
  cell: ParcelMatchCell,
  meta: { matchedCount },
}, {
  id: 'parcelMatchApn',
  header: 'APN',
  accessorKey: 'parcel',
  cell: ParcelApnCell,
}, {
  header: '',
  id: 'remove',
  accessorKey: 'id',
  cell: RemoveCell,
  meta: { setRemoveRowIndex },
}];

const PARCEL_MATCH_TABLE_INITIAL_STATE = {};

const navigateBack = (navigate, location, defaultPath) => {
  const canGoBack = location.key !== 'default';
  if (canGoBack) {
    navigate(-1);
  } else {
    navigate(defaultPath);
  }
};

function ImportActions({ className, onBack, onConfirm, isConfirming, isMatching }) {
  return (
    <div className={className}>
      <Button
        outlined
        label="Back"
        onClick={onBack}
      />
      <Button
        filled
        label={isConfirming ? 'Importing' : 'Confirm'}
        isLoading={isConfirming || isMatching}
        onClick={onConfirm}
      />
    </div>
  );
}

function RemoveRowModal({ dealImportJobId, row, rowIndex, onClose }) {
  const [removeProperty, { isLoading }] = useRemovePropertyMutation();
  const { address, city, state, zip } = row;
  const oneLineAddress = `${address}, ${city}, ${state} ${zip}`;
  const onRemove = async () => {
    await removeProperty({ id: dealImportJobId, rowIndex });
    onClose();
  };
  return (
    <Modal
      show
      showCloseAction
      onClose={onClose}
      title={`Remove Property: ${row.address}`}
      width="w-128"
    >
      <p className="my-8">{`Removing the property will result in ${oneLineAddress} not being included in the created deal.`}</p>
      <div className="w-full flex items-center justify-around">
        <Button textOnly label="Cancel" onClick={onClose} />
        <Button filled label="Remove" onClick={onRemove} isLoading={isLoading} />
      </div>
    </Modal>
  );
}

const getColumns = (parcelMatching, matchedCount, setRemoveRowIndex) => {
  if (!parcelMatching) return [];
  if (parcelMatching === PARCEL_MATCHING_NEW_BUILD) {
    return [
      ...NEW_BUILD_COLUMNS,
      ...ADDRESS_COLUMNS,
      LIST_PRICE_COLUMN,
      ...PROPERTY_COLUMNS,
    ];
  } else {
    return [
      ...ADDRESS_COLUMNS,
      ...parcelMatchColumns(matchedCount, setRemoveRowIndex),
    ];
  }
};

function ParcelMatchHeader({ matchedCount, tableData }) {
  if (isNumber(matchedCount)) {
    return (
      <div className="flex items-center">
        <div className="flex items-center gap-x-1.5">
          <Check className="text-success-500 size-6" />
          <div>{matchedCount}</div>
        </div>
        <div className="ml-3 flex items-center gap-x-1.5">
          <X className="text-error-500 size-6" />
          <div>{tableData.length - matchedCount}</div>
        </div>
        <div className="ml-3 text-sm text-gray-500">{`of ${tableData?.length} properties`}</div>
      </div>
    );
  } else {
    return (
      <div className="flex items-center gap-x-2">
        <LoadingIndicator className="text-gray-500 size-4" />
        <div className="text-gray-500">Matching addresses...</div>
      </div>
    );
  }
}

function NewBuildHeader({ tableData }) {
  return (
    <div className="flex gap-x-3 items-center">
      <div>{`${uniqBy(tableData, 'community').length} Communities`}</div>
      <div>·</div>
      <div>{`${uniqBy(tableData, 'homeModel').length} Home Models`}</div>
      <div>·</div>
      <div>{`${tableData.length} Properties`}</div>
    </div>
  );
}

function StatusHeader({ matchedCount, parcelMatching, tableData }) {
  if (parcelMatching === PARCEL_MATCHING_NEW_BUILD) {
    return <NewBuildHeader tableData={tableData} />;
  } else {
    return <ParcelMatchHeader matchedCount={matchedCount} tableData={tableData} />;
  }
}

export default function StatusPage() {
  const [pollingInterval, setPollingInterval] = useState();
  const [removeRowIndex, setRemoveRowIndex] = useState();
  const navigate = useNavigate();
  const { dealImportJobId } = useParams();

  const { data: dealImportJob, isLoading: isFetching } = useFetchDealImportJobQuery(dealImportJobId, { pollingInterval });

  const importStatus = getImportStatus(dealImportJob);

  useEffect(() => {
    if (importStatus === STATUS_COMPLETE) {
      if (dealImportJob.resultData.results.length === 1) {
        // single deal created, so navigate to that deal page
        const newDeal = dealImportJob.resultData.results[0];
        navigate(dealPath(newDeal));
      } else {
        navigate(portfolioPath({ id: dealImportJob.importData.settings.portfolioId }));
      }
    } else if (!importStatus || [STATUS_ERROR, STATUS_MATCHED].includes(importStatus)) {
      setPollingInterval(null);
    } else {
      setPollingInterval(POLLING_FREQUENCY);
    }
  }, [importStatus]);

  const csvData = dealImportJob?.importData?.csvData;
  const matchResults = dealImportJob?.validationData?.matchResults;
  const location = useLocation();

  const [confirmDealImportJobMutation] = useConfirmDealImportJobMutation();
  const onBack = () => navigateBack(navigate, location, '/import/import_deals');
  const onConfirm = () => confirmDealImportJobMutation(dealImportJob);

  const tableData = useMemo(() => {
    if (matchResults) {
      return matchResults.map(([inputCsvData, parcel, property], index) => ({
        ...inputCsvData,
        id: index,
        parcel,
        property,
      }));
    } else {
      return csvData?.map((d, index) => ({ ...d, id: index }));
    }
  }, [csvData, matchResults]);

  const matchedCount = useMemo(() => matchResults?.filter(([, parcel]) => parcel)?.length, [matchResults]);
  const isConfirming = importStatus === STATUS_IMPORTING;
  const isMatching = importStatus === STATUS_VALIDATING;

  const parcelMatching = dealImportJob?.importData?.settings?.parcelMatching;
  const columns = useMemo(() => getColumns(parcelMatching, matchedCount, setRemoveRowIndex), [matchedCount, parcelMatching]);

  return (
    <div className="py-12 px-8 flex flex-col h-container w-container">
      <h1 className="mb-12 text-2xl text-gray-700">
        Import Deals
      </h1>
      <div className="p-6 bg-white flex justify-between items-center">
        <StatusHeader
          parcelMatching={parcelMatching}
          matchedCount={matchedCount}
          tableData={tableData}
        />
        <ImportActions
          className="flex justify-center items-center gap-x-6"
          onBack={onBack}
          onConfirm={onConfirm}
          isConfirming={isConfirming}
          isMatching={isMatching}
        />
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        initialState={PARCEL_MATCH_TABLE_INITIAL_STATE}
        isLoading={isFetching}
      />
      <ImportActions
        className="pt-12 p-6 flex justify-center items-center gap-x-12 bg-white"
        onBack={onBack}
        onConfirm={onConfirm}
        isConfirming={isConfirming}
        isMatching={isMatching}
      />
      {removeRowIndex && (
        <RemoveRowModal
          dealImportJobId={dealImportJob.id}
          row={csvData[removeRowIndex]}
          rowIndex={removeRowIndex}
          onClose={() => setRemoveRowIndex(null)}
        />
      )}
    </div>
  );
}
