import { isPlainObject } from '@reduxjs/toolkit';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePreviousValue from './usePreviousValue';

const currentVersion = '';
const versionSeparator = '\0';

const undefinedSymbol = Symbol('');

const serializers = Object.freeze({
  undefined: {
    serialize: () => '',
    // cannot directly return 'undefined', as it will be removed
    deserialize: () => undefinedSymbol,
  },
  number: {
    serialize: (value) => value.toString(),
    deserialize: (value) => Number(value),
  },
  object: {
    serialize: (value) => Object.entries(value),
    deserialize: (value) => Object.fromEntries(value),
  },
  date: {
    serialize: (value) => value.valueOf(),
    deserialize: (value) => new Date(value),
  },
  map: {
    serialize: (value) => Array.from(value.entries()),
    deserialize: (value) => new Map(value),
  },
  set: {
    serialize: (value) => Array.from(value.values()),
    deserialize: (value) => new Set(value),
  },
});

const canDirectlySerialize = (value, type) => {
  if (value === undefined) {
    return false;
  }

  if (value === null) {
    return true;
  }

  if (type === 'number') {
    return Number.isFinite(value);
  } else if (type === 'object') {
    return Array.isArray(value);
  } else if (type === 'string' || type === 'boolean') {
    return true;
  }

  return false;
};

/**
 * Serialize values to JSON string but supports some types JSON doesn't support
 * e.g. undefined, Date, NaN, Infinity
 */
export const objectSerializer = (value) => {
  if (value === undefined) {
    return '';
  }

  let isJsonSerializable = true;
  let containsObject = false;

  const serializedValue = JSON.stringify(value, function replacer(key) {
    const v = this[key];

    let type = typeof v;
    if (canDirectlySerialize(v, type)) {
      return v;
    }

    if (type === 'object') {
      containsObject = true;

      if (!isPlainObject(v)) {
        isJsonSerializable = false;

        if (v instanceof Date) {
          type = 'date';
        } else if (v instanceof Map) {
          type = 'map';
        } else if (v instanceof Set) {
          type = 'set';
        } else {
          throw new Error('Unsupported object type');
        }
      }
    } else {
      isJsonSerializable = false;
    }

    const { [type]: { serialize } = {} } = serializers;
    if (serialize === undefined) {
      throw new Error(`Unsupported type ${type}`);
    }

    return { [type]: serialize(v) };
  });

  if (isJsonSerializable) {
    // use plain JSON if the value is JSON serializable
    return containsObject ? JSON.stringify(value) : serializedValue;
  }

  return `${currentVersion}${versionSeparator}${serializedValue}`;
};

/**
 * Deserialize string values serialized by objectSerializer
 */
export const objectDeserializer = (serializedValue) => {
  if (serializedValue === '') {
    return undefined;
  }

  const [version, jsonStr] = serializedValue.split(versionSeparator, 2);
  if (jsonStr === undefined) {
    // version separator is missing, assume it's a plain JSON string
    return JSON.parse(version);
  }

  if (version !== currentVersion) {
    throw new Error(`Unsupported version ${version}`);
  }

  return JSON.parse(jsonStr, (key, value) => {
    if (value === null || typeof value !== 'object') {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map((item) => (item === undefinedSymbol ? undefined : item));
    }

    const [[type, val] = []] = Object.entries(value);
    const { [type]: { deserialize } = {} } = serializers;
    if (deserialize === undefined) {
      throw new Error(`Unsupported type ${type}`);
    }

    return deserialize(val);
  });
};

const useAddLast = (setState) => (
  useCallback((updater) => {
    setState((prev) => [...prev, updater]);
  }, [setState])
);

export const useQueuedSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queuedUpdates, setQueuedUpdates] = useState([]);
  const enqueueSearchParamsUpdate = useAddLast(setQueuedUpdates);

  useLayoutEffect(() => {
    if (!queuedUpdates.length) {
      return;
    }

    setSearchParams((prev) => {
      const updated = queuedUpdates.reduce((agg, updater) => (
        typeof updater === 'function' ? updater(agg) : updater
      ), prev);
      updated.sort();

      return updated;
    }, { replace: true });
    setQueuedUpdates([]);
  }, [queuedUpdates, setSearchParams]);

  return useMemo(() => (
    [searchParams, enqueueSearchParamsUpdate]
  ), [searchParams, enqueueSearchParamsUpdate]);
};
/**
 * @template T
 *
 * @param {string} key
 * @param {T} value
 * @param {T} defaultValue
 * @param {function(T): string | null} [serialize]
 * @param {function(string): T} [deserialize]
 * @param {function(T): void} onChange
 * @param {function(function(URLSearchParams): URLSearchParams): void} [onSearchParamsChange]
 */
const useSyncSearchParam = ({
  key,
  value,
  defaultValue,
  serialize = objectSerializer,
  deserialize = objectDeserializer,
  onChange,
  onSearchParamsChange,
}) => {
  const [searchParams, defaultSetSearchParams] = useQueuedSearchParams();
  const serializedParamValue = searchParams.get(key);
  const serializedValue = useMemo(() => serialize(value) ?? null, [serialize, value]);
  const serializedDefaultValue = useMemo(() => serialize(defaultValue) ?? null, [serialize, defaultValue]);

  useEffect(() => {
    if (serializedParamValue !== null) {
      const failedDeserialization = {};
      let deserialized = failedDeserialization;
      try {
        deserialized = deserialize(serializedParamValue);
      } catch (e) {
        console.error(e);
      }

      if (deserialized !== failedDeserialization) {
        onChange(deserialized);
      }
    }
  }, [deserialize, onChange, serializedParamValue]);

  // check if the value has changed to avoid updating the search params
  // while the search params is being applied to the local state
  const hasSerializedValueChanged = usePreviousValue(serializedValue, serializedValue) !== serializedValue;

  // update search params in url when all the following are true:
  // 1. serialized local value is different from that of the previous render cycle
  // 2. serialized local value and url value differ
  // 3. this param key is present in the url, OR the local value is different from the default value
  const canUpdateSearchParam = hasSerializedValueChanged && (serializedParamValue !== serializedValue) && (serializedParamValue !== null || serializedValue !== serializedDefaultValue);

  const setSearchParams = onSearchParamsChange ?? defaultSetSearchParams;
  useLayoutEffect(() => {
    if (canUpdateSearchParam) {
      setSearchParams((prev) => {
        const updated = new URLSearchParams(prev);
        if (typeof serializedValue === 'string') {
          updated.set(key, serializedValue);
        } else {
          updated.delete(key);
        }

        return updated;
      });
    }
  }, [canUpdateSearchParam, key, serializedValue, setSearchParams]);

  return serializedParamValue;
};

export default useSyncSearchParam;
