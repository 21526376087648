import { useCallback, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { Dialog } from '@headlessui/react';
import cx from 'classnames';
import { isEmpty, noop } from 'lodash';
import { useLazyFetchGoogleDriveFileListQuery } from 'redux/listingImagesApiSlice';
import { Check, Warning, X } from 'components/icons';
import { fileSize, parseEventValue } from 'components/utils';
import FileUploadArea from 'components/FileUploadArea';
import Alert from 'components/Alert';
import Button from 'components/shared/NewButton';
import { FormField } from 'components/Form';

function DragAndDropUpload({ accept, isLoading, onUpload }) {
  const [files, setFiles] = useState([]);
  const onFileUpload = useCallback((newFiles) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      newFiles
        // use name, size, and mtime as a heuristic to check for duplicated files
        .filter((newFile) => !updatedFiles.some(([otherFile]) => (
          newFile.name === otherFile.name
          && newFile.size === otherFile.size
          && newFile.lastModified === otherFile.lastModified
        )))
        .forEach((file) => updatedFiles.push([file, nanoid()]));

      return updatedFiles;
    });
  }, []);

  const onFileRemove = useCallback((evt) => {
    const idx = parseInt(evt.currentTarget.value, 10);
    setFiles((prevFiles) => prevFiles.toSpliced(idx, 1));
  }, []);

  const uploadOnClick = () => onUpload?.(false, files.map(([file]) => file));
  return (
    <>
      <div className="flex flex-col gap-y-3 pb-px h-0 flex-1 overflow-auto" inert={isLoading ? '' : undefined}>
        {files.map(([file, fileId], index) => (
          <div key={fileId} className="w-full flex justify-between items-center gap-x-2 text-sm">
            <div className="flex-grow truncate max-w-prose" title={file.name}>{file.name}</div>
            <div className="text-gray-500">{fileSize(file.size)}</div>
            <button type="button" value={index} onClick={onFileRemove} className="focus:outline-none">
              <X className="w-5 text-red-500 hover:text-red-400" />
            </button>
          </div>
        ))}
        <FileUploadArea accept={accept} onFileUpload={onFileUpload} />
      </div>

      <div className="flex flex-row gap-x-2 justify-end pt-6 border-t mt-auto">
        <Button filled label="Upload" isLoading={isLoading} disabled={!files.length} onClick={uploadOnClick} />
      </div>
    </>
  );
}

function GoogleDriveUrlInput({ onClose, setGoogleDriveFiles }) {
  const [alert, setAlert] = useState(null);
  const [driveUrl, setDriveUrl] = useState('');
  const [trigger] = useLazyFetchGoogleDriveFileListQuery()
  const [isLoading, setIsLoading] = useState(false);

  const fetchFiles = async (driveUrlValue) => {
    setIsLoading(true);
    const urlParts = driveUrlValue.split('/');
    const foldersPathIndex = urlParts.indexOf('folders');
    if ((foldersPathIndex > -1) && urlParts.length > foldersPathIndex) {
      const folderId = urlParts[foldersPathIndex + 1].split('?')[0];
      const response = await trigger(folderId);

      setIsLoading(false);
      if (response.isError) {
        setAlert({
          type: 'danger',
          text: 'Failed to get list of files from Google Drive folder',
        });
        console.error(response);
      } else {
        const files = response.data;
        if (isEmpty(files)) {
          setAlert({
            type: 'warning',
            text: 'No files in the provided Google Drive folder',
          });
        } else {
          setGoogleDriveFiles(files);
        }
      }
    } else {
      setIsLoading(false);
      setAlert({
        type: 'warning',
        text: 'Google Drive URL is not valid. Make sure URL is of format:\nhttps://drive.google.com/drive/u/1/folders/{folderId}',
      });
    }
  };

  return (
    <>
      <p className="w-120">Enter the URL of the Google Drive directory where the image files are located. Note the directory must be public in order for the files to be imported.</p>
      <FormField
        name="driveUrl"
        value={driveUrl}
        type="text"
        className="mt-6"
        onChange={(e) => { setAlert(null); setDriveUrl(parseEventValue(e)); }}
      />
      {alert && <Alert className="mt-6 whitespace-pre" {...alert} />}
      <div className="mt-6 flex gap-x-2 justify-end">
        <Button
          textOnly
          label="Cancel"
          onClick={onClose}
        />
        <Button
          filled
          isLoading={isLoading}
          disabled={!driveUrl.length}
          label="Verify URL"
          onClick={() => fetchFiles(driveUrl)}
        />
      </div>
    </>
  );
}

function GoogleDriveFileItem({ file }) {
  return (
    <li className={cx('flex gap-x-3 items-center', { 'cursor-disabled': !file.isImage })}>
      <div className={cx(!file.isImage && 'text-gray-500')}>{file.name}</div>
      {file.isImage ? (
        <Check className="text-green-500 size-6" />
      ) : (
        <div className="flex items-center">
          <Warning className="text-gray-300 size-5 mr-2" />
          <div className="text-gray-400 text-sm">Not an image file</div>
        </div>
      )}
    </li>
  );
}

function GoogleDriveImageUpload({ googleDriveFiles, onClose, onUpload, setGoogleDriveFiles }) {
  return (
    <>
      <div className="w-120 my-6">
        <p className="mb-6">{`${googleDriveFiles.filter(f => f.isImage).length} images will be imported`}</p>
        <ul className="max-h-60 overflow-y-scroll flex flex-col gap-y-1">
          {googleDriveFiles.map(f => <GoogleDriveFileItem key={f.id} file={f} />)}
        </ul>
        <p className="mt-6 text-sm italic">Images will be downloaded in the background so it may take a few minutes before they are reflected in the inventory. You may continue to use the platform as usual during this time.</p>
      </div>
      <div className="mt-6 flex gap-x-2 justify-end">
        <Button
          textOnly
          label="Back"
          onClick={() => setGoogleDriveFiles(null)}
        />
        <Button
          filled
          label="Import Photos"
          onClick={() => onUpload(true, googleDriveFiles, onClose)}
        />
      </div>
    </>
  );
}

function GoogleDriveImport({ onClose, onUpload }) {
  const [googleDriveFiles, setGoogleDriveFiles] = useState(null);

  return googleDriveFiles ? (
    <GoogleDriveImageUpload
      googleDriveFiles={googleDriveFiles}
      onClose={onClose}
      onUpload={onUpload}
      setGoogleDriveFiles={setGoogleDriveFiles}
    />
  ) : (
    <GoogleDriveUrlInput onClose={onClose} setGoogleDriveFiles={setGoogleDriveFiles} />
  );
}

export default function FileUploadModel({ supportImportFromGoogleDrive, toggleModal, isLoading, onUpload, title, accept }) {
  const [googleDriveImport, setGoogleDriveImport] = useState(false);
  const initialFocusRef = useRef(null);

  return (
    <Dialog open onClose={isLoading ? noop : toggleModal} initialFocus={initialFocusRef}>
      <div className="fixed inset-0 z-50 bg-black/25" />
      {/*
        Initially focusing on this wrapper div to avoid the Button being automatically
        focused due to default Dialog behavior
        ref: https://headlessui.com/v1/react/dialog#managing-initial-focus
      */}
      <div ref={initialFocusRef} className="fixed inset-0 z-50 p-4 content-center overflow-clip">
        <Dialog.Panel className="relative flex flex-col h-max max-h-full w-max max-w-full rounded-2xl mx-auto py-6 *:px-6 bg-white">
          <div className="sticky inset-x-0 top-0 flex justify-between items-center pb-6">
            <Dialog.Title className="text-xl">{title}</Dialog.Title>
            {supportImportFromGoogleDrive && (
              <Button
                small
                textOnly
                label={googleDriveImport ? 'Import via File Upload' : 'Import via Google Drive'}
                onClick={() => setGoogleDriveImport(!googleDriveImport)}
              />
            )}
          </div>
          {googleDriveImport ? (
            <GoogleDriveImport
              isLoading={isLoading}
              onClose={toggleModal}
              onUpload={onUpload}
            />
          ) : (
            <DragAndDropUpload
              accept={accept}
              isLoading={isLoading}
              onUpload={onUpload}
            />
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
