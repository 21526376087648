import DataTableConfigPane from 'components/Import/NewBuild/DataTableConfigPane';
import SideNavPaneContainer from 'components/Import/NewBuild/SideNavPaneContainer';
import InventoryAttachmentsNavItem from 'components/inventory/InventoryAttachmentsNavItem';
import InventoryPhotosNavItem from 'components/inventory/InventoryPhotosNavItem';
import InventoryPhotosPane from 'components/inventory/InventoryPhotosPane';
import InventorySideNav, { SideNavRail } from 'components/inventory/InventorySideNav';
import OffMarketAttachmentsPane from 'components/OffMarketMarketplace/OffMarketAttachmentsPane';
import OffMarketUnderwritingPane from 'components/OffMarketMarketplace/OffMarketUnderwritingPane';
import DataTableConfig from 'components/shared/Table/dataTableConfig/DataTableConfig';
import { DataTableContent } from 'components/shared/Table/DataTableContext';
import { useState } from 'react';
import {
  ApplyLinkedFilters,
  ApplyLinkedGlobalFilter,
  ApplyLinkedSorting,
  OpenLinkedPanel,
  SelectLinkedRow,
} from 'components/inventory/InventoryDeepLinking';
import {
  useCreateListingImagesMutation,
  useDeleteListingImageMutation,
  useFetchListingImagesQuery,
  useInvalidateListingImages,
  useUpdateListingImagesMutation,
} from '../../redux/listingImagesApiSlice';
import ListingNotesNavItem from './ListingNotesNavItem';
import ListingNotesPane from './ListingNotesPane';
import UnderwritingNavItem from './UnderwritingNavItem';
import OffMarketInventoryTable from './OffMarketInventoryTable';
import OffMarketInventoryTableHeader from './OffMarketInventoryTableHeader';

export default function OffMarketInventory({ currentUser }) {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <div className="flex flex-col h-container w-container">
      <OffMarketInventoryTable tableContainerClassName="h-max max-h-container" isEditMode={isEditMode}>
        <OffMarketInventoryTableHeader
          currentUser={currentUser}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
        <div className="flex flex-row flex-1 [container-type:size] rounded-l-xl overflow-clip">
          <DataTableContent />
          <InventorySideNav>
            <SideNavPaneContainer>
              <OffMarketUnderwritingPane />
              <ListingNotesPane />
              <InventoryPhotosPane
                supportImportFromGoogleDrive
                useFetchInventoryImagesQuery={useFetchListingImagesQuery}
                useUpdateInventoryImagesMutation={useUpdateListingImagesMutation}
                useCreateInventoryImagesMutation={useCreateListingImagesMutation}
                useDeleteInventoryImageMutation={useDeleteListingImageMutation}
                useInvalidateInventoryImages={useInvalidateListingImages}
              />
              <OffMarketAttachmentsPane />
              <DataTableConfig tableId="offMarketMarketplaceInventoryListings">
                <DataTableConfigPane />
              </DataTableConfig>
            </SideNavPaneContainer>
            <SideNavRail page="offMarket">
              <UnderwritingNavItem />
              <ListingNotesNavItem />
              <InventoryPhotosNavItem />
              <InventoryAttachmentsNavItem />
            </SideNavRail>

            <OpenLinkedPanel />
          </InventorySideNav>
        </div>

        <SelectLinkedRow />
        <ApplyLinkedFilters />
        <ApplyLinkedSorting />
        <ApplyLinkedGlobalFilter />
      </OffMarketInventoryTable>
    </div>
  );
}
