import { useState, useCallback } from 'react';
import Alert from 'components/Alert';
import RoundButton from 'components/common/RoundButton';
import { Plus, X } from 'components/icons';
import { parseEventValue, titleCase } from 'components/utils';
import Button from 'components/shared/NewButton';
import { noop } from 'lodash';
import { FormField } from 'components/Form';

const OTHER_LOAN_TYPE = 'other';

const LOAN_TYPE_OPTIONS = [
  ['select_loan_type', 'Select Loan Type', true],
  ['fha', 'FHA'],
  ['va', 'VA'],
  ['usda', 'USDA'],
  ['conventional', 'Conventional'],
  ['cash', 'Cash'],
  ['other', 'Other'],
];

const STATUS_OPTIONS = [
  ['received', 'Received'],
  ['presented_to_seller', 'Presented to Seller'],
  ['seller_counter', 'Seller Counter'],
  ['buyer_counter', 'Buyer Counter'],
  ['rejected', 'Rejected'],
  ['accepted', 'Accepted'],
  ['terminated', 'Terminated'],
  ['re_opened', 'Re-opened'],
];

function AttachmentsInput({ attachments, setAttachments }) {
  const handleAddAttachments = useCallback(
    (e) => {
      const files = Array.from(e.target.files);
      setAttachments((prev) => [...prev, ...files]);
    },
    [setAttachments],
  );

  const handleRemoveAttachment = useCallback(
    (index) => {
      setAttachments((prev) => prev.filter((_, i) => i !== index));
    },
    [setAttachments],
  );

  return (
    <div className="flex flex-col gap-y-3">
      <label htmlFor="attachments" className="text-xs font-medium cursor-pointer">
        Attachments
      </label>
      <div className="flex items-center gap-2">
        <input type="file" name="attachments" id="attachments" multiple className="hidden" onChange={handleAddAttachments} />
        <label htmlFor="attachments" className="bg-blue-500 text-white px-4 py-2 rounded cursor-pointer flex items-center gap-2">
          <Plus className="w-4 h-4" />
          Add Files
        </label>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {attachments.map((file, index) => (
          <div key={index} className="flex items-center bg-gray-100 border border-gray-300 p-2 rounded">
            <span className="text-sm mr-2">{file.name}</span>
            <button type="button" className="text-red-500 hover:text-red-700" onClick={() => handleRemoveAttachment(index)} aria-label={`Remove ${file.name}`}>
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

function OfferForm({
  alert,
  includeAttachments = false,
  isLoading,
  offerData,
  onClose = noop,
  onSubmit,
  setAlert,
  setOfferData,
  showCloseAction = false,
  title = '',
  hideBuyerDetails = false,
}) {
  const [attachments, setAttachments] = useState([]);

  const handleOnChange = useCallback(
    (event) => {
      const { name, type, files } = event.target;
      const newValue = type === 'file' ? files : parseEventValue(event);
      setOfferData((prevData) => ({ ...prevData, [name]: newValue }));
    },
    [setOfferData],
  );

  const handleSubmit = useCallback(async () => {
    setAlert(null);

    const requiredFields = [
      'appraisalContingencyDays',
      'buyerAgentEmail',
      'closeOfEscrowDate',
      'concession',
      'earnestMoneyDeposit',
      'financingContingencyDays',
      'loanType',
      'price',
    ];

    // Dynamically add fields based on status
    if (offerData.status === 'rejected') {
      requiredFields.push('rejectionReason');
    } else if (offerData.status === 'terminated') {
      requiredFields.push('terminationReason');
    }

    if (offerData.loanType === OTHER_LOAN_TYPE && !offerData.otherLoanType) {
      requiredFields.push('otherLoanType');
    }

    const missingFields = requiredFields.filter((field) => offerData[field] === undefined || offerData[field] === null || offerData[field] === '');
    if (missingFields.length > 0) {
      setAlert({
        type: 'danger',
        text: `Please fill in all the required fields: ${missingFields.map((f) => titleCase(f)).join(', ')}`,
      });
      return;
    }

    try {
      await onSubmit({ ...offerData, attachments });
    } catch (error) {
      console.error('Failed to save offer:', error);
      setAlert({
        type: 'danger',
        text: 'An error occurred while saving the offer. Please try again.',
      });
    }
  }, [offerData, onSubmit, setAlert, attachments]);

  return (
    <div className="w-full bg-white p-4 overflow-y-auto z-50 h-auto">
      {showCloseAction && (
        <div className="flex justify-between items-center border-b border-gray-300 pb-4">
          <h2 className="text-xl">{title}</h2>
          <RoundButton padding="h-7 w-7 border border-neutral-dark border-opacity-12" onClick={onClose} aria-label="Close Offer Form">
            <X className="h-4 w-4 mx-auto" />
          </RoundButton>
        </div>
      )}

      <div className="flex flex-col space-y-4">
        {offerData.id && (
          <FormField type="select" name="status" label="Offer Status" value={offerData.status} options={STATUS_OPTIONS} disabled={hideBuyerDetails} onChange={handleOnChange} />
        )}

        {/* Conditional Fields Based on Status */}
        {offerData.status === 'rejected' && <FormField type="text-area" name="rejectionReason" label="Rejection Reason" value={offerData.rejectionReason} onChange={handleOnChange} />}
        {offerData.status === 'terminated' && <FormField type="text-area" name="terminationReason" label="Termination Reason" value={offerData.terminationReason} onChange={handleOnChange} />}

        {/* Buyer Details */}
        {!hideBuyerDetails && (
          <>
            <FormField type="text" name="buyerAgentName" label="Buyer Agent Name" value={offerData.buyerAgentName} onChange={handleOnChange} />
            <FormField type="text" name="buyerAgentEmail" label="Buyer Agent Email" value={offerData.buyerAgentEmail} onChange={handleOnChange} />
          </>
        )}

        {/* Attachments */}
        {includeAttachments && <AttachmentsInput attachments={attachments} setAttachments={setAttachments} />}

        <FormField type="currency" name="price" label="Offer Price" value={offerData.price} onChange={handleOnChange} />
        <FormField type="date" name="closeOfEscrowDate" label="Close of Escrow Date" value={offerData.closeOfEscrowDate} onChange={handleOnChange} />
        <FormField type="currency" name="earnestMoneyDeposit" label="Earnest Money Deposit" value={offerData.earnestMoneyDeposit} onChange={handleOnChange} />
        <FormField type="currency" name="concession" label="Concession" value={offerData.concession} onChange={handleOnChange} />
        <FormField type="number" name="financingContingencyDays" label="Financing Contingency Days" value={offerData.financingContingencyDays} onChange={handleOnChange} />
        <FormField type="number" name="appraisalContingencyDays" label="Appraisal Contingency Days" value={offerData.appraisalContingencyDays} onChange={handleOnChange} />
        <FormField type="select" name="loanType" label="Loan Type" value={offerData.loanType || 'select_loan_type'} options={LOAN_TYPE_OPTIONS} onChange={handleOnChange} className="mt-6" />
        {offerData.loanType === OTHER_LOAN_TYPE && <FormField type="text" name="otherLoanType" label="Other Loan Type" value={offerData.otherLoanType} onChange={handleOnChange} />}
        <FormField type="text" name="otherNotes" label="Other Notes" value={offerData.otherNotes} onChange={handleOnChange} />

        {alert && <Alert className="mb-6 py-3" {...alert} />}
        <Button filled label={offerData?.id ? 'Save Changes' : 'Submit Offer'} className="mx-auto items-center justify-center" onClick={handleSubmit} isLoading={isLoading} />
      </div>
    </div>
  );
}

export default OfferForm;
