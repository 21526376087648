import cx from 'classnames';
import { useMemo, useState } from 'react';
import Map from 'components/property/components/Map';
import MapModal from 'components/property/components/MapModal';
import Badge from 'components/shared/Badge';

const defaultMapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  cameraControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
  zoom: 12,
  minZoom: 7,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
  },
};

export default function SubdivisionMap({ latitude, longitude, className }) {
  const [showMapModal, setShowMapModal] = useState(false);
  const properties = useMemo(() => [{ latitude, longitude }], [latitude, longitude]);

  return (
    <div className={cx('relative rounded-xl overflow-clip', className)}>
      <Map properties={properties} options={defaultMapOptions} />
      <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal}>
        <Map properties={properties} />
      </MapModal>
      <Badge
        visible
        label="Click to expand"
        className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg"
        onClick={() => setShowMapModal(true)}
      />
    </div>
  );
}
