import { apiSlice } from 'redux/apiSlice';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { DEAL_CONTEXT_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchSensitivities: builder.query({
        query(dealId) {
          return `/api/deals/${dealId}/sensitivities`;
        },
        // do not camelCase response because it messes up the JSON-formatted keys
        transformResponse: response => response,
      }),
      refreshSensitivities: builder.mutation({
        query: (dealId) => ({
          url: `/api/deals/${dealId}/refresh_sensitivities`,
          method: 'POST',
        }),
        transformResponse: response => camelCaseKeys(response),
      }),
      updateScenarios: builder.mutation({
        query: ({ dealId, scenarios }) => ({
          url: `/api/deals/${dealId}/update_scenarios`,
          method: 'PUT',
          body: snakeCaseKeys({ scenarios }),
        }),
        transformResponse: response => camelCaseKeys(response),
        invalidatesTags: [DEAL_CONTEXT_TAG, 'Model'],
      }),
    };
  },
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useFetchSensitivitiesQuery,
  useRefreshSensitivitiesMutation,
  useUpdateScenariosMutation,
} = extendedApiSlice;
