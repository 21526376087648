import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { listingImagesApiPath } from 'components/routes';
import { camelCaseKeys, snakeCaseKeys } from 'components/utils';
import { apiSlice } from './apiSlice';
import { LISTING_IMAGES_TAG } from './utils';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      fetchGoogleDriveFileList: builder.query({
        query: folderId => `/api/listing_images/list_google_drive_images?folder_id=${folderId}`,
        transformResponse: response => camelCaseKeys(response),
      }),
      importGoogleDriveImages: builder.mutation({
        query: ({ fileIds, listingId }) => ({
          url: generatePath('/api/listings/:listingId/images/import_google_drive_images', { listingId }),
          method: 'POST',
          body: snakeCaseKeys({ fileIds }),
        }),
        invalidatesTags: [LISTING_IMAGES_TAG],
      }),
      fetchListingImages: builder.query({
        query: ({ id }) => generatePath(listingImagesApiPath, { listingId: id }),
        transformResponse: response => camelCaseKeys(response),
        providesTags: [LISTING_IMAGES_TAG],
      }),
      createListingImages: builder.mutation({
        query: ({ id, files }) => {
          const formData = new FormData();
          files.forEach((file, index) => formData.append(`files[${index}]`, file));

          return ({
            url: generatePath(listingImagesApiPath, { listingId: id }),
            method: 'POST',
            body: formData,
          });
        },
        invalidatesTags: [LISTING_IMAGES_TAG],
      }),
      updateListingImages: builder.mutation({
        query: ({ orderedIds, id }) => ({
          url: generatePath(listingImagesApiPath, { listingId: id }),
          method: 'PUT',
          body: snakeCaseKeys({ listingImageIds: orderedIds }),
        }),
        invalidatesTags: [LISTING_IMAGES_TAG],
      }),
      deleteListingImage: builder.mutation({
        query: ({ id, listingId }) => ({
          url: generatePath(listingImagesApiPath, { id, listingId }),
          method: 'DELETE',
        }),
        invalidatesTags: [LISTING_IMAGES_TAG],
      }),
    };
  },
});

export const useInvalidateListingImages = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(extendedApiSlice.util.invalidateTags([LISTING_IMAGES_TAG]));
  }, [dispatch]);
};

export const {
  useLazyFetchGoogleDriveFileListQuery,
  useImportGoogleDriveImagesMutation,
  useFetchListingImagesQuery,
  useCreateListingImagesMutation,
  useUpdateListingImagesMutation,
  useDeleteListingImageMutation,
} = extendedApiSlice;
