import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Chevron } from 'components/icons';
import { HorizontalTabs, LinkTab } from 'components/shared/Tabs';
import { PropertyBreadcrumb } from 'components/DealBase/Navigation';

function NavBottom({ propertyManagementRecordId }) {
  const location = useLocation();

  // TODO: typicaly NavLink active functionality doesn't work due to nested routes
  const isRentComps = location.pathname.includes('rent_comps');
  const isSaleComps = location.pathname.includes('sale_comps');
  const isProperty = !isRentComps && !isSaleComps;

  const basePath = `/asset_management/${propertyManagementRecordId}`;

  return (
    <HorizontalTabs>
      <LinkTab to={`${basePath}/overview`} active={isProperty}>Property</LinkTab>
      <LinkTab to={`${basePath}/rent_comps`} active={isRentComps}>Rent Comps</LinkTab>
      <LinkTab to={`${basePath}/sale_comps`} active={isSaleComps}>Sale Comps</LinkTab>
    </HorizontalTabs>
  );
}

export default function Header({ property, propertyManagementRecord }) {
  const linkTo = `/asset_management/${propertyManagementRecord?.id}`;
  return (
    <div className="w-full top-0 left-0 flex flex-col bg-white border-b">
      <div className="flex justify-between items-center pt-3 px-8">
        <div className={cx('flex gap-x-2 items-center', { 'h-14': !propertyManagementRecord })}>
          <Link to="/asset_management" className="text-lg cursor-pointer">Asset Management</Link>
          {propertyManagementRecord && (
            <>
              <Chevron direction="right" className="w-4 text-gray-500" />
              <PropertyBreadcrumb linkTo={linkTo} property={property} />
            </>
          )}
        </div>
      </div>
      {propertyManagementRecord && (
        <NavBottom propertyManagementRecordId={propertyManagementRecord.id} />
      )}
    </div>
  );
}
