import { createColumnHelper } from '@tanstack/react-table';
import cx from 'classnames';
import {
  CompRentCell,
  CurrencyCell,
  EnumCell,
  InputCell,
  IntegerCell,
  PercentCell,
  RouteLinkCell,
  SelectInputCell,
  ZeroWidthCell,
  ZonedDatetimeCell,
} from 'components/shared/Table/Cells';
import DataTable from 'components/shared/Table/DataTable';
import { dateRangeFilter, multiColumnGlobalFilter } from 'components/shared/Table/table.filterFns';
import { dataTableMeta, enableEditing, selectRowOnClick, tableConfigMeta } from 'components/shared/Table/table.helpers';
import { formatDate, parseEventValue } from 'components/utils';
import { difference, isNil } from 'lodash';
import { useFetchSelfQuery } from '../../redux/apiSlice';
import { useFetchOffMarketMarketplaceInventoryQuery } from '../../redux/offMarketMarketplaceApiSlice';
import DataValidationCell from './DataValidationCell';
import DeleteListingCell from './DeleteListingCell';
import ListingStatusCell from './ListingStatusCell';
import MlsStatusCell from './MlsStatusCell';
import { rowIsIncomplete, rowIsNotIncomplete } from './offMarketMarketplace';
import PropertyMatchesBuyBoxCell from './PropertyMatchesBuyBoxCell';
import TruncatedWithTooltipCell from './TruncatedWithTooltipCell';
import ReturnsMatchBuyBoxCell from './ReturnsMatchBuyBoxCell';

/** @type {import('@tanstack/react-table').TableOptions['defaultColumn']} */
export const INVENTORY_DEFAULT_COLUMN = {
  sortUndefined: 'last',
  // only enable global filter on address related columns
  enableGlobalFilter: false,
};

const LISTING_STATUS_OPTIONS = Object.freeze(['Active', 'Expired', 'Withdrawn'].map(v => ([v, v])));

const columnHelper = createColumnHelper();

const hiddenColumns = [
  columnHelper.accessor('marketplaceOrganization.id', { filterFn: 'equals', meta: { ...dataTableMeta.disableTableConfig } }),
];

const orgNameColumn = columnHelper.accessor('marketplaceOrganization.name', {
  id: 'uploadedBy',
  header: 'Uploaded By',
  enableColumnFilter: false,
});

const offMarketBrokerColumns = [
  columnHelper.accessor('status', {
    id: 'deleteIncompleteListing',
    header: null,
    cell: ZeroWidthCell,
    enableSorting: false,
    enableColumnFilter: false,
    meta: {
      thClassName: 'sr-only',
      ...enableEditing({
        when: rowIsIncomplete,
        cell: DeleteListingCell,
      }),
      ...tableConfigMeta({ visibility: false, order: 'hidden' }),
    },
  }),
  columnHelper.display({
    id: 'listingLink',
    cell: RouteLinkCell,
    meta: {
      linkPath: '/listings/:id',
      ...tableConfigMeta({ visibility: false, order: 'hidden' }),
    },
  }),

  orgNameColumn,
  columnHelper.accessor('unparsedAddress', { header: 'Address', enableGlobalFilter: true }),
  columnHelper.accessor('market', { header: 'Market', filterFn: 'equals', enableGlobalFilter: true }),
  columnHelper.accessor('city', { header: 'City', enableGlobalFilter: true }),
  columnHelper.accessor('stateOrProvince', { header: 'State', enableGlobalFilter: true }),
  columnHelper.accessor('postalCode', {
    header: 'Zip',
    enableGlobalFilter: true,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('dataValidation', {
    header: 'Data Validation',
    cell: DataValidationCell,
  }),
  columnHelper.accessor('buyBoxMatchResults', {
    id: 'propertyBuyBox',
    header: 'Property Buy Box',
    cell: PropertyMatchesBuyBoxCell,
  }),
  columnHelper.accessor('buyBoxMatchResults', {
    id: 'returnsBuyBox',
    header: 'Returns Buy Box',
    cell: ReturnsMatchBuyBoxCell,
  }),
  columnHelper.accessor('status', {
    header: 'Listing Status',
    cell: ListingStatusCell,
    filterFn: 'equals',
    meta: {
      ...enableEditing({
        when: rowIsNotIncomplete,
        cell: SelectInputCell,
      }),
      options: LISTING_STATUS_OPTIONS,
    },
  }),
  columnHelper.accessor('sentToBuyerAt', {
    header: 'Sent to Buyers',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
    filterFn: dateRangeFilter,
  }),
  columnHelper.accessor('transactionStatus', {
    header: 'Transaction Status',
    filterFn: 'equals',
  }),
  columnHelper.accessor('offers', {
    header: 'Total Offers',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('activeOffers', {
    header: 'Active Offers',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('photosCount', {
    header: 'Photos',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('attachmentsCount', {
    header: 'Attachments',
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('mlsListings', {
    header: 'MLS Listing',
    cell: MlsStatusCell,
  }),
  columnHelper.accessor('expirationDate', {
    header: 'Listing Expiration Date',
    cell: ({ getValue }) => formatDate(getValue(), 'MMM d, yyyy'),
    meta: { ...enableEditing({ cell: InputCell, inputType: 'date' }) },
    filterFn: dateRangeFilter,
  }),
  columnHelper.accessor('preInspectionAvailableYn', {
    header: 'Pre-Inspection Available',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { [true]: 'Yes', [false]: 'No' },
      enumFallbackValue: '',
      ...enableEditing({ cell: SelectInputCell }),
      options: [['', '-'], ['true', 'Yes'], ['false', 'No']],
      editEventValueParser: (event) => {
        const parsedEventValue = parseEventValue(event);
        return isNil(parsedEventValue) ? '' : (parsedEventValue === 'true');
      },
    },
  }),
  columnHelper.accessor('aToBUploaded', {
    header: 'A to B Contract Uploaded',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { true: 'Yes', false: 'No' },
      enumFallbackValue: '',
    },
  }),
  columnHelper.accessor(row => row.avm?.estimatedValueAmount, {
    header: 'AVM',
    cell: CurrencyCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('price', {
    header: 'Price',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
    },
  }),
  columnHelper.accessor('compRent', {
    header: 'Market Rent',
    cell: CompRentCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('netYield', {
    header: 'Net Yield',
    cell: PercentCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('grossYield', {
    header: 'Gross Yield',
    cell: PercentCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('bedroomsTotal', {
    header: 'Bed',
    cell: IntegerCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('bathroomsFull', {
    header: 'Full Bath',
    cell: IntegerCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('bathroomsHalf', {
    header: '1/2 Bath',
    cell: IntegerCell,
    meta: {
      thClassName: 'diagonal-fractions *:diagonal-fractions',
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('livingArea', {
    header: 'Sqft',
    cell: IntegerCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10000 }),
    },
  }),
  columnHelper.accessor('stories', {
    header: 'Stories',
    cell: IntegerCell,
    meta: { ...dataTableMeta.textRight },
  }),
  columnHelper.accessor('poolPrivateYn', {
    header: 'Private Pool',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { true: 'Yes', false: 'No' },
      enumFallbackValue: '',
      ...enableEditing({ cell: SelectInputCell }),
      options: [[true, 'Yes'], [false, 'No'], ['', '-']],
    },
  }),
  columnHelper.accessor('garageSpaces', {
    header: 'Garage',
    cell: IntegerCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 10 }),
    },
  }),
  columnHelper.accessor('septicYn', {
    header: 'Septic',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { true: 'Yes', false: 'No' },
      enumFallbackValue: '',
      ...enableEditing({ cell: SelectInputCell }),
      options: [[true, 'Yes'], [false, 'No'], ['', '-']],
    },
  }),
  columnHelper.accessor('associationFee', {
    header: 'Annual HOA',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
    },
  }),
  columnHelper.accessor('lotSizeSquareFeet', {
    header: 'Lot Sqft',
    cell: IntegerCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 0, max: 100000 }),
    },
  }),
  columnHelper.accessor('yearBuilt', {
    header: 'Year Built',
    sortDescFirst: true,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'number', min: 1800, max: new Date().getFullYear() }),
    },
  }),
  columnHelper.accessor('propertyType', { header: 'Property Type' }),
  columnHelper.accessor('renovationEstimate', {
    header: 'Renovation Estimate',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
    },
  }),
  columnHelper.accessor('occupantType', {
    header: 'Occupancy',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { owner: 'Owner', tenant: 'Tenant', vacant: 'Vacant' },
      enumFallbackValue: '',
      ...enableEditing({ cell: SelectInputCell }),
      options: [['', '-'], ['owner', 'Owner'], ['tenant', 'Tenant'], ['vacant', 'Vacant']],
    },
  }),
  columnHelper.accessor('leaseExpiration', {
    header: 'Lease Expiration Date',
    cell: ({ getValue }) => formatDate(getValue(), 'MMM d, yyyy'),
    meta: { ...enableEditing({ cell: InputCell, inputType: 'date' }) },
  }),
  columnHelper.accessor('leaseAmount', {
    header: 'Lease Amount',
    cell: CurrencyCell,
    meta: {
      ...dataTableMeta.textRight,
      ...enableEditing({ cell: InputCell, inputType: 'currency' }),
    },
  }),
  columnHelper.accessor('section8Yn', {
    header: 'Section 8',
    cell: EnumCell,
    meta: {
      enumDisplayValues: { true: 'Yes', false: 'No' },
      enumFallbackValue: '',
      ...enableEditing({ cell: SelectInputCell }),
      options: [[true, 'Yes'], [false, 'No'], ['', '-']],
    },
  }),
  columnHelper.accessor('publicRemarks', {
    header: 'Public Remarks',
    cell: TruncatedWithTooltipCell,
    enableColumnFilter: false,
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
  columnHelper.accessor('listingPrivateRemarks', {
    header: 'Private Remarks',
    cell: TruncatedWithTooltipCell,
    enableColumnFilter: false,
    meta: {
      className: 'max-w-60 truncate text-ellipsis',
      ...enableEditing({ cell: InputCell, inputType: 'text-area', rows: 3 }),
    },
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
    filterFn: dateRangeFilter,
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated At',
    cell: ZonedDatetimeCell,
    sortDescFirst: true,
    meta: {
      datetimeFormat: 'MMM d, yyyy (zzz)',
      // use user's timezone
      datetimeTimeZone: null,
      ...dataTableMeta.textRight,
    },
    filterFn: dateRangeFilter,
  }),
  ...hiddenColumns,
];

const allColumnsSet = new Set(offMarketBrokerColumns);
const brokerOnlyColumnsSet = new Set([orgNameColumn]);
const offMarketImporterColumns = difference(Array.from(allColumnsSet), Array.from(brokerOnlyColumnsSet));

/** @type {import('@tanstack/react-table').InitialTableState} */
const tableInitialState = {
  columnVisibility: {
    marketplaceOrganization_id: false,
  },
  sorting: [
    { id: 'updatedAt', desc: true },
  ],
};

/**
 * @param {string} tableContainerClassName
 * @param {import('react').ReactNode} children
 */
export default function OffMarketInventoryTable({ isEditMode, tableContainerClassName, children }) {
  const { currentData, isUninitialized, isFetching } = useFetchOffMarketMarketplaceInventoryQuery();
  const { currentData: { settings: { offMarketBroker } = {} } = {}, isFetching: isFetchingSelf } = useFetchSelfQuery();

  return (
    <DataTable
      virtual
      columns={offMarketBroker === true ? offMarketBrokerColumns : offMarketImporterColumns}
      data={currentData}
      initialState={tableInitialState}
      defaultColumn={INVENTORY_DEFAULT_COLUMN}
      isLoading={(isUninitialized || isFetching || isFetchingSelf) && !currentData}
      tableContainerClassName={cx('whitespace-pre [&_td:not(:last-of-type)]:w-0 [&_th:not(:last-of-type)]:w-0', tableContainerClassName)}
      onRowClick={selectRowOnClick}
      enableMultiRowSelection={false}
      enableEditing={isEditMode}
      globalFilterFn={multiColumnGlobalFilter}
    >
      {children}
    </DataTable>
  );
}
