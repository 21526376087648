import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';
import Alert from 'components/Alert';
import { FormField } from 'components/Form';
import Modal from 'components/Modal';
import { Map } from 'components/icons';
import Button from 'components/shared/NewButton';
import { parseEventValue } from 'components/utils';
import { useDeleteSaleCompSetMutation, useUpdateSaleCompSetMutation } from 'redux/apiSlice';
import { CURRENT_COMP_SET, setActiveSaleCompSetId } from 'redux/saleCompsSlice';
import SaleCompSetSelector from './SaleCompSetSelector';
import ShortSummary from './Summary';

function EditSaleCompSetModal({ saleCompSets, setShowEditModal }) {
  const { activeSaleCompSetId, selectedSaleCompIds } = useSelector(state => state.saleComps);
  const [updateSaleCompSetMutation, { isLoading: isUpdating }] = useUpdateSaleCompSetMutation();
  const [name, setName] = useState('');
  const [compPrice, setCompPrice] = useState(null);
  const [alert, setAlert] = useState(null);
  const activeSaleCompSet = saleCompSets.find(({ id }) => id === activeSaleCompSetId);

  useEffect(() => {
    setName(activeSaleCompSet?.name);
    setCompPrice(activeSaleCompSet?.compPrice);
  }, [activeSaleCompSet]);

  const handleEdit = async () => {
    setAlert(null);
    await updateSaleCompSetMutation({
      id: activeSaleCompSetId,
      name,
      compPrice,
      selectedIds: selectedSaleCompIds,
    }).unwrap().catch(() => setAlert({ type: 'danger', text: 'Unable to update the sale comp set' }));
    setShowEditModal(false);
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="mt-4 w-90 h-auto">
        <div className="text-2xl font-normal">Edit Comp Set</div>
        <FormField
          name="name"
          label="Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-6"
        />
        <FormField
          name="compPrice"
          type="currency"
          value={compPrice}
          onChange={(e) => setCompPrice(parseEventValue(e))}
          className="mt-6"
        />
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-4 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={() => setShowEditModal(false)}
          />
          <Button
            filled
            label={isUpdating ? 'Saving ' : 'Save Changes'}
            onClick={handleEdit}
            isLoading={isUpdating}
            disabled={isUpdating}
          />
        </div>
      </div>
    </Modal>
  );
}

function DeleteSaleCompSetModal({ saleCompSets, setShowDeleteModal }) {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null);
  const { activeSaleCompSetId } = useSelector(state => state.saleComps);
  const [deleteSaleCompSetMutation, { isLoading: isDeleting }] = useDeleteSaleCompSetMutation();
  const activeSaleCompSet = saleCompSets.find(({ id }) => id === activeSaleCompSetId);

  const handleDelete = async () => {
    setAlert(null);
    const result = await deleteSaleCompSetMutation({ id: activeSaleCompSetId }).unwrap().catch(() => setAlert({ type: 'danger', text: 'Unable to delete the sale comp set' }));
    if (isNull(result)) {
      setShowDeleteModal(false);
      dispatch(setActiveSaleCompSetId(CURRENT_COMP_SET.id));
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="mt-4 w-90 h-auto">
        <div className="text-2xl font-normal">Edit Comp Set</div>
        <div className="mt-4 text-sm text-neutral-medium">
          {`Are you sure you want to delete ${activeSaleCompSet.name} ?`}
        </div>
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={() => setShowDeleteModal(false)}
          />
          <Button
            filled
            danger
            label={isDeleting ? 'Deleting ' : 'Delete'}
            onClick={handleDelete}
            isLoading={isDeleting}
            disabled={isDeleting}
          />
        </div>
      </div>
    </Modal>
  );
}

export default function SaleCompSet(props) {
  const {
    canTakeDealActions,
    deal,
    minimizeMap,
    property,
    propertyManagementRecord,
    saleCompSets,
    selectedComps,
    setMinimizeMap,
  } = props;
  const { activeSaleCompSetId } = useSelector(state => state.saleComps);
  const activeSaleCompSet = saleCompSets?.find(scs => scs.id === activeSaleCompSetId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <div className="py-2 px-4 h-18 flex items-center justify-between bg-white">
      {canTakeDealActions ? (
        <SaleCompSetSelector
          activeSaleCompSet={activeSaleCompSet}
          deal={deal}
          property={property}
          propertyManagementRecord={propertyManagementRecord}
          saleCompSets={saleCompSets}
          setShowDeleteModal={setShowDeleteModal}
          setShowEditModal={setShowEditModal}
        />
      ) : <div />}
      {selectedComps && (
        <div className="flex items-center justify-end gap-x-4">
          <ShortSummary activeSaleCompSet={activeSaleCompSet} comps={selectedComps} isSingleFamily={property.isSingleFamily} />
          {minimizeMap && <Map className="border rounded p-2 size-12 hover:bg-gray-100 cursor-pointer" onClick={() => setMinimizeMap(false)} />}
        </div>
      )}
      {showDeleteModal && <DeleteSaleCompSetModal saleCompSets={saleCompSets} setShowDeleteModal={setShowDeleteModal} />}
      {showEditModal && <EditSaleCompSetModal saleCompSets={saleCompSets} setShowEditModal={setShowEditModal} />}
    </div>
  );
}
