import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact, isNil } from 'lodash';
import Button from 'components/shared/NewButton';
import Menu from 'components/shared/Menu';
import { showToast } from 'redux/toastSlice';
import { useDeleteRentCompSetMutation, useUpdateRentCompSetMutation } from 'redux/apiSlice';
import { setActiveRentCompSetId } from 'redux/rentCompsSlice';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import { FormField } from 'components/Form';
import { TOAST_RENT_COMP_SET_DELETED, TOAST_RENT_COMP_SET_SAVED } from 'components/toast';
import { formatInteger, parseEventValue, unitMixKey } from 'components/utils';
import { Map } from 'components/icons';
import ShortSummary from './ShortSummary';
import RentCompSetSelector from './RentCompSetSelector';

function EditRentCompSetModal({ activeRentCompSet, setShowEditModal }) {
  const dispatch = useDispatch();
  const { selectedRentCompIds } = useSelector(state => state.rentComps);

  const [updateRentCompSetMutation, { isLoading: isUpdating }] = useUpdateRentCompSetMutation();

  const [name, setName] = useState(activeRentCompSet?.name);
  const [compPrice, setCompPrice] = useState(activeRentCompSet?.compPrice);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    setName(activeRentCompSet?.name);
  }, [activeRentCompSet]);

  const handleEdit = async () => {
    setAlert(null);
    await updateRentCompSetMutation({
      id: activeRentCompSet?.id,
      name,
      compPrice,
      selectedIds: selectedRentCompIds,
    }).unwrap().catch(() => setAlert({ type: 'danger', text: 'Unable to update the rent comp set' }));
    setShowEditModal(false);
    dispatch(showToast(TOAST_RENT_COMP_SET_SAVED));
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="mt-4 w-90 h-auto">
        <div className="text-2xl font-normal">Edit Comp Set</div>
        <FormField
          name="name"
          label="Name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-6"
        />
        <FormField
          name="compPrice"
          label="Comp Price"
          type="currency"
          value={compPrice}
          onChange={(e) => setCompPrice(parseEventValue(e))}
          className="mt-6"
        />
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-4 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={() => setShowEditModal(false)}
          />
          <Button
            filled
            label={isUpdating ? 'Saving ' : 'Save Changes'}
            onClick={handleEdit}
            isLoading={isUpdating}
            disabled={isUpdating}
          />
        </div>
      </div>
    </Modal>
  );
}

function DeleteRentCompSetModal({ activeRentCompSet, rentCompSets, setShowDeleteModal }) {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null);
  const [deleteRentCompSetMutation, { isLoading: isDeleting }] = useDeleteRentCompSetMutation();

  const handleDelete = async () => {
    setAlert(null);
    const result = await deleteRentCompSetMutation({ id: activeRentCompSet?.id })
      .unwrap().catch(() => setAlert({ type: 'danger', text: 'Unable to delete the rent comp set' }));
    if (isNil(result)) {
      // switch to primary comp set if delete is successful
      dispatch(setActiveRentCompSetId(rentCompSets.find(rcs => rcs.primary).id));
      dispatch(showToast(TOAST_RENT_COMP_SET_DELETED));
      setShowDeleteModal(false);
    }
  };

  return (
    <Modal show showCloseAction={false}>
      <div className="mt-4 w-90 h-auto">
        <div className="text-2xl font-normal">Edit Comp Set</div>
        <div className="mt-4 text-sm text-neutral-medium">
          {`Are you sure you want to delete ${activeRentCompSet?.name} ?`}
        </div>
        {alert && <Alert className="mt-6" {...alert} />}
        <div className="mt-6 flex gap-x-2 justify-end">
          <Button
            textOnly
            label="Cancel"
            onClick={() => setShowDeleteModal(false)}
          />
          <Button
            filled
            danger
            label={isDeleting ? 'Deleting ' : 'Delete'}
            onClick={handleDelete}
            isLoading={isDeleting}
            disabled={isDeleting}
          />
        </div>
      </div>
    </Modal>
  );
}

const unitMixFormatter = unitMix => compact([
  isNil(unitMix[0]) ? null : `${unitMix[0]} bd`,
  isNil(unitMix[1]) ? null : `${unitMix[1]} ba`,
  isNil(unitMix[2]) ? null : `${formatInteger(unitMix[2])} sqft`,
]).join(' · ');

export default function CompSetTopActions(props) {
  const {
    activeRentCompSet,
    activeUnitMix,
    canTakeDealActions,
    deal,
    minimizeMap,
    property,
    propertyManagementRecord,
    rentCompSets,
    setActiveUnitMix,
    setMinimizeMap,
    unitMixes,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const unitMixMenuItems = !property.isSingleFamily && [unitMixes.map(unitMix => ({
    text: unitMixFormatter(unitMix),
    onClick: () => setActiveUnitMix(unitMix),
    className: unitMixKey(unitMix) === unitMixKey(activeUnitMix) ? 'bg-primary-focus' : '',
  }))];

  return (
    <div className="py-2 px-4 h-18 flex justify-between items-center bg-white">
      {canTakeDealActions ? (
        <RentCompSetSelector
          activeRentCompSet={activeRentCompSet}
          deal={deal}
          property={property}
          propertyManagementRecord={propertyManagementRecord}
          rentCompSets={rentCompSets}
          setShowDeleteModal={setShowDeleteModal}
          setShowEditModal={setShowEditModal}
        />
      ) : <div />}
      {!property.isSingleFamily && !activeRentCompSet && (
        <Menu
          className="w-80"
          label={compact(activeUnitMix).join('/')}
          itemGroups={unitMixMenuItems}
          alignLeft
        />
      )}
      <div className="flex items-center justify-end gap-x-4">
        <ShortSummary
          activeRentCompSet={activeRentCompSet}
          property={property}
        />
        {minimizeMap && <Map className="border rounded p-2 size-12 hover:bg-gray-100 cursor-pointer" onClick={() => setMinimizeMap(false)} />}
      </div>
      {showDeleteModal && (
        <DeleteRentCompSetModal
          activeRentCompSet={activeRentCompSet}
          rentCompSets={rentCompSets}
          setShowDeleteModal={setShowDeleteModal}
        />
      )}
      {showEditModal && (
        <EditRentCompSetModal
          activeRentCompSet={activeRentCompSet}
          setShowEditModal={setShowEditModal}
        />
      )}
    </div>
  );
}
