import useUserPreference from 'hooks/useUserPreference';
import useTransitionOnSecondRender from 'hooks/useTransitionOnSecondRender';
import { get, isEqual, isNil } from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import {
  useActivePreset,
  useActivePresetId,
  useOrderConfigurableLeafColumns,
  useOrderDraggableRange,
  useSetColumnOrder,
  useSetColumnVisibility,
  useVisibilityConfigurableFlatColumns,
} from '../DataTableContext';
import {
  activePresetConfigPath,
  EMPTY_PRESET,
  FALLBACK_VISIBILITY,
  getDefaultPresetId,
  orderConfigPath,
  presetConfigStorageKey,
  STORAGE_VERSION,
  tableConfigStorageKey,
  visibilityConfigPath,
  visibilitySectionConfigPath,
} from './utils';

const dataTableConfigDefault = Object.freeze({});

/**
 * Run the supplied callback in a useLayoutEffect hook.
 *
 * This allows the initial render to update table state synchronously before browser paint
 * so that the user doesn't see the table in its default configuration.
 *
 * From the second render onward, the callback is additionally wrapped in a startTransition.
 * After the initial render, table state updates are most likely triggered by user interactions.
 * So the updates don't need to be prepared synchronously.
 */
const useTableStateEffect = (callback) => {
  const startTransition = useTransitionOnSecondRender();

  useLayoutEffect(() => {
    let cleanup;
    startTransition(() => {
      cleanup = callback();
    });
    return cleanup;
  }, [callback, startTransition]);
};

/**
 * @param {string} tableId
 */
const useApplyActivePreset = ({ tableId }) => {
  const defaultPresetId = getDefaultPresetId({ tableId });
  const [presetId, setPresetId] = useUserPreference(tableConfigStorageKey({ tableId }), defaultPresetId, STORAGE_VERSION, activePresetConfigPath);

  const [activePresetId, setActivePresetId] = useActivePresetId();
  useLayoutEffect(() => {
    setActivePresetId(presetId);
  }, [presetId, setActivePresetId]);

  if (presetId !== activePresetId && activePresetId) {
    setPresetId(activePresetId);
  }
};

const useApplyVisibilityConfig = () => {
  const preset = useActivePreset();
  const presetId = preset.id;

  const [userConfig] = useUserPreference(presetConfigStorageKey({ presetId }), dataTableConfigDefault, STORAGE_VERSION);
  const configurableColumns = useVisibilityConfigurableFlatColumns();

  // map from column id -> array of visibility config paths
  // in ascending order of specificity
  /** @type {Map<import('@tanstack/react-table').Column['id'], string[]>} */
  const columnToLocalStorageKeys = useMemo(() => (
    new Map(configurableColumns.map(({ id, columnDef: { meta: { toggleSection } = {} } }) => (
      [
        id,
        [visibilitySectionConfigPath({ toggleSection }), visibilityConfigPath({ columnId: id })],
      ]
    )))
  ), [configurableColumns]);

  const setColumnVisibility = useSetColumnVisibility();
  useTableStateEffect(useCallback(() => {
    setColumnVisibility((prev) => ({
      ...prev,
      ...Object.fromEntries(configurableColumns.map(({ id, columnDef: { meta: { toggleSection } = {} } }) => {
        // search from the end to find the most specific visibility preference
        // and set column visibility to that
        const configPath = columnToLocalStorageKeys.get(id).findLast((path) => typeof get(userConfig, path) === 'boolean');
        const userConfigVisibility = configPath && get(userConfig, configPath);

        const visible = userConfigVisibility
          ?? preset.visibility[id]
          ?? preset.toggleSection[toggleSection]
          ?? preset.defaultVisibility
          ?? FALLBACK_VISIBILITY;

        return [id, visible];
      })),
    }));
  }, [preset, userConfig, configurableColumns, columnToLocalStorageKeys, setColumnVisibility]));
};

const useApplyOrderConfig = () => {
  const preset = useActivePreset();
  const presetId = preset.id;

  const defaultOrder = preset.order ?? EMPTY_PRESET.order;
  const [storedOrder, setStoredOrder] = useUserPreference(presetConfigStorageKey({ presetId }), defaultOrder, STORAGE_VERSION, orderConfigPath);

  const columns = useOrderConfigurableLeafColumns();
  const [draggableRangeStart, draggableRangeEnd] = useOrderDraggableRange();

  const order = useMemo(() => {
    // re-calculate column order given column definition order of non-orderable columns.
    // 1. the columns that are not order-able can change, and the order of those columns can also change
    // 2. order-able columns that are not covered by the existing order but should not be placed at the end

    if (!storedOrder.length) {
      return storedOrder;
    }

    if (draggableRangeStart < 0) {
      // no draggable column, remove column order
      return EMPTY_PRESET.order;
    } else {
      const orderedSet = new Set();
      columns.slice(0, draggableRangeStart).forEach(({ id }) => orderedSet.add(id));
      storedOrder.forEach((id) => orderedSet.add(id));
      columns.forEach(({ id }) => orderedSet.add(id));
      columns.slice(draggableRangeEnd + 1).forEach(({ id }) => {
        orderedSet.delete(id);
        orderedSet.add(id);
      });

      const updatedOrder = Array.from(orderedSet);
      return isEqual(storedOrder, updatedOrder) ? storedOrder : updatedOrder;
    }
  }, [columns, draggableRangeEnd, draggableRangeStart, storedOrder]);

  const shouldSetOrder = !isEqual(order, storedOrder);
  useEffect(() => {
    if (shouldSetOrder) {
      setStoredOrder(order);
    }
  }, [order, setStoredOrder, shouldSetOrder]);

  const setColumnOrder = useSetColumnOrder();
  useTableStateEffect(useCallback(() => {
    if (!order.length) {
      setColumnOrder((prev) => (!prev?.length ? prev : order));
      return;
    }

    setColumnOrder((prev) => (isEqual(prev, order) ? prev : order));
  }, [order, setColumnOrder]));
};

function LoadPreset({ tableId, children }) {
  useApplyActivePreset({ tableId });
  const [activePresetId] = useActivePresetId();

  return isNil(activePresetId) ? null : children;
}

function LoadConfig() {
  useApplyVisibilityConfig();
  useApplyOrderConfig();
}

export default function DataTableConfig({ tableId, children }) {
  return (
    <LoadPreset tableId={tableId}>
      <LoadConfig />
      {children}
    </LoadPreset>
  );
}
