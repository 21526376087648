import FloatingTooltip from 'components/shared/FloatingTooltip';
import { CircleInfo } from 'components/icons';
import { rowIsNotIncomplete } from './offMarketMarketplace';

const attributeDisplayNames = Object.freeze({
  price: 'Price',
  expirationDate: 'Listing Expiration Date',
});

function AttributeDisplayName({ attribute }) {
  return (
    <span className="font-bold">
      {Object.hasOwn(attributeDisplayNames, attribute) ? attributeDisplayNames[attribute] : attribute}
    </span>
  );
}

function BlankMessage({ attribute }) {
  return (
    <p>
      <AttributeDisplayName attribute={attribute} /> is required
    </p>
  );
}

const messages = Object.freeze({
  blank: BlankMessage,
});

function IncompleteReason({ className, ...props }) {
  const { attribute, error } = props;
  const Message = messages[error];

  return (
    <li className={className}>
      {Message ? (
        <Message {...props} />
      ) : (
        `${attribute} ${error}`
      )}
    </li>
  );
}

function IncompleteReasons({ row }) {
  const { incompleteReasons } = row.original;

  return (
    <ul className="text-body-md">
      {Object.entries(incompleteReasons).flatMap(([attribute, reasons]) => (
        reasons.map(({ error, ...options }) => (
          <IncompleteReason
            {...options}
            key={`${attribute}-${error}`}
            attribute={attribute}
            error={error}
          />
        ))
      ))}
    </ul>
  );
}

export default function ListingStatusCell({ getValue, row }) {
  if (rowIsNotIncomplete({ row })) {
    return getValue();
  }

  return (
    <div className="inline-flex *:[tr:has(&)]:opacity-65 has-[.opaque]:*:[tr:has(&)]:opacity-100">
      <FloatingTooltip contents={<IncompleteReasons row={row} />}>
        <span className="font-medium">
          Incomplete
          <CircleInfo className="inline size-4 ml-0.5 align-text-bottom" />
        </span>
      </FloatingTooltip>
    </div>
  );
}
