import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { compact } from 'lodash';
import { useCreateListingMutation } from 'redux/offMarketMarketplaceApiSlice';
import GoogleAddressSearch, { PARCEL_UNMATCHED_VALUE } from 'components/shared/GoogleAddressSearch';
import Badge from 'components/shared/Badge';
import Button from 'components/shared/NewButton';
import Map from 'components/property/components/Map';
import MapModal from 'components/property/components/MapModal';
import StreetView from 'components/property/components/StreetView';
import { LoadingIndicator } from 'components/icons';
import { formatInteger, parseEventValue } from 'components/utils';
import { InlineFormField } from 'components/Form';
import Alert from 'components/Alert';

const DEFAULT_MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: false,
  cameraControl: false,
  streetViewControl: false,
  keyboardShortcuts: false,
  zoom: 17,
  minZoom: 10,
  mapTypeControl: true,
  mapTypeControlOptions: {
    mapTypeIds: ['roadmap', 'satellite', 'hybrid'],
  },
};

function AddressSearch({ setSelectedProperty }) {
  return (
    <div className="w-120">
      <h2 className="mb-16 text-lg">Search Listing Address</h2>
      <label className="text-gray-700 font-medium text-sm" htmlFor="addressSearchInput">Address</label>
      <GoogleAddressSearch
        onPropertySelected={setSelectedProperty}
        onPropertyUpdated={(propertyUpdate) => setSelectedProperty(sp => ({ ...propertyUpdate, ...sp }))}
      />
    </div>
  );
}

function PropertyDetailField({ label, value }) {
  return (
    <div className="flex items-center gap-x-8">
      <div className="w-32 text-right text-sm text-gray-500 font-medium">{label}</div>
      <div>{value}</div>
    </div>
  );
}

function VerifyProperty({ selectedProperty, setSelectedProperty, setPropertyConfirmed }) {
  return (
    <div className="min-w-120">
      <h2 className="mb-16 text-lg">Is this the property you would like to list?</h2>
      <VerifyPropertyBody
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
        setPropertyConfirmed={setPropertyConfirmed}
      />
    </div>
  );
}

function VerifyPropertyBody({ selectedProperty, setSelectedProperty, setPropertyConfirmed }) {
  const [showMapModal, setShowMapModal] = useState(false);

  if (!selectedProperty.parcel) {
    return (
      <div className="flex items-center gap-x-4">
        <LoadingIndicator className="size-6" />
        <div className="text-gray-500">Fetching parcel data...</div>
      </div>
    );
  }

  const { oneLineAddress, parcel } = selectedProperty;

  if (selectedProperty.PARCEL_UNMATCHED_VALUE === PARCEL_UNMATCHED_VALUE) {
    return (
      <div>{`Unable to match ${oneLineAddress} to a parcel`}</div>
    );
  }

  const {
    apn,
    bathCount,
    bedCount,
    buildingSqFt,
    lotSizeSqFt,
    yearBuilt
  } = parcel;

  const mapElem = (
    <Map
      properties={[parcel]}
      options={DEFAULT_MAP_OPTIONS}
      borderRadius="0.5rem"
    />
  );

  return (
    <div
      className="flex gap-x-24 overflow-y-scroll"
      style={{ height: 'calc(100vh - 320px)' }}
    >
      <div>
        <label className="text-sm text-gray-600">Property Information from Assessor:</label>
        <div className="mt-3 flex flex-col gap-y-2">
          <PropertyDetailField label="Address" value={oneLineAddress} />
          <PropertyDetailField label="APN" value={apn} />
          <PropertyDetailField label="Bedrooms" value={bedCount} />
          <PropertyDetailField label="Bathrooms" value={bathCount} />
          <PropertyDetailField label="Sq Ft" value={formatInteger(buildingSqFt)} />
          <PropertyDetailField label="Lot Size" value={formatInteger(lotSizeSqFt)} />
          <PropertyDetailField label="Year Built" value={yearBuilt} />
        </div>
        <div className="mt-12 flex justify-around items-center">
          <Button
            outlined
            label="Go Back"
            onClick={() => setSelectedProperty(null)}
          />
          <Button
            filled
            label="Confirm"
            onClick={() => setPropertyConfirmed(true)}
          />
        </div>
      </div>
      <div className="w-120 grid grid-cols-1 grid-rows-2 gap-6 col-span-2 rounded-lg max-h-128 row-span-full">
        <StreetView
          property={parcel}
          setShowMapModal={setShowMapModal}
          showMapModal={showMapModal}
        />
        <div className="relative">
          {mapElem}
          <MapModal setShowMapModal={setShowMapModal} showMapModal={showMapModal} property={parcel}>
            {mapElem}
          </MapModal>
          <Badge
            label="Click to expand"
            className="cursor-pointer absolute bottom-5 right-5 bg-white border-2 shadow-lg"
            onClick={() => setShowMapModal(true)}
          />
        </div>
      </div>
    </div>
  );
}

const TRUE_FALSE_SELECT_OPTIONS = [['', '-'], ['true', 'Yes'], ['false', 'No']];
const OCCUPANT_TYPE_OPTIONS = [['', '-'], ['owner', 'Owner'], ['tenant', 'Tenant'], ['vacant', 'Vacant']];
const LISTING_INPUT_PROPS = {
  ListPrice: { type: 'currency' },
  ExpirationDate: { type: 'date' },
  BedroomsTotal: { type: 'number' },
  BathroomsFull: { type: 'number' },
  BathroomsHalf: { type: 'number' },
  GarageSpaces: { type: 'number' },
  LivingArea: { type: 'number' },
  LotSizeSquareFeet: { type: 'number' },

  OccupantType: { type: 'select', options: OCCUPANT_TYPE_OPTIONS },
  LeaseAmount: { type: 'currency' },
  LeaseExpiration: { type: 'date' },
  Section8YN: { type: 'select', options: TRUE_FALSE_SELECT_OPTIONS },

  AssociationFee: { type: 'currency' },
  PoolPrivateYN: { type: 'select', options: TRUE_FALSE_SELECT_OPTIONS },
  SepticYN: { type: 'select', options: TRUE_FALSE_SELECT_OPTIONS },
  PreInspectionAvailableYN: { type: 'select', options: TRUE_FALSE_SELECT_OPTIONS },
  RenovationEstimate: { type: 'currency' },
  PrivateRemarks: { type: 'text' },
  PublicRemarks: { type: 'text' },
};

const defaultListing = parcel => ({
  BathroomsFull: parcel.bathCount,
  BedroomsTotal: parcel.bedCount,
  LivingArea: parcel.buildingSqFt,
  LotSizeSquareFeet: parcel.lotSizeSqFt,
});

const isIncompleteListing = listingData => !listingData.ListPrice || !listingData.ExpirationDate;

function ListingCompletenessAlert({ listingData }) {
  const { ExpirationDate, ListPrice } = listingData;
  const missingFieldsPhrase = isIncompleteListing(listingData)
    ? compact([!ListPrice && 'list price', !ExpirationDate && 'expiration date']).join(' and ')
    : null;

  const alertText = isIncompleteListing(listingData)
    ? `Because ${missingFieldsPhrase} ${missingFieldsPhrase.length > 1 ? 'are' : 'is'} missing, the listing will be considered incomplete but you will still have access to all of the Hive market data analysis for the listing. You will be able to publish the listing later after entering the ${missingFieldsPhrase}.`
    : 'You have entered the complete listing data. This listing will be published to the marketplace upon submission.';
  return (
    <Alert className="my-6" type="info" text={alertText} />
  );
}

function ListingDetails({ selectedProperty, setPropertyConfirmed, listingData, setListingData, setListingConfirmed }) {
  const { oneLineAddress } = selectedProperty;

  const onChange = e => {
    setListingData(prevData => ({
      ...prevData,
      [e.target.name]: parseEventValue(e),
    }));
  };

  return (
    <div className="overflow-y-scroll" style={{ maxHeight: 'calc(100vh - 176px)' }}>
      <h2 className="mb-6 text-lg">{`Enter Listing Information for ${oneLineAddress}`}</h2>
      <p className="my-6">Provide additional information about the listing that will be made available to the buyer and better determine if it fits an existing buy box:</p>
      <div className="grid grid-cols-2 grid-rows-8 gap-x-12 gap-y-3 grid-flow-col-dense">
        {Object.keys(LISTING_INPUT_PROPS).map(listingFieldName => (
          <InlineFormField
            key={listingFieldName}
            name={listingFieldName}
            value={listingData[listingFieldName]}
            onChange={onChange}
            {...LISTING_INPUT_PROPS[listingFieldName]}
          />
        ))}
      </div>
      <ListingCompletenessAlert listingData={listingData} />
      <div className="mt-12 flex justify-around items-center">
        <Button
          outlined
          label="Go Back"
          onClick={() => setPropertyConfirmed(false)}
        />
        <Button
          filled
          label="Confirm"
          onClick={() => setListingConfirmed(true)}
        />
      </div>
    </div>
  );
}

function CreateListing({ listingData, setListingConfirmed, selectedProperty }) {
  const { oneLineAddress, parcel: { fipsApn } } = selectedProperty;
  const navigate = useNavigate();
  const [createListing, { error, isLoading }] = useCreateListingMutation();

  const onCreate = async () => {
    const response = await createListing({ ...listingData, fipsApn });
    if (!response.error) {
      navigate({ pathname: '/off_market_marketplace/inventory', search: createSearchParams({ id: response.data.id }).toString() });
    }
  };

  const createOutcome = isIncompleteListing(listingData) ? 'add the listing to your inventory' : 'publish the listing to the Hive marketplace';

  return (
    <div className="w-120">
      <h2 className="text-lg">{`Submit Listing for ${oneLineAddress}`}</h2>
      <p className="my-12">{`Selecting submit will ${createOutcome}. Once the listing is created, you can upload pictures and the A to B agreement.`}</p>
      {error && <Alert className="my-12" type="danger" text="Error creating listing. Please contact Honeycomb support." />}
      <div className="mt-12 flex justify-around items-center">
        <Button
          outlined
          label="Go Back"
          onClick={() => setListingConfirmed(false)}
          disabled={isLoading}
        />
        <Button
          filled
          label="Submit"
          onClick={onCreate}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default function OffMarketAddListing() {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyConfirmed, setPropertyConfirmed] = useState(false);
  const [listingData, setListingData] = useState({});
  const [listingConfirmed, setListingConfirmed] = useState(false);

  useEffect(() => {
    if (selectedProperty?.parcel) {
      setListingData(defaultListing(selectedProperty.parcel));
    }
  }, [selectedProperty, setListingData]);

  let stepBody = null;
  if (!selectedProperty) {
    stepBody = <AddressSearch setSelectedProperty={setSelectedProperty} />
  } else if (listingConfirmed) {
    stepBody = (
      <CreateListing
        selectedProperty={selectedProperty}
        listingData={listingData}
        setListingData={setListingData}
        setListingConfirmed={setListingConfirmed}
      />
    );
  } else if (propertyConfirmed) {
    stepBody = (
      <ListingDetails
        selectedProperty={selectedProperty}
        setPropertyConfirmed={setPropertyConfirmed}
        listingData={listingData}
        setListingData={setListingData}
        setListingConfirmed={setListingConfirmed}
      />
    );
  } else {
    stepBody = (
      <VerifyProperty
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
        setPropertyConfirmed={setPropertyConfirmed}
      />
    );
  }

  return (
    <div className="pt-12 pr-12">
      <h1 className="mb-12 text-2xl">Add Listing</h1>
      <div className="max-w-max bg-white p-6">{stepBody}</div>
    </div>
  );
}
